import { useEffect, useState } from 'react';


/**
 *
 * @param key{string}
 * @param initialState{any}
 * @return {[(any),((value: (((prevState: (any)) => (any)) | any)) => void)]}
 */

export default function useLocalStorage(key, initialState) {
	const current = localStorage.getItem(key);
	let string = current ?? JSON.stringify(initialState);

	let _value = JSON.parse(string);

	const [value, setValue] = useState(_value);
	useEffect(() => {
		const _value = value ?? initialState;
		try {
			localStorage.setItem(key, JSON.stringify(_value));
		}
		catch (error) {
			console.error('had error setting item', error);
		}
	}, [key, value, initialState]);
	return [value, setValue];
}

import Box from '@mui/material/Box';
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import get from 'lodash/get';
import numbro from 'numbro';
import useIntlPlus from './IntlPlus';
import {
	Tooltip,
	Typography
} from '@mui/material';
import * as React from 'react';
import {
	GRID_AGGREGATION_FUNCTIONS,
	GridAggregationFunction,
	GridGroupingValueGetterParams,
	GridRenderCellParams,
	GridValueFormatterParams,
	GridValueGetterParams
} from '@mui/x-data-grid-premium';
import { startCase } from 'lodash';
import { renderStatus } from '../grid/renderStatus';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../AbilityContext';

export const MASK = '******';
export default function useGridHelper<R extends GridValidRowModel>() {
	const intl = useIntlPlus();
	const ability = useAbility(AbilityContext);



	const valueGetter = (params: GridValueGetterParams)=> {
		const value = get( params.row, params.colDef.field );
		if (!value) return value;

		switch ( params.colDef.type ) {
		case 'date' : return new Date(value);
		case 'dateTime' : return new Date(value);
		case 'number': return Number(value);
		case 'boolean': return Boolean(value);
		default : return value;
		}
	};

	const stringToNumberValueGetter = (params: GridValueGetterParams) => {
		const value = get( params.row, params.colDef.field );
		if (!value) return value;
		return numbro.unformat(value);
		// const stripped = value.replace(/^[a-zA-Z\s]+|[a-zA-Z\s]+$/g, '');
		// const number = parseFloat(stripped);
		// return isNaN(number) ? null : number;
	};

	/**
	 * Get the column value as Date object
	 * @param params
	 * @return {Date|*}
	 */
	const getDate = (params: GridValueGetterParams) => params.value ? new Date(params.value): params.value;

	/**
	 * Get the column value as Date object
	 * @param params
	 * @return {Date|*}
	 */
	const getDateOnly = (params: GridValueGetterParams) => {
		if (params.value) {
			return new Date(`${params.value}T00:00:00Z`);
		}
		return params.value;
	};

	const formatDateOnly = ( params:GridValueFormatterParams) => {
		if (!params.value) return params.value;
		return intl.formatDate(params.value, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			timeZone: 'UTC',
		});

	};
	/**
	 * Wrapper for can
	 * @param params
	 * @return {boolean}
	 */
	const isRestrictedValue = (params: any)=>params.action && params.subject && !ability.can(params.action, `${params.subject}.${params.field}`);
	const renderRestrictedValue = (params: (GridRenderCellParams|GridGroupingValueGetterParams & {action: string; subject: string})) => (
		params.row?.id &&
		isRestrictedValue(params) ? MASK : (params as GridRenderCellParams).formattedValue ?? params.value
	);
	const formatDate = (params:GridValueFormatterParams) => {
		if (!params.value) return params.value;
		return intl.formatDate(params.value, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
		});
	};

	const formatCurrency = (params: GridValueFormatterParams)=>{
		if (!params.value) return params.value;
		const value = Number.isNaN(params.value) ? Number(params.value?.replace(',','')) : params.value;
		return value && intl.formatCurrency(value);
	};

	const formatDateTime = (params: GridValueFormatterParams) => {
		if (!params.value) return params.value;
		return intl.formatDate(params.value, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		});
	};

	const formatYear = (params: GridValueFormatterParams) =>{
		//if (!params.value) return params.value;
		return params?.value;
	};

	/**
	 *
	 * @param params
	 * @return {string|*}
	 */
	const formatFieldName = (params: GridValueFormatterParams): string | any=>{
		const {value} = params;
		return value ? intl.formatMessage({id: value, defaultMessage: startCase(value)}) : value;
	};

	/**
	 *
	 * @param params
	 * @return {JSX.Element}
	 */
	const renderComment = (params: GridRenderCellParams):React.ReactNode => {
		const lineLimit = 3; //TODO: how to make this configurable
		return (
			<Box
				title={params.value}
				sx={{
					whiteSpace: 'pre-wrap',
					overflow: 'auto',
					maxHeight: '100%',
					width: '100%',
					display: 'flex',
					alignItems: 'flex-start',
					paddingRight: '4px',
					padding: 0,
					// border: lines.length > lineLimit ? '0px solid lightgrey' : 'none'
				}}
			>
				<Typography variant="body2">
					{params.formattedValue ?? params.value}
				</Typography>
			</Box>
		);
	};

	/**
	 * Attach as renderCell to columns that have associated column so that a tooltip is rendered with the name.  I.e., Lookup codes
	 * @param params
	 * @return {JSX.Element}
	 */
	const renderLookupCode = (params:GridRenderCellParams): React.ReactNode => (
		// @ts-ignore
		<Tooltip title={ params.row[params.colDef.ref]?.name ?? '' }>
			<span className="table-cell-truncate">{ params.value }</span>
		</Tooltip>
	);
	/**
	 * Grid Aggregation Function to count distinct values
	 * @type {{apply: (function({values: *}): number), columnTypes: string[], valueFormatter: (function({value: *, field: *, api: *}): string), label: string}}
	 */
	const countDistinct: GridAggregationFunction = {
		apply: ({ values }) => {
			return new Set(values.filter(v=>v)).size;
		},
		valueFormatter: ({ value, field, api }) => {
			const label = api.getColumn(field).headerName;
			return `${value} ${label}s`;
		},
		label: 'count',
		columnTypes: ['string'],
	};

	/**
	 * Count By Distinct values
	 * @type {{apply: (function({values: *}): number), columnTypes: string[], valueFormatter: (function({value: *, field: *, api: *}): string), label: string}}
	 */
	const groupCount: GridAggregationFunction = {
		apply: ({ values }) => {
			const distinct = new Set(values);
			const ret= [...distinct]?.map(v=>{
				return {
					[v ?? 'Unknown']: values.filter(i=>v===i).length
				};
			});
			return ret?.length > 0 ? ret?.reduce((a={},b={}) => {return {...a,...b};}) : {};
		},
		valueFormatter: ({ value }) => {
			return (<div style={{padding:'2px'}}>
				{ Object.entries(value)?.map((v)=>(<div key={v[0]} style={ { padding: '0px', fontSize:'x-small' }}>{`${v[0]}: ${v[1]}`}</div>)) }
			</div>);
		},
		label: 'group',
		columnTypes: ['string'],
	};

	/**
	 * Grid Aggregation Function to count distinct values
	 * @type {{apply: (function({values: *}): number), columnTypes: string[], valueFormatter: (function({value: *, field: *, api: *}): string), label: string}}
	 */
	const joinDistinct: GridAggregationFunction = {
		apply: ({ values }) => {
			return [...new Set(values).values()].sort().join();
		},
		valueFormatter: ({ value, field, api }) => {
			const label = api.getColumn(field).headerName;
			return `${value} ${label}s`;
		},
		label: 'join',
		columnTypes: ['string'],
	};

	const ALL_STATUS = ['OVERDUE', 'DUE', 'OK', 'COMPLETED'];

	/**
	 * Grid Aggregation Function to return the "least" status value
	 * @type {{const: string[], apply: (function({values: *}): string), columnTypes: string[], valueFormatter: (function({value: *, field: *, api: *}): string), label: string}}
	 */
	const status: GridAggregationFunction = {
		apply: ({ values }) => {
			values = [...new Set(values).values()];
			values = values.sort((a,b)=> {
				const indexA = ALL_STATUS.indexOf(a);
				const indexB = ALL_STATUS.indexOf(b);
				if (indexA === -1 && indexB === -1) {
					return 0; // both elements not found in indexArray, maintain their order
				} else if (indexA === -1) {
					return 1; // a not found, b comes first
				} else if (indexB === -1) {
					return -1; // b not found, a comes first
				} else {
					return indexA - indexB; // sort based on the index difference
				}
			});
			return values.at(0);
		},
		// @ts-ignore
		renderCell: renderStatus,
		label: 'status',
		columnTypes: ['string'],
	};

	const maxDate:GridAggregationFunction= {
		apply: (params)=> {
			const value = GRID_AGGREGATION_FUNCTIONS.max.apply(params);
			if (value === -Infinity) {
				return null;
			}
			return value;
		},
		label:'max',
		columnTypes: ['date']
	};

	function mergeUpdatesById(data:R[], updates:R[]) {
		return data.map((item) => {
			const matchingUpdate = updates.find((update) => update.id === item.id);
			if (matchingUpdate) {
				return { ...item, ...matchingUpdate }; // Merge updated properties
			}
			return item; // Keep original item if no matching update
		});
	}

	const GridAggregationFunctions = {
		...GRID_AGGREGATION_FUNCTIONS,
		count: countDistinct,
		join: joinDistinct,
		group: groupCount,
		milestoneStatus: status,
		maxDate: maxDate
	};
	return {
		MASK,
		valueGetter,
		stringToNumberValueGetter,
		getDate,
		getDateOnly,
		isRestrictedValue,
		renderRestrictedValue,
		renderLookupCode,
		renderComment,
		formatCurrency,
		formatDate,
		formatDateOnly,
		formatDateTime,
		formatYear,
		formatFieldName,
		mergeUpdatesById,
		GridAggregationFunctions
	};
}

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import * as React from 'react';
import { useSnackbar } from 'notistack';
import SaveIcon from '@mui/icons-material/Save';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import InputField from './InputField';
import { gridColumnFieldsSelector } from '@mui/x-data-grid-premium';
import { useCallback, useEffect } from 'react';

const GridBulkEditDialog = ({
	open,
	onClose,
	onSave,
	data = {},
	schema,
	columns,
	gridView,
	typeLabel = '',
	dialogContentText,
	apiRef,
	disableAuditNote = false
}) =>
{
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	const [errors, setErrors] = React.useState({});
	const [loading, setLoading] = React.useState(false);
	const [valid, setValid] = React.useState(true);
	const [changed, setChanged] = React.useState({});
	const [fields, setFields] = React.useState([]);
	const [auditNote, setAuditNote] = React.useState('');
	const [ids, setIds] = React.useState([]);
	const [groupedFields, setGroupedFields] = React.useState([]);
	useEffect(()=>{
		if (apiRef && apiRef?.current) {
			const visibleFields = gridColumnFieldsSelector(apiRef.current.state, apiRef.current.instanceId);
			setFields(visibleFields);
			//setGroupedFields(gridRowGroupingSanitizedModelSelector(apiRef.current.state, apiRef.current.instanceId));
			const rows = apiRef.current.getSelectedRows();
			setIds(Array.from(rows?.keys()));
		}
	}, [apiRef, gridView, columns]);
	const handleChange = async (id, event, value1, reason, details) =>{
		let { target: { value } } = event;
		if (reason) {
			value = value1;
		}
		value = typeof value === 'string' && isEmpty(value) ? null : value;
		let objectId = getRef(id);
		try
		{
			let fSchema;
			try {
				fSchema = schema?.extract(objectId);
			} catch(e) {
				fSchema = schema?.extract(id.substring(0,id.indexOf('-')));
				objectId = id.substring(0,id.indexOf('-'));
			}
			let { error } = fSchema?.validate(value, { allowUnknown: true }) || {};
			let newErrors = { ...errors };
			delete newErrors[id];
			if (error)
			{
				newErrors[id] = error.message;
			}
			setErrors(newErrors);
			setValid(!errors);
			if (id !== objectId) {
				const temp = set(changed[objectId] ?? {}, id, value);
				set(changed, objectId, temp);
			} else {
				set(changed, objectId, value);
			}
			return true;
		} catch (e)
		{
			const newErrors = { ...errors, [id]: e.message };
			setErrors(newErrors);
			setValid(!errors);
		}
	};

	const validate = async (changed) =>{
		let { error } = schema?.validate(changed, { abortEarly: false, allowUnknown: true }) || {};
		if (error)
		{
			const errors = error.details.map(d =>
			{
				return {
					key: d.context.key,
					message: d.message
				};
			})
				.reduce(function(map, obj)
				{
					map[obj.key] = obj.message;
					return map;
				}, {});
			setErrors(errors);
			setValid(false);

			const messages = error.details.map(d => `${ d.message }`);
			enqueueSnackbar(messages.join('.  '), {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center'
				}
			});
			return false;
		}
		return true;
	};

	const handleClose = (event, reason) => {
		if (['backdropClick'].includes(reason)) {
			return;//ignored event
		}
		event.preventDefault();
		clearState();
		onClose(false);
	};

	const clearState = () =>{
		setChanged({});
		setValid(true);
		setErrors({});
	};

	const handleError = async (e) =>{
		let { message, errors } = e;
		if (errors)
		{
			message = `${ message }: ${ errors.flatMap(e => e.message)
				.join(', ') }`;
		}
		enqueueSnackbar(message, {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			}
		});
	};
	const hasChanges = () =>
	{
		return Object.keys(changed).length > 0;
	};
	const isValid = () =>
	{
		return Object.keys(errors).length === 0;
	};

	const getRef = (field)=>{
		return columns.find(c=>c.field === field)?.ref ?? field;
	};
	const handleSave = useCallback(async (e) =>
	{
		e.preventDefault();
		if (!hasChanges())
		{
			return;
		}

		// const payload = { };
		// Object.keys(changed)
		// 	.forEach(key =>
		// 	{
		// 		set(payload, key, changed[key]);
		// 	});

		let valid = await validate(changed);
		if (!valid){
			return;
		}
		try
		{
			setLoading(true);
			const config = auditNote ? {headers: {'x-audit-note': auditNote }} : undefined;
			const result = await onSave({ ids, data: changed, config });
			if (result && result.id)
			{
				enqueueSnackbar(`Saved ${ typeLabel }`, {
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center'
					}
				});
				clearState();
				onClose(result);
			}
		} catch (e)
		{
			await handleError(e);
		} finally
		{
			setLoading(false);
		}
	},[auditNote, changed, data?.id, enqueueSnackbar, handleError, hasChanges, onClose, onSave, typeLabel, validate]);

	const formatFields = (fields) =>
	{
		if (!fields) return [];
		return fields.map((f) => {
			return columns.find((c)=>c.field === f);
		})
			.filter( f => f?.editable )
			.map(formatField);
	};

	const formatField = (props = {}) => {
		const { field: id, headerName: label, type, description, valueGetter,
			editable = false, readOnly: _readOnly = false, required, inputProps } = props;
		const params = { row: {} , ...props, colDef: props };
		const readOnly = (typeof _readOnly === 'boolean') ? _readOnly : _readOnly(params);
		return {
			apiRef,
			id,
			label: intl.formatMessage({id: id ?? 'unknown', defaultMessage: label ?? id}),
			type,
			editable: typeof editable === 'boolean' ? editable : editable(params),
			required,
			fullWidth: true,
			variant: 'standard',
			key: id,
			//defaultValue: valueGetter ? valueGetter(params) :get(data, id),
			//defaultValue: get(data, id),
			onChange: (event, value1, reason, details)=> {
				return handleChange(id, event, value1, reason, details);
			},
			renderEditCell: props.renderEditCell,
			error: !!errors[id],
			helperText: errors[id] ?? description,
			margin: 'dense',
			readOnly: readOnly ?? !editable ?? !gridView?.editable,
			inputProps: {...inputProps, readOnly }
		};
	};

	return (
		<Dialog open={ open && gridView?.editable } onClose={ handleClose } fullWidth={true} >
			<DialogTitle>{ intl.formatMessage({ id: 'bulkEdit', defaultMessage: 'Bulk Edit'}) } { ids?.length } Selected Rows</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{ dialogContentText }
				</DialogContentText>
				<form id='editForm' onSubmit={handleSave}> {
					formatFields(fields)
						?.filter(f => !groupedFields.includes(f.id))
						.map((f) => f.renderEditCell
							? f.renderEditCell(f)
							: <InputField { ...f }/>)

				}
				</form>
			</DialogContent>
			<DialogActions>
				{!disableAuditNote && <TextField
					id='x-audit-note'
					//variant='filled'
					helperText='Enter an optional comment'
					label="Comment" multiline sx={{ width: '90%', margin: '8px' }}
					onChange={(event)=> {
						setAuditNote(event.target.value);
					}}
					disabled={disableAuditNote}
				/>}
				<Button onClick={ handleClose }>Cancel</Button>
				<LoadingButton
					type='submit'
					form='editForm'
					loading={ loading }
					disabled={ (!gridView?.editable || !hasChanges()) && isValid() }
					loadingPosition="start"
					startIcon={ <SaveIcon /> }
				>Save</LoadingButton>
			</DialogActions>
		</Dialog>);
};
GridBulkEditDialog.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	data: PropTypes.object,
	schema: PropTypes.object,
	typeLabel: PropTypes.string,
	dialogContentText: PropTypes.string,
	columns: PropTypes.array,
	gridView: PropTypes.object,
	apiRef: PropTypes.object,
	disableAuditNote: PropTypes.bool
};
export { GridBulkEditDialog };

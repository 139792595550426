import { useAbility } from '@casl/react';
import { AppBar, Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import { useAuth } from 'base-shell/lib/providers/Auth';
import Page from 'material-ui-shell/lib/containers/Page';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AbilityContext } from '../../components/AbilityContext.js';
import AuditInfo from '../../components/AuditInfo';
import AuditableHistoryGrid from '../../components/AuditRecord/AuditableHistoryGrid';
import ErrorDisplay from '../../components/ErrorDisplay';
import GlobalSearch from '../../components/GlobalSearch';
import PageTitle from '../../components/hooks/PageTitle';
import { MarketContext } from '../../components/Market/MarketContext';
import MarketProperties from '../../components/Market/MarketProperties';
import MarketSummary from '../../components/Market/MarketSummary';
import TabPanel from '../../components/TabPanel';
import { useMarketApi } from '../../services/market-api';

const MarketDetail = () => {
	const {auth} = useAuth();
	const ability = useAbility(AbilityContext);
	const [market, setMarket] = useState({});
	const [readonly, setReadonly] = useState(false);
	const location = useLocation();
	const [host, route, id, tab = 'summary'] = location.pathname.split('/');
	const handleError = ErrorDisplay();
	const {isLoading, getOne, save} = useMarketApi(handleError);
	const [isEditor, setEditor] = useState(false);
	const [refresh, setRefresh] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		setEditor(!!ability.can('update', 'Market'));
		if (auth?.isAuthenticated && (refresh || id !== market?.id)) {
			getOne(id)
				.then((data) => {
					setMarket(data);
					setRefresh(false);
					setReadonly(data?.deletedAt);
				});
		}
	}, [id, refresh, auth]);

	const handleChange = (event, newValue) => {
		const newPath = `/${ route }/${ id }/${ newValue }`;
		navigate(newPath);
	};

	function applyTabProps(index) {
		return {
			id: `full-width-tab-${ index }`,
			'aria-controls': `full-width-tabpanel-${ index }`,
		};
	}

	PageTitle({id: 'xxxdetail', defaultTitle: market?.name ?? id});
	return (
		<MarketContext.Provider value={ {market, setMarket} }>
			<Page
				style={ {height: '100%', width: '100%', alignContent: 'space-around'} }
				pageTitle={ market?.name + (readonly ? ' (DELETED)' : '') }
				isLoading={ isLoading || !market?.id }
				appBarContent={
					<>
						<GlobalSearch/>
					</>
				}
				tabs={
					<AppBar position="static">
						<Tabs
							value={ tab }
							onChange={ handleChange }
							textColor="inherit"
							indicatorColor="secondary"
							variant={ 'fullWidth' }
							scrollButtons={ true }
							allowScrollButtonsMobile={ true }
						>
							<Tab label="Summary" value="summary" { ...applyTabProps('summary') } />
							<Tab label="Properties" value="properties" { ...applyTabProps('properties') }
							     disabled={ readonly }/>
							<Tab label="Reports" value="reports" { ...applyTabProps('reports') } disabled={ readonly }/>
							<Tab label={ <AuditInfo auditable={ market }/> } value="audit"
							     sx={ {alignSelf: 'end', alignItems: 'end'} } { ...applyTabProps('audit') }/>
						</Tabs>
					</AppBar>
				}
			>
				<Box
					style={ {height: '100%', width: '100%', alignContent: 'space-around'} }
					index={ tab }
				>
					<TabPanel value={ tab } index="summary">
						<MarketSummary
							readonly={ readonly }
							editable={ isEditor }>
						</MarketSummary>
					</TabPanel>
					<TabPanel value={ tab } index="properties">
						<MarketProperties editable={ isEditor }/>
					</TabPanel>
					<TabPanel value={ tab } index="reports">
						Market Reports
					</TabPanel>
					<TabPanel value={ tab } index="audit">
						<AuditableHistoryGrid
							auditable={ market }
							auditableType="market"
							editable={ false }
							role="editor"
							style={ {height: '100%', width: '100%', alignContent: 'space-around', overflow: 'auto'} }
						/>
					</TabPanel>
				</Box>
			</Page>
		</MarketContext.Provider>
	);
};
export default MarketDetail;

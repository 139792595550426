
export default function updateGoogleTagUser(userId, department, building) {
	console.log(`updating gtag user id ${userId}, ${department}, ${building}`)
	gtag('set', 'user_properties', {
		department,
		building
	});

	gtag('config', window.env.analyticsMeasurementId, {
		'user_id': userId
	});
}

import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

export default function ErrorDisplay({ logError = true, displayError = true }={}) {
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	return (error) => {
		let { message, errors } = error;
		if (errors)
		{
			message = `${ message }: ${ errors.flatMap(e => e.message)
				.join(', ') }`;
		}
		try {
			if (message) {
				console.dir(message);
				message = intl.formatMessage({ id: message, defaultMessage: message });
			} else {
				console.dir(error);
				message = 'Unhandled error';
			}
		}
		catch (e) {
			console.error('Cannot handle error:', e);
			displayError && enqueueSnackbar( message,{
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center'
				}
			});
		}
		finally
		{
			logError && console.error(message);
			displayError && enqueueSnackbar( message,{
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center'
				}
			});
		}
	};
}

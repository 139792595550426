import React, { useState, ChangeEvent } from 'react';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

const formatNumber = (value: string | number): string => {
	// Convert the value to a number and check if it's valid
	const num = parseFloat(value as string);
	if (isNaN(num)) {
		return value as string; // Return the original value if it's not a valid number
	}
	return `${Math.round((num + Number.EPSILON) * 10000) / 100}`; // float with 2 digit precision
};

type PercentageFieldProps = {
  defaultValue?: number | string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: number) => void;
  InputProps?: any; // You can replace 'any' with the specific type of InputProps if available
  readOnly?: boolean;
};

const PercentageField: React.FC<PercentageFieldProps> = (props) => {
	const { defaultValue } = props;
	const [inputValue, setInputValue] = useState<number | undefined>(undefined);
	const [defaultInputValue, setDefaultInputValue] = useState<string>(
		formatNumber(defaultValue || '')
	);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		let num = parseFloat(value);
		setInputValue(num);
		if (!isNaN(num)) {
			num = num / 100;
		}
		event.target.value = num.toString();
		props.onChange && props.onChange(event, num);
	};

	return (
		<TextField
			{...props}
			type="number"
			value={inputValue}
			defaultValue={defaultInputValue}
			onChange={handleChange}
			InputProps={{
				...props.InputProps,
				startAdornment: <InputAdornment position="start">%</InputAdornment>,
				readOnly: props.readOnly,
			}}
		/>
	);
};

export default PercentageField;

import React, {
	useEffect,
	useState
} from 'react';
import {
	CollegeHouseMarket,
	useCollegeHouseApi
} from '../../services/college-house-api';
import ErrorDisplay from '../ErrorDisplay';
import useIntlPlus from '../hooks/IntlPlus';
import Tile from '../Tile';
import { useMarketContext } from './MarketContext';

type CollegeHouseMarketTileProps = {
	readonly: boolean
	editable: boolean
};


export const CollegeHouseMarketTile: React.FC<CollegeHouseMarketTileProps> = ( props ) => {
	const intl = useIntlPlus();
	const { market } = useMarketContext();
	const [ model, setModel ] = useState<CollegeHouseMarket>();

	const handleError = ErrorDisplay();
	const collegeHouseApi = useCollegeHouseApi( handleError );

	useEffect( () => {
		console.dir( market );
		if ( market?.externalId ) {
			collegeHouseApi.getMarket( market?.externalId )
			               .then( ( data ) => {
				               console.dir( data );
				               setModel( data );
			               } );
		}
	}, [ market ] );

	return (
		<Tile
			title="College House Info"
			title2={ model?.city }
			readonly={ props.readonly }
			editable={ props.editable }
			fields={ [
				{
					id: 'name',
					key: 'name',
					label: 'Name',
					value: model?.city,
				},
				{
					id: 'stateAbbr',
					key: 'stateAbbr',
					label: 'State',
					value: model?.stateAbbr,
				},
				{
					id: 'regionAbbr',
					key: 'regionAbbr',
					label: 'Region',
					value: model?.regionAbbr,
				},
				{
					id: 'bedsPurposeBuilt',
					key: 'bedsPurposeBuilt',
					label: 'Current Market Beds',
					value: model?.bedsPurposeBuilt,
				},
			] }
			data={ market }

		/>
	);
};

export default CollegeHouseMarketTile;

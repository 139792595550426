import {
	GridToolbarColumnsButton,
	GridToolbarContainer, GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid-premium';
import { Button } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { useIntl } from 'react-intl';

const CoreDataGridToolbarBasic = ({handleRefresh, handleReset})=> {
	const intl = useIntl();

	return (
		<GridToolbarContainer>
			{/*<Button*/}
			{/*	size="small"*/}
			{/*	startIcon={ <AddIcon /> }*/}
			{/*	onClick={ handleClickAdd }*/}
			{/*	disabled={ !canEdit }*/}
			{/*	title={ intl.formatMessage({ id: 'add_vendor', defaultMessage: 'Add Vendor' }) }*/}
			{/*>*/}
			{/*	{ intl.formatMessage({ id: 'add', defaultMessage: 'Add' }) }*/}
			{/*</Button>*/}
			<Button
				size="small"
				startIcon={ <Refresh /> }
				onClick={ handleRefresh }
				title={ intl.formatMessage({ id: 'refresh-grid', defaultMessage: 'Refresh Data' }) }
				disabled={ !handleRefresh }
			>
				{ intl.formatMessage({ id: 'refresh', defaultMessage: 'Refresh' }) }
			</Button>
			<GridToolbarColumnsButton/>
			<GridToolbarFilterButton/>
			<GridToolbarExport />
			{/*<Button*/}
			{/*	size="small"*/}
			{/*	startIcon={ <Clear /> }*/}
			{/*	onClick={ handleReset }*/}
			{/*	title={ intl.formatMessage({ id: 'reset-grid-config', defaultMessage: 'Reset Grid Settings' }) }*/}
			{/*>*/}
			{/*	{ intl.formatMessage({ id: 'reset', defaultMessage: 'Reset' }) }*/}
			{/*</Button>*/}
		</GridToolbarContainer>
	);
};
export default CoreDataGridToolbarBasic;

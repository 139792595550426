import { HelpCenterRounded } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { Popover } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';

interface HelpInfoProps {
	children?: React.ReactNode|null;
	onClick?:()=>void
	sx?:object
}

export const HelpInfo:React.FC<HelpInfoProps> = ({ children, sx, onClick })=> {
	const [anchor, setAnchor] = React.useState(null);
	const handleOpen = (event:any) => {
		setAnchor(event.currentTarget);
	};

	const handleClose = (event:any) => {
		setAnchor(null);

	};
	const open = Boolean(anchor);

	return ( children ?
		<Box sx={sx} onClick={onClick}>
			<IconButton
				sx={sx}
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handleOpen}
				onMouseLeave={handleClose}
				color="inherit"
			><HelpCenterRounded/>
			</IconButton>
			<Popover
				id="mouse-over-popover"
				sx={ {
					pointerEvents: 'none',
					padding: '4px'
				} }
				open={ open }
				anchorEl={ anchor }
				anchorOrigin={ {
					vertical: 'bottom',
					horizontal: 'left',
				} }
				transformOrigin={ {
					vertical: 'top',
					horizontal: 'left',
				} }
				onClose={ handleClose }
				disableRestoreFocus
			>
				<Box sx={ { p: 1 } }>
					{children}
				</Box>
			</Popover>
		</Box>:null
	);
};

import { gridStringOrNumberComparator } from '@mui/x-data-grid-premium';
import { GridComparatorFn } from '@mui/x-data-grid/models/gridSortModel';
import { UnitTypeUnitSpace } from '../Property/Units/Units.types';

const getValue = (value: any, field:any)=>{
	return value && typeof value === 'object' ? value[field] : value;
};

const NamedObjectArrayToString:(v:any)=>string = (v) => v?.map((e:any)=>getValue(e, 'name')).join(', ') ?? '';
export const NamedObjectArrayComparator:GridComparatorFn  = (v1, v2, param1 , param2) => {
	return gridStringOrNumberComparator(NamedObjectArrayToString(v1), NamedObjectArrayToString(v2), param1, param2);
};


export const NamedObjectComparator:GridComparatorFn = (v1, v2, param1 , param2) =>gridStringOrNumberComparator(getValue(v1, 'name'), getValue(v2, 'name'), param1, param2);
export const AliasedObjectComparator:GridComparatorFn = (v1, v2, param1 , param2) =>gridStringOrNumberComparator(getValue(v1, 'alias'), getValue(v2, 'alias'), param1, param2);

export const ObjectIdComparator:GridComparatorFn  = (v1, v2, param1 , param2) =>gridStringOrNumberComparator(getValue(v1, 'id'), getValue(v2, 'id'), param1, param2);

export const ObjectIndexComparator:GridComparatorFn  = (v1, v2, param1 , param2) =>gridStringOrNumberComparator(getValue(v1, 'index'), getValue(v2, 'index'), param1, param2);

export const UnitNumberComparator:GridComparatorFn<UnitTypeUnitSpace>  = (a, b, param1 , param2) => {
	const av = a?.sortKey;
	const bv = b?.sortKey;
	return av?.localeCompare(bv);
};

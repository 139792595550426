import {
	Clear,
	Refresh
} from '@mui/icons-material';
import {
	Button,
	CardContent,
	CardHeader,
	Link,
	Stack
} from '@mui/material';
import Box from '@mui/material/Box';

import {
	DataGridPremium,
	GridColDef,
	GridLogicOperator,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
	GridToolbarQuickFilter,
	useGridApiRef,
} from '@mui/x-data-grid-premium';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { useSnackbar } from 'notistack';
import React, {
	useEffect,
	useMemo,
	useState
} from 'react';

import { useIntl } from 'react-intl';
import { useMarketApi } from '../../services/market-api';
import ErrorDisplay from '../ErrorDisplay';
import useGridHelper from '../hooks/GridHelper';
import useLocalStorage from '../hooks/LocalStorage';
import { Property } from '../Property/types';

import { useMarketContext } from './MarketContext';

const MarketProperties = ( { editable = false } ) => {
	const intl = useIntl();
	const handleError = ErrorDisplay();
	const api = useMarketApi( handleError );
	const [ refresh, setRefresh ] = React.useState( true );
	const { enqueueSnackbar } = useSnackbar();
	const { formatYear } = useGridHelper();
	const { market } = useMarketContext();
	const apiRef = useGridApiRef();

	const columns = useMemo<GridColDef[]>( () => [
		{
			field: 'id', headerName: 'Property Id', minWidth: 200, hideable: false,
			renderCell: (params) => (
				<Link href={ `/communities/${ params.row?.id }` }>{ params.row.name }</Link>
			)
		},
		{
			field: 'name', headerName: 'Property Name', minWidth: 200,
			hideable: false,
			renderCell: (params) => (
				<Link href={ `/communities/${ params.row?.id }` }>{ params.row.name }</Link>
			)
		},
		{
			field: 'propertyType',
			headerName: intl.formatMessage( { id: 'type', defaultMessage: 'Community Type' } ),
			minWidth: 120,
			valueGetter: ( params: any ) => params.value?.name,
			description: intl.formatMessage( { id: 'property_type_desc', defaultMessage: 'Community Type' } )
		},
		{
			field: 'streetAddress',
			headerName: intl.formatMessage( { id: 'property_street_address', defaultMessage: 'Address' } ),
			description: intl.formatMessage( {
				id: 'property_street_address_desc',
				defaultMessage: 'Community Address'
			} ),
			width: 200, minWidth: 120,
		},
		{
			field: 'phone',
			headerName: intl.formatMessage( { id: 'property_phone', defaultMessage: 'Phone' } ),
			description: intl.formatMessage( { id: 'property_phone_desc', defaultMessage: 'Community Phone' } ),
			width: 120, minWidth: 60,
		},
		{
			field: 'yearBuilt', headerName: intl.formatMessage( { id: 'year_built', defaultMessage: 'Year Built' } ),
			description: intl.formatMessage( { id: 'year_built_desc', defaultMessage: 'Year Built or Opened' } ),
			minWidth: 60, editable: false,
			valueFormatter: ( params ) => formatYear( params.value ),
			valueGetter: ( params: any ) => params.row.externalProperty?.entrataProperty?.yearBuilt ?? params.value
		},
		{
			field: 'bedCount',
			headerName: intl.formatMessage( { id: 'property_bed_count', defaultMessage: 'Beds' } ),
			description: intl.formatMessage( { id: 'property_bed_count_desc', defaultMessage: 'Bed Count' } ),
			valueGetter: ( v: any ) => v?.row?.externalProperty?.entrataProperty?.unitSpaceCount ?? v?.row?.bedCount,
			type: 'number',
			minWidth: 80, editable: false
		},
		{
			field: 'unitCount',
			headerName: intl.formatMessage( { id: 'property_unit_count', defaultMessage: 'Units' } ),
			description: intl.formatMessage( { id: 'property_unit_count_desc', defaultMessage: 'Unit Count' } ),
			valueGetter: ( v: any ) => v?.row?.externalProperty?.entrataProperty?.unitCount ?? v?.row?.unitCount,
			type: 'number',
			minWidth: 80,
			editable: false
		},
		{
			field: 'floorCount',
			headerName: intl.formatMessage( { id: 'property_floor_count', defaultMessage: 'Floors' } ),
			description: intl.formatMessage( { id: 'property_floor_count_desc', defaultMessage: 'Floor Count' } ),
			valueGetter: ( v: any ) => v?.row?.externalProperty?.entrataProperty?.unitCount ?? v?.row?.unitCount,
			type: 'number',
			minWidth: 80,
			editable: false
		},
		{
			field: 'parkingSpaceCount',
			headerName: intl.formatMessage( { id: 'property_parking_space_count', defaultMessage: 'Parking' } ),
			description: intl.formatMessage( {
				id: 'property_parking_space_count_desc',
				defaultMessage: 'Parking Space Count'
			} ),
			minWidth: 80,
			type: 'number',
			editable: false
		},
		{
			field: 'grossArea',
			headerName: intl.formatMessage( { id: 'property_gross_area', defaultMessage: 'Gross SF' } ),
			description: intl.formatMessage( {
				id: 'property_gross_area_desc',
				defaultMessage: 'Gross Square Footage'
			} ),
			minWidth: 80,
			type: 'number',
			editable: false
		},
		{
			field: 'residentialArea',
			headerName: intl.formatMessage( { id: 'property_residential_area', defaultMessage: 'Residential SF' } ),
			description: intl.formatMessage( {
				id: 'property_residential_area_desc',
				defaultMessage: 'Residential Square Footage'
			} ),
			minWidth: 80, editable: false
		},
		{
			field: 'distanceToCampus',
			headerName: intl.formatMessage( { id: 'property_distance_to_campus', defaultMessage: 'Distance' } ),
			description: intl.formatMessage( {
				id: 'property_distance_to_campus_desc',
				defaultMessage: 'Distance To Campus'
			} ),
			minWidth: 80, editable: false,
			valueFormatter: ( p: any ) => p ? `${ intl.formatNumber( p?.value ) } mi` : ''
		},
		{
			field: 'collegeHouseId',
			headerName: 'College House Id',
			minWidth: 300,
			description: 'College House Id'
		},

	], [] );
	const initialGridState: GridInitialStateCommunity = {
		columns: {
			columnVisibilityModel: {
				actions: true,
				id: false,
				name: true,
				type: true,
				propertyType: false,
			},
		},
		filter: {
			filterModel: {
				items: [],
				logicOperator: GridLogicOperator.And,
				quickFilterValues: [],
				quickFilterLogicOperator: GridLogicOperator.And
			},
		},
		sorting: {
			sortModel: [
				{
					field: 'name',
					sort: 'asc'
				} ],
		}
	};

	const [ rows, setRows ] = useState<Property[]>( [] );
	const [ gridState, setGridState ] = useLocalStorage( 'property-competitor-grid-model', initialGridState );
	useEffect( () => {
		if ( refresh && market?.id ) {
			setRefresh( false );
			api.getProperties( market?.id )
			   .then( ( data: Property[] ) => {
				   setRows( data ?? [] );
			   } );
		}
	}, [ refresh, market ] );

	function CustomToolbar() {
		const resetGridView = async () => {
			apiRef.current.restoreState( initialGridState );
			setGridState( initialGridState );
		};

		return (
			<GridToolbarContainer>
				<Box sx={ { flexGrow: 1 } }>
					<Button
						size="small"
						startIcon={ <Refresh/> }
						onClick={ () => {
							setRefresh( true );
						} }
						title={ intl.formatMessage( { id: 'refresh-grid', defaultMessage: 'Refresh Data' } ) }
					>
						{ intl.formatMessage( { id: 'refresh', defaultMessage: 'Refresh' } ) }
					</Button>
					<GridToolbarColumnsButton/>
					<GridToolbarFilterButton/>
					<GridToolbarExport/>
					<Button
						size="small"
						startIcon={ <Clear/> }
						onClick={ resetGridView }
						title={ intl.formatMessage( { id: 'reset-grid-config', defaultMessage: 'Reset Grid View' } ) }
					>Reset
					</Button>
				</Box>
				<Box sx={ { display: { xs: 'none', md: 'flex' } } }>
					<GridToolbarQuickFilter/>
				</Box>
			</GridToolbarContainer>
		);
	}

	return (
		<div style={ { height: 'calc(100vh - 280px)', alignContent: 'space-around', overflow: 'auto' } }>

			<Stack style={ { height: '100%', alignContent: 'center' } }>
				<CardHeader title={ `Market: ${ market?.name }` }/>
				<CardContent style={ { height: '100%' } }>

					<DataGridPremium
						apiRef={ apiRef }
						rows={ rows }
						columns={ columns }
						initialState={ gridState }
						onStateChange={ ( state ) => setGridState( state ) }
						disableRowGrouping
						pagination={ true }
						paginationMode={ 'client' }
					/>

				</CardContent>
			</Stack>

		</div>
	);

};

export default MarketProperties;

// @ts-ignore
import { useAuth } from 'base-shell/lib/providers/Auth';
import React, {
	useEffect,
	useState
} from 'react';
import useLocalStorage from 'react-use-localstorage';
import ErrorDisplay from '../../components/ErrorDisplay';
import PartnerGrid from '../../components/Vendor/PartnerGrid';
import {
	Vendor,
	VendorByType
} from '../../components/Vendor/types';

import useWindowSize from '../../components/WindowSize';
import { useVendorApi } from '../../services/vendor-api';

const Partners = () => {
	const { auth } = useAuth();
	const [ data, setData ] = useState<Vendor[]>( [] );
	const [ refresh, setRefresh ] = useState( true );
	const handleError = ErrorDisplay();
	const vendorApi = useVendorApi( handleError );
	const windowSize = useWindowSize();
	const [ view ] = useLocalStorage( 'view', 'auto' );

	useEffect( () => {
		if ( auth?.isAuthenticated ) {
			setRefresh( true );
		}
	}, [ auth ] );
	useEffect( () => {
		if ( refresh ) {
			setRefresh( false );
			vendorApi.getVendorsByType( 'EQUITY_PARTNER' )
			         .then( ( data: VendorByType ) => {
				         setData( data['EQUITY_PARTNER'] || [] );
			         } );
		}
	}, [ refresh, vendorApi ] );
	return (
		<PartnerGrid data={ data } setRefresh={ setRefresh } api={ vendorApi }/>
	);
};
export default Partners;

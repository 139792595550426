import { useState } from 'react';
import { ApiCore } from './common/api-core';
import useApiClient from './common/api-client';
import {
	TaskJobSchedule,
	TaskExecution,
	TaskExecutionResults
} from '../components/Admin/SyncJobTypes.js';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';

const url = 'admin';
const plural = '/admin';
const single = '/admin';

interface QuerySpec {
	page?: number,
	pageSize?: number,
	sort?: GridSortModel,
	filter?: GridFilterModel
}

interface QueryResults<T> {
	count: number,
	rows: T[]
}

// plural and single may be used for message logic if needed in the ApiCore class.
const API_BASE_URL = '/api';
const apiOptions = {
	getAll: false,
	getSingle: false,
	post: true,
	put: true,
	patch: false,
	delete: false,
	url: url,
	plural: plural,
	single: single
};

export const useAdminApi = ( errorCallback: any ) => {
	const client = useApiClient( { baseURL: API_BASE_URL } );
	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const api = new ApiCore( apiOptions, client );

	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}
		const data = response.data;
		if ( errorCallback ) {
			return errorCallback( data );
		}
		throw data;
	};

	const flushRedis = async ( query: any ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/flush`;
		return api.getProvider().post( url, query )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};


	const getAllTasks = async (): Promise<TaskJobSchedule[]> => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/tasks`;
		return api.getProvider().getAll( url, {} )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};
	const getTaskHistory = async (query: QuerySpec): Promise<QueryResults<TaskExecutionResults>> => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/tasks/history`;
		return api.getProvider().getAll( url, { params: query } )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const rescheduleAllJobs = async ( query = {} ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/tasks`;
		return api.getProvider().post( url, query )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const updateJob = async ( row: TaskJobSchedule ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/tasks/${ row.id }`;
		return api.getProvider().put( url, row )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const runNow = async ( task: TaskExecution, id: number ) => {

		setIsLoading( true );

		const url = `${ apiOptions.url }/tasks/${ id }`;
		return api.getProvider().post( url, task )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const setRoles = async ( query: {} ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/roles`;
		return api.getProvider().post( url, query )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};



	return {
		isLoading,
		hasError,
		getAllTasks,
		getTaskHistory,
		rescheduleAllJobs,
		updateJob,
		flushRedis,
		runNow,
		setRoles
	};
};

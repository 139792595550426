import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useMarketApi } from '../../services/market-api';
import ErrorDisplay from '../ErrorDisplay';
import useIntlPlus from '../hooks/IntlPlus';
import Tile from '../Tile';
import CollegeHouseMarketTile from './CollegeHouseMarketTile';
import { useMarketContext } from './MarketContext';


export interface MarketSummaryProps {
	readonly: boolean;
	editable: boolean;
}


const MarketSummary: React.FC<MarketSummaryProps> = ( props ) => {
	const { market, setMarket } = useMarketContext();
	const intl = useIntlPlus();
	const handleError = ErrorDisplay();
	const { save } = useMarketApi( handleError );

	const handleUpdate = () => {
		save( market ).then( data => {
			if ( data ) {
				setMarket( data );
			}
		} );
	};
	return (
		<div style={ { flexGrow: 1, height: 'inherit' } }>
			<Grid container width="100%" rowSpacing={ 1 } columnSpacing={ { xs: 1, sm: 1, md: 2 } } padding={ 1 }>
				<Grid item xs={ 12 } md={ 4 }>
					<Tile
						title="Market Details"
						readonly={ props.readonly }
						editable={ props.editable }
						fields={ [
							{
								id: 'name',
								label: 'Name',
								type: 'string',
								// description: 'Common name of the project',
							},
							{
								id: 'stateCode',
								label: 'State',
								type: 'string',
								// description: 'Common name of the project',
							},
						] }
						data={ market }
						setData={ setMarket }
						onSave={ handleUpdate }
						editProps={ { typeLabel: 'Market' } }
					/>
				</Grid>
				<Grid item xs={ 12 } md={ 4 }>
					<CollegeHouseMarketTile editable readonly={ true }/>
				</Grid>
			</Grid>
		</div>
	);
};
export default MarketSummary;

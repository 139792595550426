import {useState} from 'react';
import {ApiCore} from './common/api-core';
import useApiClient from './common/api-client';

const url = 'university';

const API_BASE_URL = '/api';
const apiOptions = {
	getAll: true,
	post: true,
	put: true,
	patch: true,
	delete: true,
	url: url,
};

export const useUniversityApi = (errorCallback) => {
	const client = useApiClient({ baseURL: API_BASE_URL });
	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const api = new ApiCore(apiOptions, client);

	const handleResponse = response => {
		setIsLoading(false);
		//return response.data || initialData;
		return response.data;
	};

	const handleError = response => {
		setIsLoading(false);
		const isError = response.status >= 400;
		setHasError(isError);
		if (!isError) return;
		const data = response.data;
		if (errorCallback) {
			return errorCallback(data);
		}
		throw data;
	};

	const getUniversities = async (config) => {
		setIsLoading(true);
		return api.getAll(config)
			.then(handleResponse)
			.catch(handleError);
	};
	const save = async (university) => {
		if (university.id) {
			return api.put(university)
				.then(handleResponse)
				.then(response => {
					return response;
				})
				.catch(handleError);
		}
		return api.post(university)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};
	const deleteUniversity= async (id) => {
		return api.delete(id)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	return {
		isLoading,
		hasError,
		getUniversities,
		saveUniversity: save,
		deleteUniversity
	};
};

import { FormatDateOptions, useIntl } from "react-intl";
import { IntlShape } from 'react-intl';

export interface IntlPlus extends IntlShape {
	formatValue: (type: string, value: any) => string;
	formatAddress: (value: any) => string;
	formatBoolean: (value: boolean) => string;
	formatCurrency: (value: number) => string;
	formatPercentage: (value: number, precision?: number) => string;
}
const formatValue = (type: string, value: any, intl: any) => {
	switch (type) {
	case 'address':
		return formatAddress(value, intl);
	case 'boolean':
		return formatBoolean(value, intl);
	case 'currency':
		return formatCurrency(value, intl);
	case 'percentage':
		return formatPercentage(value, 2, intl);
	default:
		return value;
	}
};

const formatAddress = (value: any, intl: IntlShape) => {
	const address = `${value?.streetAddress ?? ''} ${value?.city ?? ''}, ${value?.state?.code ?? value?.state ?? ''} ${value?.postalCode ?? ''}`.trim();
	return address === ',' ? '' : address;
};

const formatBoolean = (value: boolean, intl: IntlShape) => {
	return value ? intl.formatMessage({ id: 'true', defaultMessage: 'Yes' }) : intl.formatMessage({ id: 'false', defaultMessage: 'No' });
};

const formatCurrency = (value: number, intl: IntlShape) => {
	return value ? (value >= 1000000) ? `$${intl.formatNumber(value / 1000000)}M` : `$${intl.formatNumber(value)}` : '';
};

const formatPercentage = (value: number, precision: number = 2, intl: IntlShape) => {
	return value ? `${intl.formatNumber(value * 100, {
		//minimumFractionDigits: precision,
		maximumFractionDigits: precision,
	})}%` : '';
};

export const DateTimeFormat: FormatDateOptions = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric'
};

export default function useIntlPlus(): IntlPlus {
	const intl = useIntl();

	return {
		...intl,
		formatValue: (type: string, value: any) => formatValue(type, value, intl),
		formatAddress: (value: any) => formatAddress(value, intl),
		formatBoolean: (value: boolean) => formatBoolean(value, intl),
		formatCurrency: (value: number) => formatCurrency(value, intl),
		formatPercentage: (value: number, precision: number = 2) => formatPercentage(value, precision, intl),
	} as IntlPlus;
}

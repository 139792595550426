import ApiProvider from '../../services/common/api-provider';
import useApiClient from '../../services/common/api-client';

const API_BASE_URL = '/api';

/**
 * Hook for ApiProvider
 * @param config
 * @return {ApiProvider}
 */
export default function useAPI(config = {}) {
	const api = new ApiProvider(useApiClient({ baseURL: API_BASE_URL }));
	return api;
}

import React from 'react';
import { Typography } from '@mui/material';
import { DayOfWeek } from "../Admin/SyncJobTypes";

interface CronDisplayProps {
	cronString: string;
}

const CronDisplay: React.FC<CronDisplayProps> = ({ cronString }) => {
	const parts = cronString?.split(' ');

	if (parts?.length !== 5) {
		return <Typography color="textSecondary">Invalid cron string</Typography>;
	}

	const minute = parts[0] === '*'? '*': parts[0]?.padStart(2, '0');
	const hour = parts[1] === '*'? '*': parts[1]?.padStart(2, '0');
	const dayOfMonth = parts[2];
	const month = parts[3];
	const dayOfWeek: DayOfWeek = parts[4] as DayOfWeek;

	let schedule;
	if (parts.every(part => part === '*')) {
		schedule = 'Runs every minute';
	}
	else if (minute !== '*' && hour === '*' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
		schedule = `Runs every hour at minute ${minute}`;
	}
	else if (minute !== '*' && hour !== '*' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
		schedule = `Runs daily at ${hour}:${minute}`;
	}
	else if (minute !== '*' && hour !== '*' && dayOfMonth === '*' && month === '*' && dayOfWeek !== '*') {
		const daysOfWeek = {
			'SUN':'Sunday',
			'MON':'Monday',
			'TUE':'Tuesday',
			'WED':'Wednesday',
			'THU':'Thursday',
			'FRI':'Friday',
			'SAT':'Saturday'
		};
		let dayOfWeekString: any = daysOfWeek[dayOfWeek]
		schedule = `Runs weekly every ${dayOfWeekString} at ${hour}:${minute}`;
	}
	else if (minute !== '*' && hour !== '*' && dayOfMonth !== '*' && month === '*' && dayOfWeek === '*') {
		schedule = `Runs monthly on the ${dayOfMonth} day at ${hour}:${minute}`;
	}
	else if  (minute !== '*' && hour !== '*' && dayOfMonth !== '*' && month !== '*' && dayOfWeek === '*'){
		schedule = `Runs yearly in ${month} on the ${dayOfMonth} day at ${hour}:${minute}`;
	}
	else {
		schedule = `Runs on a custom schedule with ${cronString}`
	}
	return <Typography color="textSecondary">{schedule}</Typography>;
};
export default CronDisplay;

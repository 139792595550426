import { Autocomplete, TextField } from '@mui/material';
import * as React from 'react';
import { EditDataDialog } from '../EditDataDialog';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { VendorTypes } from '@corespaces/core-io-common';

const EditVendor = ({ open, onClose, onSave, vendor, setVendor, typeLabel }) =>
{
	const intl = useIntl();

	return (
		<EditDataDialog
			open={ open }
			data={ vendor }
			setData={ setVendor }
			onClose={ onClose }
			onSave={ onSave }
			typeLabel = {typeLabel}
			disableAuditNote={true}
		>
			<TextField
				id="name"
				label="Name"
				description="Vendor Full Name"
				type="string"
				required
			/>
			<TextField
				id="alias"
				label="Short Name"
				description="Vendor Short Name"
				type="string"
				required
			/>
			<TextField
				id="email"
				label="Email"
				type="string"
			/>
			<TextField
				id="phone"
				label="Phone"
				type="string"
			/>
			<Autocomplete
				multiple
				id="types"
				options={VendorTypes.map((t)=>t.type)??[]}
				filterOptions={(o)=>o}
				getOptionLabel={(option) => option ? VendorTypes.find(o=>o.type === option)?.label ?? option : ''}
				filterSelectedOptions
				renderInput={(params) => (
					<TextField
						{...params}
						variant="standard"
						label="Company Types"
					/>
				)}
			/>
		</EditDataDialog>);
};
EditVendor.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	vendor: PropTypes.object,
	setVendor: PropTypes.func
};
export default EditVendor;

import { useState } from 'react';
import {
	PartnerDetailType,
	PartnerProperty,
	PartnerReportsPackageRequest,
	Vendor,
	VendorByType
} from '../components/Vendor/types.js';
import useApiClient from './common/api-client';
import { ApiCore } from './common/api-core';

const url = 'vendors';

const API_BASE_URL = '/api';
const apiOptions = {
	getSingle: true,
	getAll: true,
	post: true,
	put: true,
	patch: true,
	delete: true,
	url: url,
};

export const useVendorApi = ( errorCallback: Function ) => {
	const client = useApiClient( { baseURL: API_BASE_URL } );
	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const api = new ApiCore( apiOptions, client );

	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}
		const data = response.data;
		if ( errorCallback ) {
			return errorCallback( data );
		}
		throw data;
	};

	const getVendors = async () => {
		setIsLoading( true );
		return api.getAll()
			.then( handleResponse )
			.catch( handleError );
	};

	const getVendor = async ( id: number ) => {
		setIsLoading( true );
		return api.getSingle( id )
			.then( handleResponse )
			.catch( handleError );
	};

	const getPartnerDetails = async ( id: number ): Promise<PartnerDetailType> => {
		setIsLoading( true );
		return api.getSingle( id )
			.then( handleResponse )
			.catch( handleError );
	};

	const getPartnerProperties  = async ( id: number, date?: string ): Promise<PartnerProperty[]> => {
		const url = `${ apiOptions.url }/${id}/properties?date=${date}`;
		setIsLoading( true );
		return api.getProvider()
			.getAll( url )
			.then( handleResponse )
			.catch( handleError );
	};



	const getVendorsByType = async ( type?: string ) :Promise<VendorByType> => {
		const url = type ? `${ apiOptions.url }/by-type/${ type }` : `${ apiOptions.url }/by-type`;
		return api.getProvider()
			.getAll( url )
			.then( handleResponse )
			.catch( handleError );
	};

	const save = async ( vendor: Vendor ) => {
		if ( vendor.id ) {
			return api.put( vendor )
				.then( handleResponse )
				.then( response => {
					return response;
				} )
				.catch( handleError );
		}
		return api.post( vendor )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const deleteVendor = async ( id: number ) => {
		return api.delete( id )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const packageVendorReports = async ( data:PartnerReportsPackageRequest, config?:any) => {
		setIsLoading(true);
		return api
			.getProvider()
			.post( `${apiOptions.url}/package-reports`, data, config )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	return {
		isLoading,
		hasError,
		getVendors,
		getVendor,
		getVendorsByType,
		saveVendor: save,
		deleteVendor,
		getPartnerDetails,
		getPartnerProperties,
		packageVendorReports
	};
};

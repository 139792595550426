import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { Button, Checkbox, Divider, IconButton, ListItemText } from '@mui/material';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { SwitchAccount } from '@mui/icons-material';

interface AnchorState {
  buttonElement: HTMLElement | undefined;
}
interface RolePickerProps {
  auth: any,
  intl: any,
  apply: (roles:string[])=>{},
  disabled: boolean
}

interface Role {
  code: string,
  name: string,
  readonly?: boolean,
}

export default function RolePicker({auth, intl, apply, disabled}: RolePickerProps) {
	const [ anchorEl, setAnchorEl ] = useState<AnchorState>();
	const [selectedRoles, setSelectedRoles] = useState<string[]>(auth?.roles);
	const [isDirty, setIsDirty] = useState(false);
	const roles: Role[] = [
		{ code: 'user', name: intl.formatMessage( { id: 'false', defaultMessage: 'User' } ), readonly: true },
		{ code: 'admin', name: intl.formatMessage( { id: 'admin', defaultMessage: 'Admin' } ) },
		{
			code: 'capital-markets-editor',
			name: intl.formatMessage( { id: 'capital-markets-editor', defaultMessage: 'Capital Markets Editor' } )
		},
		{
			code: 'asset-management-editor',
			name: intl.formatMessage( { id: 'asset-management-editor', defaultMessage: 'Asset Management Editor' } )
		},
		{
			code: 'asset-management',
			name: intl.formatMessage( { id: 'asset-management-editor', defaultMessage: 'Asset Management' } )
		},
		{
			code: 'btr-editor',
			name: intl.formatMessage( { id: 'asset-management-editor', defaultMessage: 'BTR Editor' } )
		},
		{
			code: 'business-systems-editor',
			name: intl.formatMessage( { id: 'business-systems-editor', defaultMessage: 'Business Systems Editor' } )
		},
		{
			code: 'community-management-editor',
			name: intl.formatMessage( { id: 'community-management-editor', defaultMessage: 'Community Management Editor' } )
		},
		{ code: 'editor', name: intl.formatMessage( { id: 'editor', defaultMessage: 'Editor' } ) },
		{
			code: 'finance-viewer',
			name: intl.formatMessage( { id: 'finance-viewer', defaultMessage: 'Finance Viewer' } )
		},
		{
			code: 'finance-editor',
			name: intl.formatMessage( { id: 'finance-editor', defaultMessage: 'Finance Editor' } )
		},
		{ code: 'legal-viewer', name: intl.formatMessage( { id: 'legal-viewer', defaultMessage: 'Legal Viewer' } ) },
		{ code: 'legal-editor', name: intl.formatMessage( { id: 'legal-editor', defaultMessage: 'Legal Editor' } ) },
		{ code: 'project-editor', name: intl.formatMessage( { id: 'project-editor', defaultMessage: 'Project Editor' } ) },
		{ code: 'project-viewer', name: intl.formatMessage( { id: 'project-viewer', defaultMessage: 'Project Viewer' } ) },
		{
			code: 'student-acquisition-editor',
			name: intl.formatMessage( { id: 'student-acquisition-editor', defaultMessage: 'Student Acquisition Editor' } )
		},
		{
			code: 'student-development-editor',
			name: intl.formatMessage( { id: 'student-development-editor', defaultMessage: 'Student Development Editor' } )
		},
	];
	const confirm = useConfirm();

	const handleApply = async () => {
		apply(selectedRoles);
		setIsDirty(false);
	};

	const handleClose = async () =>{
		setAnchorEl(undefined);
		return isDirty && confirm({title: 'Apply Roles', description: 'Do you wish to apply the selected Roles?'})
			.then(async ()=> {
				apply(selectedRoles);
				setIsDirty(false);
			})
			.catch((e)=>{ e && console.error(e); });
	};

	const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
		// @ts-ignore
		const { target: { id, checked = false } } = event;
		if (checked) {
			if (!selectedRoles.includes(id)) {
				setSelectedRoles([...selectedRoles, id]);
				setIsDirty(true);
			}
		} else {
			if (selectedRoles.includes(id)) {
				setSelectedRoles(selectedRoles.filter(item => item !== id));
				setIsDirty(true);
			}
		}
	};
	const handleToggle = (event: React.ChangeEvent<HTMLElement>) => {
		// @ts-ignore
		const { target: { id, checked = false } } = event;
		setSelectedRoles(!checked ? ['user'] : roles.map(r=>r.code));
	};

	// @ts-ignore
	return (
		<div>
			<IconButton
				size="large"
				aria-label="Set Role"
				color="inherit"
				disabled={ disabled }
				//@ts-ignore
				onClick={ ( event ) => setAnchorEl( event.currentTarget ) }>
				<Tooltip title="Change Roles">
					<SwitchAccount/>
				</Tooltip>
			</IconButton>
			<Menu
				//@ts-ignore
				anchorEl={ anchorEl }
				open={ Boolean( anchorEl ) }
				onClose={ handleClose }>
				<MenuItem>
					<Checkbox
						checked ={selectedRoles.length === roles.length}
						onChange={ handleToggle }
					/>
					<ListItemText primary='Select All' />
				</MenuItem>
				<Divider/>
				{ roles.map( r =>
					//@ts-ignore
					<MenuItem key={r.code} size="small" title={`Toggle Role: ${r.name}`}
						disabled={r.readonly}
						//@ts-ignore
						onClick={(e)=>handleChange({...e, target: { id: r.code, checked: !selectedRoles.includes(r.code) }})}
					>
						<Checkbox id={r.code} checked={ selectedRoles.includes(r.code) } onChange={handleChange} />
						<ListItemText primary={r.name} />
					</MenuItem>
				) }
				<Divider/>
				<MenuItem key='apply' value='apply' sx={{textAlign: 'center'}} >
					<Button sx={{textAlign: 'center', width:'100%'}} onClick={handleApply}>Apply</Button>
				</MenuItem>
			</Menu>
		</div>);
}

import * as React from 'react';
import { EditDataDialog } from '../EditDataDialog';
import { useIntl } from 'react-intl';
import { User } from './types';
import InputField from '../InputField';


interface UserEditProps {
  open: boolean,
  onClose: any,
  onSave: any,
  user: User|any
}

export const UserEdit: React.FC<UserEditProps> = ({ open, onClose, onSave, user }) => {
	const intl = useIntl();

	return (
		<EditDataDialog
			open={ open }
			data={ user }
			onClose={ onClose }
			onSave={ onSave }
			typeLabel={ intl.formatMessage({id:'user',defaultMessage:'user'})}
		>
			<InputField
				id="name"
				label="Name"
				type="string"
				required
				readOnly={true}
			/>
			<InputField
				id="externalId"
				label="External Id"
				type="number"
				readOnly={true}
				value={user?.externalId ?? user?.sub }
			/>
			<InputField
				id="email"
				label="email"
				readOnly={true}
			/>
		</EditDataDialog>);
};

export default UserEdit;

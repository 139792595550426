import React, { useEffect, useMemo, useState } from 'react';
import {
	debounce,
	LinearProgress,
	Link
} from '@mui/material';
import ErrorDisplay from '../ErrorDisplay';
import {
	DataGridPremium,
	gridClasses,
	GridColDef, GridFilterModel,
	GridInitialState, GridPaginationModel,
	GridRenderCellParams, GridSortModel, useGridApiRef
} from '@mui/x-data-grid-premium';
import CoreDataGridToolbarBasic from '../grid/CoreDataGridToolbarBasic';
import PageTitle from '../hooks/PageTitle';
import useGridHelper from '../hooks/GridHelper';
import { TaskExecutionResults } from './SyncJobTypes';
import { useIntl } from 'react-intl';
import { useAdminApi } from '../../services/admin-api';
import useLocalStorage from '../hooks/LocalStorage';

const TaskHistoryGrid = () => {
	PageTitle({ id: 'audit', defaultTitle: 'Task History' });
	const handleError = ErrorDisplay();
	const api = useAdminApi(handleError);
	const intl = useIntl();
	const { getDate } = useGridHelper();
	const gridApiRef = useGridApiRef();
	const columns = useMemo<GridColDef[]>(() =>
		[
			{
				field: 'requestId',
				type: 'string',
				flex: 1,
				renderCell:(p:GridRenderCellParams<TaskExecutionResults>)=>(
					p.value && <Link
						href={p.row.logLink}
						target={p.value}>{p.value}
					</Link>),
				headerName: intl.formatMessage({
					id: 'job.taskname',
					defaultMessage: 'RequestId'
				}),
			},
			{
				field: 'taskExecutionId',
				type: 'string',
				flex: 1,
				sortable:false,
				valueGetter:(p)=>p.value,
				groupingValueGetter:(p)=>`${p.row.task?.job?.name ?? p.row.task?.taskDefinition?.name}(${p.value})`,
				headerName: intl.formatMessage({
					id: 'job.taskExecution',
					defaultMessage: 'Task Execution'
				}),
			},
			{
				field: 'task.taskType',
				type: 'string',
				flex: 1,
				valueGetter:(p)=>p.row.task?.taskType,
				headerName: intl.formatMessage({
					id: 'job.taskType',
					defaultMessage: 'Task Type'
				}),
			},
			{
				field: 'task.configuration',
				type: 'string',
				flex: 1,
				valueGetter:(p)=>JSON.stringify(p.row.task?.configuration),
				headerName: intl.formatMessage({
					id: 'job.taskConfiguration',
					defaultMessage: 'Configuration'
				}),
			},
			{
				field: 'task.parameters.organizationKey',
				type: 'string',
				flex: 1,
				valueGetter:(p)=>p.row.task?.parameters?.organizationKey,
				headerName: intl.formatMessage({
					id: 'job.options.taskConfiguration',
					defaultMessage: 'Organization'
				}),
			},
			// {
			// 	field: 'task.configuration.options.queueByType',
			// 	type: 'string',
			// 	flex: 1,
			// 	valueGetter:(p)=>p.row.task?.configuration?.options?.queueByType,
			// 	headerName: intl.formatMessage({
			// 		id: 'job.taskConfiguration',
			// 		defaultMessage: 'EnqueueBy'
			// 	}),
			// },
			// {
			// 	field: 'task.configuration.options.propertyIds',
			// 	type: 'string',
			// 	flex: 1,
			// 	valueGetter:(p)=>p.row.task?.configuration?.options?.propertyIds?.toString(),
			// 	headerName: intl.formatMessage({
			// 		id: 'job.taskConfiguration',
			// 		defaultMessage: 'Enqueue Ids'
			// 	}),
			// },
			{
				field: 'task.taskDefinition.name',
				type: 'string',
				flex: 1,
				valueGetter:(p)=>p.row.task?.taskDefinition?.name,
				groupingValueGetter:(p)=>p.row.task?.taskDefinition?.name,
				headerName: intl.formatMessage({
					id: 'job.taskname',
					defaultMessage: 'Task Name'
				}),
			},
			{
				field: 'task.job.name',
				type: 'string',
				flex: 1,
				groupable: true,
				valueGetter:(p)=>p.row.task?.job?.name,
				groupingValueGetter:(p)=>p.row.task?.job?.name,
				headerName: intl.formatMessage({
					id: 'job.jobname',
					defaultMessage: 'Job Name'
				}),
			},
			{
				field: 'cloudTaskName',
				type: 'string',
				flex: 1,
				groupable: true,
				headerName: intl.formatMessage({
					id: 'job.taskname',
					defaultMessage: 'Cloud Task Name'
				}),
			},
			{
				field: 'startTime',
				type: 'dateTime',
				valueGetter: getDate,
				flex: 1,
				headerName: intl.formatMessage({
					id: 'job.startTime',
					defaultMessage: 'Start Time'
				}),
			},
			{
				field: 'endTime',
				type: 'dateTime',
				valueGetter: getDate,
				flex: 1,
				headerName: intl.formatMessage({
					id: 'job.endTime',
					defaultMessage: 'End Time'
				}),
			},
			{
				field: 'status',
				minWidth: 60,
				type: 'string',
				flex:.5,
				headerName: intl.formatMessage({
					id: 'job.status',
					defaultMessage: 'Status'
				}),
			},
			{
				field: 'results',
				minWidth: 200,
				type: 'string',
				flex: 2,
				filterable: false,
				groupable: false,
				valueFormatter: (p)=>p.value && JSON.stringify(p.value,null,2),
				headerName: intl.formatMessage({
					id: 'job.status',
					defaultMessage: 'Results'
				}),
			},
			{
				field: 'results.totalCount',
				minWidth: 60,
				type: 'number',
				flex: 1,
				filterable: false,
				groupable: false,
				valueGetter: p=>p.row.results?.totalCount,
				headerName: intl.formatMessage({
					id: 'job.status',
					defaultMessage: 'Total Count'
				}),
			},
			{
				field: 'createdAt',
				type: 'dateTime',
				valueGetter: getDate,
				flex: 1,
				headerName: intl.formatMessage({
					id: 'job.createdAt',
					defaultMessage: 'Created'
				}),
			},
			{
				field: 'updatedAt',
				type: 'dateTime',
				valueGetter: getDate,
				flex: 1,
				headerName: intl.formatMessage({
					id: 'job.createdAt',
					defaultMessage: 'Updated'
				}),
			},
		], []);
	let initialFilter: GridFilterModel = {
		items:[],
	};
	const initialGridState: GridInitialState = {
		columns: {
			columnVisibilityModel: {
				cloudTaskName: false,
				'task.configuration': false,
				createdAt: false,
			},
		},
		filter:{
			// filterModel: {
			// 	items: [
			// 		{
			// 			field: 'updatedAt',
			// 			operator: 'onOrAfter',
			// 			value: d.toISOString().substring(0,16)
			// 		}
			// 	],
			// },
		},
		sorting: {
			sortModel: [{
				field: 'updatedAt',
				sort: 'desc'
			}],
		}
	};
	const [gridState, setGridState] = useLocalStorage('task-history-grid-model', initialGridState);
	const [refresh, setRefresh] = useState(true);
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({page: 0, pageSize: 100});
	const [filter, setFilter] = useState<GridFilterModel>(initialFilter);
	const [sort, setSort] = useState<GridSortModel>([]);
	//const [columnModel, setColumnModel] = useState(initialGridState.columns.columnVisibilityModel)
	const [rowCount, setRowCount] = useState(0);
	const [rows, setRows] = useState<TaskExecutionResults[]>([]);

	const fetch = debounce((paginationModel:GridPaginationModel, sort:GridSortModel, filter:GridFilterModel)=>{
		const { page, pageSize } = paginationModel;
		api.getTaskHistory( { page, pageSize, sort, filter } )
			.then( ( data ) => {
				const { count, rows } = data ?? {};
				setRowCount( count );
				setRows( rows ?? [] );
			} );
	},1000);

	useEffect(() => {
		fetch(paginationModel, sort, filter);
		// return () => {
		// 	fetch.clear();
		// };
	}, [refresh, paginationModel, sort, filter]);

	return (
		<div style={ { height: '99%', width: '100%', alignContent: 'space-around' } }>
			<DataGridPremium
				sx={{
					[`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
						outline: 'none',
					},
					[`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
							{
								outline: 'none',
							},
				}}
				apiRef={gridApiRef}
				rows={ rows }
				columns={ columns }
				initialState={gridState}
				onStateChange={(state)=>setGridState(state)}
				pagination
				paginationMode='server'
				rowCount={rowCount}
				unstable_cellSelection={true}
				experimentalFeatures={{ clipboardPaste: true }}
				onPaginationModelChange={(model)=>setPaginationModel(model)}
				onSortModelChange={(model)=>setSort(model)}
				onFilterModelChange={(model)=>setFilter(model)}
				slots={ {
					toolbar: CoreDataGridToolbarBasic,
					loadingOverlay: LinearProgress
				} }
				slotProps={ {
					toolbar: { handleRefresh: ()=>setRefresh(!refresh) }
				} }
			/>
		</div>
	);
};

export default TaskHistoryGrid;

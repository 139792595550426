import * as React from 'react';
import { useIntl } from 'react-intl';
import {
	DataGridPremium,
	GridActionsCellItem, GridColDef,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
	useGridApiContext
} from '@mui/x-data-grid-premium';
import { Avatar, Button, IconButton, LinearProgress, Link } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorDisplay from '../ErrorDisplay';
import { useSnackbar } from 'notistack';
import { Clear, Refresh } from '@mui/icons-material';
import PageTitle from '../hooks/PageTitle';
import UserEdit from './UserEdit';
import useLocalStorage from '../hooks/LocalStorage';
import {User} from './types';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../AbilityContext.js';
import ContactCard from '../ContactCard';
import Tooltip from '@mui/material/Tooltip';

const defaultSortModel=[{ field: 'name', sort: 'asc' }];
const defaultFilterModel={ items:[] };
const defaultColumnVisibilityModel={
	id: false,
	name: true,
	createdAt: false,
	updatedAt: false
};
interface UserGridProps {
  data: [],
  setRefresh: Function,
  api: { remove: Function, save: Function, isLoading: boolean }
}
export const UserGrid: React.FC<UserGridProps> = ({data, setRefresh, api}) => {
	const { enqueueSnackbar } = useSnackbar();
	const handleError = ErrorDisplay();
	const intl = useIntl();
	const { remove, save } = api;
	const newUser = {types:[]};
	const [user, setUser] = React.useState(newUser);
	const getDate = (params: any) => params.value ? new Date(params.value): params.value;
	const formatDate = (params: any) => {
		if (!params.value) return params.value;
		return intl.formatDate(params.value, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		});
	};
	PageTitle({ id: 'users', defaultTitle: 'Markets' });
	const [gridState, setGridState] = useLocalStorage('user-grid-model',{
		columns: { columnVisibilityModel: defaultColumnVisibilityModel },
		sorting: { sortModel: defaultSortModel },
		filtering: { filterModel: defaultFilterModel },
		pinnedColumns:{
			right: ['updatedAt','actions'],
			left: []
		}
	});
	const ability = useAbility(AbilityContext);
	const [open, setOpen] = React.useState(false);
	const [canEdit] =  React.useState(ability.can('update', 'Market'));
	const [canDelete] =  React.useState(ability.can('delete', 'Market'));
	const editRow = React.useCallback(
		(params: any) => () => {
			setUser(params.row);
			setOpen(true);
		},
		[]
	);
	const deleteRow = React.useCallback(
		(params: any) => async () => {
			try
			{
				const data = await remove(params.row.id);
				if (data && data.deleted)
				{
					setRefresh(true);
					enqueueSnackbar(`Deleted ${ data.deleted || 0 } Markets`, {
						variant: 'info',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center'
						}
					});
				}
			} catch (e)
			{
				await handleError(e);
			}
		},
		[remove, enqueueSnackbar, handleError, setRefresh]
	);
	const columns: GridColDef<User>[] = useMemo(() => [
		{ field: 'actions', type: 'actions', maxWidth: 20, minWidth: 20, width: 20,
			getActions: (params: any) => [
				<GridActionsCellItem icon={ <EditIcon /> } onClick={ editRow(params) } label="Edit" showInMenu disabled={ !canEdit } />,
				<GridActionsCellItem icon={ <DeleteIcon /> } onClick={ deleteRow(params) } label="Delete" showInMenu disabled={ !canDelete } />
			]
		},
		{ field: 'picture', headerName: 'User', minWidth: 300, description: 'Profile Picture',
			groupable: false,
			renderCell: (params) => {
				const value = params.row;
				return value.id && <Tooltip key={`${value.id}-title`} title={<ContactCard contact={value} />} placement="top">
					<IconButton
						disabled={!value?.id}
						target="_blank" href={`https://contacts.google.com/person/${value?.sub}`}>
						<Avatar src={value?.picture} />{value.name}
					</IconButton>
				</Tooltip>;
			},
		},
		{ field: 'name', headerName: 'Name', minWidth: 300, description: 'User Full Name',
			renderCell: (params) => (
				<Link id={ `user_name_link${params.row.id}` } component={RouterLink} to={ `/admin/iam/users/${ params.row.id }` }>{ params.value }</Link>
			), groupable: false
		},
		{
			field: 'email', headerName: 'Email', minWidth: 80, editable: true, groupable: true,
			description: 'Email',
		},
		{
			field: 'sub',
			headerName: 'Sub',
			description: 'Google Subject Id',
			minWidth: 220, editable: false, groupable: false,
			renderCell: (params) => (
				<Link id={`sub_link${params.row.id}` } href={`https://contacts.google.com/person/${params.value}`} target={params.value}>{ params.value }</Link>
			),
		},
		{ field: 'title', headerName: 'Title', minWidth: 300, description: 'Title' },
		{ field: 'department', headerName: 'Department', minWidth: 300, description: 'User Department' },
		{ field: 'buildingId', headerName: 'Location', minWidth: 300, description: 'User Location' },
		{ field: 'createdAt', headerName: 'Created', minWidth: 200, type: 'dateTime', valueFormatter: formatDate, valueGetter: getDate },
		{ field: 'updatedAt', headerName: 'Updated', minWidth: 200, type: 'dateTime', valueFormatter: formatDate, valueGetter: getDate },
	], []);
	const handleClickAdd = async () =>
	{
		setOpen(true);
	};
	const handleClose = (saved: boolean) =>
	{
		setOpen(false);
		setUser(newUser);
		setRefresh(saved);
	};

	function CustomToolbar()
	{
		const apiRef = useGridApiContext();
		return (
			<GridToolbarContainer>
				<Button
					size="small"
					startIcon={ <AddIcon /> }
					onClick={ handleClickAdd }
					disabled={ !canEdit }
					title={ intl.formatMessage({ id: 'add_user', defaultMessage: 'Add User' }) }
				>
					{ intl.formatMessage({ id: 'add', defaultMessage: 'Add' }) }
				</Button>
				<Button
					size="small"
					startIcon={ <Refresh /> }
					onClick={ () =>
					{
						setRefresh(true);
					} }
					title={ intl.formatMessage({ id: 'refresh-grid', defaultMessage: 'Refresh Data' }) }
				>
					{ intl.formatMessage({ id: 'refresh', defaultMessage: 'Refresh' }) }
				</Button>
				<GridToolbarColumnsButton/>
				<GridToolbarFilterButton/>
				<GridToolbarExport />
				<Button
					size="small"
					startIcon={ <Clear /> }
					onClick={ () => {
						apiRef.current.restoreState({
							...gridState,
							columns: { columnVisibilityModel: defaultColumnVisibilityModel },
							sorting: { sortModel: defaultSortModel },
							filter: { filterModel: defaultFilterModel },
							pinnedColumns: {}
						});
					} }
					title={ intl.formatMessage({ id: 'reset-grid-config', defaultMessage: 'Reset Grid Settings' }) }
				>
					{ intl.formatMessage({ id: 'reset', defaultMessage: 'Reset' }) }
				</Button>
			</GridToolbarContainer>
		);
	}

	return (
		<div style={ { height: '99%', width: '99%', alignContent: 'space-around' } }>
			<UserEdit
				open={ open }
				onClose={ handleClose }
				user={ user }
				onSave={ save }
			/>
			<DataGridPremium
				rows={ data }
				columns={ columns }
				pagination
				disableRowGrouping={ false }
				checkboxSelection={ false }
				checkboxSelectionVisibleOnly={ false }
				initialState={gridState}
				onStateChange={(state)=>setGridState(state)}
				editMode="row"
				sortingOrder={ ['desc', 'asc'] }
				slots={ {
					toolbar: CustomToolbar,
					loadingOverlay: LinearProgress,
				} }
				// getDetailPanelContent={({ row })=>{
				// 	return <ContactCard contact={row}/>;
				// }}
			/>
		</div>
	);
};

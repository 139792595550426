import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';

declare global {
	//FIXME.  google thing is type safe but I cant get it to compile in docker
	interface Window {
		google: any
		env: any
	}
}

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App/>);
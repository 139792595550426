import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Typography
} from '@mui/material';
import React, {
	useEffect,
	useState
} from 'react';
import APIAutocomplete from '../APIAutocomplete';
import JsonContent from '../JsonContent';
import ExternalPropertyAutocomplete from '../Property/ExternalPropertyAutocomplete';
import PropertyAutocomplete from '../Property/PropertyAutocomplete';
import {
	JobNowDialogProps,
	TaskExecution,
	TaskJobSchedule
} from './SyncJobTypes';


type ExternalPropertyOption = {
	id: string,
	marketingName: string,
	organization: string
}

type PropertyOption = {
	id: string,
	marketingName: string
}

type VendorOption = {
	id: string,
	name: string
}


function ConfirmAllPropsDialog( { open, onClose }: { open: boolean, onClose: ( response: boolean ) => any } ) {

	const handleCancel = () => {
		onClose( false );
	};

	const handleOk = () => {
		onClose( true );
	};

	return (
		<Dialog
			sx={ { '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } } }
			open={ open }
		>
			<DialogTitle>Run for all properties?</DialogTitle>
			<DialogContent dividers>
				<Typography> This will execute for all available (projects,properties,vendors,etc) and may take time to complete.
					Are you sure?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={ handleCancel }>
					Cancel
				</Button>
				<Button onClick={ handleOk }>Ok</Button>
			</DialogActions>
		</Dialog>
	);
}

type TaskExecutionFieldsParams = {
	editable: boolean,
	taskExecution: TaskExecution,
	job: TaskJobSchedule,
	updateParameters: ( parameters: any ) => void
	updateSubTasks?: ( ( subTasks: any[] ) => void ) | undefined,
};
export const TaskExecutionFields = ( props: TaskExecutionFieldsParams ) => {

	return <>

		<Typography height={ '20px' }>Parameters</Typography>
		<JsonContent
			editable={ props.editable }
			data={ props.taskExecution.parameters }
			updateContent={ props.updateParameters }
		/>
		{
			( props.taskExecution.configuration.subTasks && props.updateSubTasks ) &&
			<>
				<Typography height={ '20px' }>Triggers Tasks</Typography>
				<JsonContent
					editable={ props.editable }
					data={ props.taskExecution.configuration.subTasks }
					updateContent={ props.updateSubTasks }
				/>
			</>
		}
	</>;
};


export interface JobRunNowDialogModel {
	job: TaskJobSchedule,
	taskExecution: TaskExecution
	editable: boolean
}


const JobRunNowDialog = ( { taskDefinition, job, open, handleRunNow, handleClose }: JobNowDialogProps ) => {
	const [ error, setError ] = useState( false );

	const [ confirmAllPropsOpen, setConfirmAllPropsOpen ] = useState<boolean>( false );
	const [ model, setModel ] = useState<JobRunNowDialogModel>();
	const [ editable, setEditable ] = useState( true );
	useEffect( () => {
		if ( job ) {
			const taskExecution = {
				...taskDefinition,
				subTasks: job?.subTasks,
				parameters: {
					...taskDefinition?.defaultParameters,
					...job.jobParameters
				},
				configuration: {
					...job.jobConfiguration
				}
			};
			const queueByType = job.jobConfiguration?.options?.queueByType;
			const newModel = {
				job,
				taskExecution,
				editable
			};
			setModel( newModel );
		}
	}, [ job ] );


	const handlePropertyChanged = ( event: any, newValue: PropertyOption[], reason: any ) => {
		console.info( 'handlePropertyChanged' );

		if ( model && newValue ) {
			setError( false );
			const propertyIds = newValue.map( ( it ) => it.id );
			let configuration = { ...model.taskExecution.configuration };
			let options = {
				...configuration.options,
				propertyIds
			};
			const taskExecution: TaskExecution = {
				...model.taskExecution,
				parameters: {
					...model.taskExecution.parameters,
				},
				configuration: {
					...configuration,
					options
				}
			};
			setModel(
				{
					job: model?.job,
					taskExecution,
					editable
				} );
		}
	};

	const handleEntrataPropertyChanged = ( event: any, newValue: ExternalPropertyOption[], reason: any ) => {
		console.info( 'handleEntrataPropertyChanged' );
		if ( model && newValue ) {
			setError( false );
			const entrataPropertyIds = newValue.map( ( it ) => it.id );
			let configuration = { ...model.taskExecution.configuration };
			let options = {
				...configuration.options,
				entrataPropertyIds
			};
			const taskExecution: TaskExecution = {
				...model.taskExecution,
				parameters: {
					...model.taskExecution.parameters,
				},
				configuration: {
					...configuration,
					options
				}
			};
			setModel(
				{
					job: model?.job,
					taskExecution,
					editable
				} );
		}
	};

	const handleProjectChanged = ( event: any, newValue: any, reason: any ) => {
		if ( model && newValue && newValue.northspyre_id ) {
			setError( false );
			const northspyreProjectIds = [ newValue.northspyre_id ];
			let configuration = { ...model.taskExecution.configuration };
			let options = {
				...configuration.options,
				northspyreProjectIds
			};
			const taskExecution: TaskExecution = {
				...model.taskExecution,
				parameters: {
					...model.taskExecution.parameters,
				},
				configuration: {
					...configuration,
					options
				}
			};
			setModel(
				{
					job: model?.job,
					taskExecution,
					editable
				} );
		}
	};

	const handleVendorChanged = ( event: any, newValue: VendorOption[], reason: any ) => {
		if ( model && newValue ) {
			setError( false );

			const vendorIds = newValue.map( ( it: VendorOption ) => it.id );
			let configuration = { ...model.taskExecution.configuration };
			let options = {
				...configuration.options,
				vendorIds
			};
			const taskExecution: TaskExecution = {
				...model.taskExecution,
				parameters: {
					...model.taskExecution.parameters,
				},
				configuration: {
					...configuration,
					options
				}
			};
			setModel(
				{
					job: model?.job,
					taskExecution,
					editable
				} );
		}
	};

	const handleSubTaskChanged = ( subTasks: string[] ) => {
		if ( model ) {
			const taskExecution: TaskExecution = {
				...model.taskExecution,
				configuration: {
					...model.taskExecution.configuration,
					subTasks
				}
			};
			setModel(
				{
					job: model?.job,
					taskExecution,

					editable
				} );
		}
	};

	const updateParameters = ( parameters: any ) => {
		setError( false );

		if ( model ) {
			setModel( {
				job: model.job,
				taskExecution: {
					...model.taskExecution,
					parameters
				},
				editable
			} );
		}
	};

	const doHandleRunNow = () => {
		if ( !model ) {
			return;
		}
		const taskExecution = model.taskExecution as TaskExecution;
		if ( taskExecution.configuration?.options?.queueByType === 'entrataProperty' ) {
			const propertyIds = taskExecution.configuration?.options.entrataPropertyIds ?? [];
			if ( Number( propertyIds.length ) <= 0 ) {
				setConfirmAllPropsOpen( true );
				return;
			}
		}
		else if ( taskExecution.configuration?.options?.queueByType === 'northspyreProject' ) {
			const northspyreProjectIds = taskExecution.configuration?.options.northspyreProjectIds ?? [];
			if ( Number( northspyreProjectIds.length ) <= 0 ) {
				setConfirmAllPropsOpen( true );
				return;
			}
		}
		else if ( taskExecution.configuration?.options?.queueByType === 'property' ) {
			const northspyreProjectIds = taskExecution.configuration?.options.propertyIds ?? [];
			if ( Number( northspyreProjectIds.length ) <= 0 ) {
				setConfirmAllPropsOpen( true );
				return;
			}
		}
		else if ( taskExecution.configuration?.options?.queueByType === 'vendor' ) {
			const vendorIds = taskExecution.configuration?.options.vendorIds ?? [];
			if ( Number( vendorIds.length ) <= 0 ) {
				setConfirmAllPropsOpen( true );
				return;
			}
		}

		handleRunNow( taskExecution, model.job );
	};

	function doHandleConfirmAllProps( response: boolean ) {
		if ( !model ) {
			return;
		}
		setConfirmAllPropsOpen( false );
		if ( response ) {
			const taskExecution = model.taskExecution as TaskExecution;
			handleRunNow( taskExecution, model.job );
		}
	}

	const isPropertyTask = () => {
		return model?.job.jobConfiguration?.options?.queueByType === 'property';
	};
	const isEntrataPropertyTask = () => {
		return model?.job.jobConfiguration?.options?.queueByType === 'entrataProperty';
	};
	const isProjectTask = () => {
		return model?.job.jobConfiguration?.options?.queueByType === 'northspyreProject';
	};
	const isVendorTask = () => {
		return model?.job.jobConfiguration?.options?.queueByType === 'vendor';
	};
	return (
		<>
			<Dialog open={ open } onClose={ handleClose } fullWidth>
				<DialogTitle>Task Settings</DialogTitle>
				<DialogContent>
					<Stack>

						{
							isPropertyTask()
							&& (
								<>
									<Typography height={ '20px' }>Property</Typography>
									<PropertyAutocomplete
										multiple
										propertyId={ undefined }
										fullWidth
										readOnly={ false }
										disabled={ !isPropertyTask() }
										label={ 'Property' }
										onChange={ handlePropertyChanged }/>
								</> )
						}
						{
							isEntrataPropertyTask()
							&& (
								<>
									<Typography height={ '20px' }>Property</Typography>
									<ExternalPropertyAutocomplete
										multiple
										propertyId={ undefined }
										organization={ model?.job.jobConfiguration?.options?.organizationKey }
										fullWidth
										readOnly={ false } //FIXME
										disabled={ !isEntrataPropertyTask() }
										label={ 'Property' }
										onChange={ handleEntrataPropertyChanged }/>
								</> )
						}
						{
							isProjectTask()
							&& ( <>
								<Typography height={ '20px' }>Project</Typography>
								<APIAutocomplete
									url={ 'projects/northspyre' }
									onChange={ handleProjectChanged }
									getOptionLabel={ ( option ) => option.project_name }/>
							</> )
						}
						{
							isVendorTask()
							&& ( <>
								<Typography height={ '20px' }>Partner</Typography>
								<APIAutocomplete
									url={ `/vendors?type=EQUITY_PARTNER` }
									multiple={ true }
									onChange={ handleVendorChanged }
									getOptionLabel={ ( option ) => option.name }/>
							</> )
						}
						{ model && ( <TaskExecutionFields
							taskExecution={ model?.taskExecution }
							job={ model.job }
							editable={ true }
							updateSubTasks={ handleSubTaskChanged }
							updateParameters={ updateParameters }/> ) }
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={ handleClose }>Cancel</Button>
					<Button onClick={ doHandleRunNow }>Run Now</Button>
				</DialogActions>
			</Dialog>

			<ConfirmAllPropsDialog onClose={ doHandleConfirmAllProps }
			                       open={ confirmAllPropsOpen }></ConfirmAllPropsDialog>
		</>
	);
};

export default JobRunNowDialog;


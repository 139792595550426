export function handleResponse(response) {
	return response?.response || response;
}

export function handleError(error) {

	// // if api error, use that, otherwise, get response message
	// let {response: { data }} = error;
	// if (data?.message) {
	// 	throw data;
	// }
	console.error(error);
	throw error.response || error;
}

import {
	AppBar,
	CardContent,
	Link,
	Stack
} from '@mui/material';
import {
	DataGridPremium,
	GridColDef,
	GridRenderCellParams,
	GridToolbar,
	useGridApiRef
} from '@mui/x-data-grid-premium';
import { useAuth } from 'base-shell/lib/providers/Auth';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import ErrorDisplay from '../ErrorDisplay';
import useIntlPlus from '../hooks/IntlPlus';

import { useReportingApi } from '../../services/reporting-ui-api';

type ReportRow = {
	id: number,
	name: string,
	count: number
}

type SpaceOptionsStatus = ReportRow[]
type Params = {
	isLoading: boolean,
	onLoaded: ( loaded: boolean ) => void
};
const BudgetRateStatusReport: React.FC<Params> = ( { isLoading, onLoaded } ) => {
	const { auth } = useAuth();
	const intl = useIntlPlus();
	const [ report, setReport ] = useState<SpaceOptionsStatus>( [] );
	const [ loading, setLoading ] = useState( isLoading );
	const reportApi = useReportingApi( ErrorDisplay() );
	const apiRef = useGridApiRef();
	const setRefresh = useCallback( () => {

	}, [] );
	useEffect( () => {

		reportApi.getBudgetRateStatus( 'groupBy=community' )
		         .then( ( result ) => {
			         console.dir( result );
			         setReport( result );
			         onLoaded( false );
			         setLoading( false );
		         } );
	}, [] );

	const columns = useMemo<GridColDef[]>( () => {
		return [
			{
				field: 'name',
				minWidth: 59,
				type: 'string',
				flex: 1,
				headerName: intl.formatMessage( {
					id: 'community',
					defaultMessage: 'Community'
				} ),
				renderCell: ( params: GridRenderCellParams<ReportRow> ) => {
					const { row } = params;
					return ( <Link href={ `/communities/${ row?.id }/units?view=space25_26_units_missing_rates` }>{ row.name }</Link> );
				}
			},
			{
				field: 'count',
				minWidth: 59,
				type: 'number',
				flex: 1,
				headerName: intl.formatMessage( {
					id: 'missing_rate',
					defaultMessage: 'Spaces Missing Rates'
				} ),
			},
		];
	}, [ report ] );

	return (

		<Stack style={ { height: '100%', alignContent: 'center' } }>
			<CardContent style={ { height: '100%' } }>
				<AppBar position="static">
				</AppBar>
				<DataGridPremium
					columns={ columns }

					rows={ report ?? [] }
					slots={ { toolbar: GridToolbar } }

				/>

			</CardContent>
		</Stack>


	);
};


export { BudgetRateStatusReport };

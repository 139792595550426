import Chip from '@mui/material/Chip';
import {
	GridBaseColDef,
	ValueOptions
} from '@mui/x-data-grid/models/colDef/gridColDef.js';
import type { GridValidRowModel } from '@mui/x-data-grid/models/gridRows.js';
import { GridValueOptionsParams } from '@mui/x-data-grid/models/params/gridValueOptionsParams.js';
import React, {
	SyntheticEvent,
	useState
} from 'react';
import {
	GridFilterInputValueProps,
	GridFilterItem,
	GridFilterOperator,
} from '@mui/x-data-grid';
import {
	FormControl,
	Autocomplete,
	TextField,
} from '@mui/material';

interface CustomMultiSelectFilterInputProps extends GridFilterInputValueProps {
	type: 'multiSelect',
	filterOperators: [any],
	valueOptions?: string[],
}

const CustomMultiSelectFilterInput: React.FC<CustomMultiSelectFilterInputProps> = (props) => {
	const { item, applyValue, valueOptions = [] } = props;
	const [selectedValues, setSelectedValues] = useState<string[]>(item.value ?? []);

	const handleChange = (event: SyntheticEvent, value: string | string[], reason: string, details?: string) => {
		const selected = value as string[];
		console.log(selected);
		setSelectedValues(selected);
		applyValue({ ...item, value: selected });
	};

	return (
		<FormControl>
			<Autocomplete
				multiple
				disableCloseOnSelect
				fullWidth
				sx={{fontSize: 'small'}}
				id="tags-standard"
				options={valueOptions}
				value={selectedValues}
				getOptionLabel={(o)=>o}
				// getOptionValue={(o)=>o}
				getOptionKey={(o)=>o}
				renderTags={(tagValue, getTagProps) =>
					tagValue.map((option, index) => (
						<Chip
							variant='outlined'
							size='small'
							label={option}
							{...getTagProps({ index })}
						/>
					))
				}
				renderInput={(params) => (
					<TextField
						sx={{fontSize: 'small'}}
						{...params}
						variant="outlined"
						label="Values"
					/>
				)}
				//@ts-ignore
				onChange={handleChange}
			/>
		</FormControl>
	);
};

const isAnyOfOperator: GridFilterOperator = {
	label: 'is any of',
	value: 'isAnyOf',
	getApplyFilterFn: (filterItem: GridFilterItem) => {
		if (!filterItem.value || (filterItem.value as string[]).length === 0) {
			return null;
		}
		return ({ value }) => (filterItem.value as string[]).includes(value);
	},
	InputComponent: (props: GridFilterInputValueProps) => {
		// Extract valueOptions from column
		const column = props.item.field ? props.apiRef.current.getColumn(props.item.field) : null;
		const valueOptions = column ? (column as any).valueOptions || [] : [];
		//@ts-ignore
		return <CustomMultiSelectFilterInput type={ 'multiSelect' }
			{ ...props }
			valueOptions={ valueOptions } />;
	},
};

interface GridMultiSelectColDef<R extends GridValidRowModel = any, V = any, F = V> extends GridBaseColDef<R, V, F> {
	/**
	 * The type of the column.
	 * @default 'singleSelect'
	 */
	type: 'multiSelect';
	/**
	 * To be used in combination with `type: 'singleSelect'`. This is an array (or a function returning an array) of the possible cell values and labels.
	 */
	valueOptions?: Array<ValueOptions> | ((params: GridValueOptionsParams<R>) => Array<ValueOptions>);
}

const createGridMultiSelectColDef = (field: string, valueOptions: string[]): GridMultiSelectColDef => ( {
	field,
	valueOptions,
	type: 'multiSelect',
	filterOperators: [isAnyOfOperator]
});

export default createGridMultiSelectColDef;

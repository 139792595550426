import { ReportState } from '../Reports/types';

export type DownloadLink = {
	href: string
}

export enum VendorType {
	EQUITY_PARTNER='EQUITY_PARTNER',
	LENDER= 'LENDER',
	GENERAL_CONTRACTOR = 'GENERAL_CONTRACTOR',
	ARCHITECT = 'ARCHITECT',
	OWNER= 'OWNER',
	MANAGER= 'MANAGER',
	INTERIOR_DESIGNER='INTERIOR_DESIGNER',
	CO_DEVELOPER='CO_DEVELOPER',
}

export type Vendor = {
	id: number;
	name: string;
	phone: string;
	alias: string;
	types: VendorType[];
	type: string;
	email: string;
	driveFolderId?: string;

}

export type VendorByType = {
	[key in VendorType]: Vendor[]

}

export type DriveLink = {
	href: string;
	mimeType: string;
	label: string,
	updatedAt: Date|string
}

export type PartnerDetailType = Vendor & {
	driveFolder?: DriveLink
	reportFolder?: DriveLink
}

export type PartnerReportState = ReportState & {
	driveLink?: DriveLink
}

export type PartnerProperty = {
	id: number;
	name: string;
	marketReportLink: DriveLink;
	weeklyUpdate: PartnerReportState;
}

export type PartnerReportsPackageRequest = {
	partner: {
		id: number,
		name: string
	}
	properties: PartnerProperty[],
	date: string
}

import * as React from 'react';
import {
	Card, CardActionArea,
	CardHeader, CardMedia,
	useTheme
} from '@mui/material';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import {
	Link as RouterLink,
} from 'react-router-dom';
import { BoardItemType } from './types.js';

const StyledCard = styled(Card)(({ theme }) => ({
	// @ts-ignore
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	// @ts-ignore
	...theme.typography.body2,
	padding: 0,
	maxHeight: '300px',
	textAlign: 'left',
	verticalAlign: 'top',
	// @ts-ignore
	color: theme.palette.text.secondary
}));


const BoardItem: React.FC<BoardItemType> = ({ id, title, description, imageUrl, filters}) => {
	const href = `./${ id }?${ filters?.toString() ?? '' }`;

	const theme = useTheme();
	return (
		<Grid item xs={ 12 } md={ 2 } lg={ 3 } xl={ 4 }>
			<StyledCard title={title!} key={`BoardItemCard-${title}`}>
				<CardHeader
					key={ title }
					title={ title }
					subheader={ description }
					sx={{'& .MuiCardHeader-content': { minHeight: '48px' }, '& .MuiCardHeader-title': { fontSize: '1.10rem'}}}/>
				<CardActionArea component={RouterLink} to={ href } >
					<CardMedia
						component="img"
						height="220px"
						image={`${imageUrl}?height=300&theme=${theme.palette.mode}`}
						alt={description??''}
					/>
				</CardActionArea>
			</StyledCard>
		</Grid>
	);
};


export default BoardItem;

import React, { useEffect, useState } from 'react';
import { LinearProgress, Link, Stack } from '@mui/material';
import ErrorDisplay from '../ErrorDisplay';
import { DataGridPremium, getGridDefaultColumnTypes, gridClasses } from '@mui/x-data-grid-premium';
import { useAuditApi } from '../../services/audit';
import CoreDataGridToolbarBasic from '../grid/CoreDataGridToolbarBasic';
import createGridMultiSelectColDef from '../grid/GridMultiSelectColDef';
import PageTitle from '../hooks/PageTitle';
import { TypeUrlMap } from './Utils';
import { useSearchParams } from 'react-router-dom';
import useGridHelper from '../hooks/GridHelper';

const AuditRecordGrid = ({ editable = false}) => {
	PageTitle({ id: 'audit', defaultTitle: 'Audit History' });
	const handleError = ErrorDisplay();
	const api = useAuditApi(handleError);
	const { getDate, formatDateTime, formatFieldName } = useGridHelper();
	const defaultColumnTypes = getGridDefaultColumnTypes();

	const columns = React.useMemo(() => [
		{ field: 'typeName',
			headerName: 'Type', minWidth: 100,
			...createGridMultiSelectColDef('typeName', ['Project', 'Property', 'User', 'Note',
				'Vendor',
				'ProjectTask',
				'Milestone',
				'University'
			]),
		},
		{ field: 'typeId', headerName: 'Type Id', hide: true },
		{ field: 'entity', headerName: 'Name', minWidth: 200, type: 'string',
			sortable: true, filterable: true,
			valueGetter: (p=>p.value?.name),
			renderCell: (params) => (
				TypeUrlMap[params.row.typeName] ?
					<Link href={ TypeUrlMap.get(params.row) }>{ params.value }</Link> :
					params.value?.name
			)
		},
		{
			...createGridMultiSelectColDef('action', ['delete', 'add', 'update', 'export', 'login', 'create', 'view']),
			headerName: 'Action', minWidth: 100,
			// valueOptions: ['delete', 'add', 'update', 'export', 'login', 'create', 'view'],
			// filterOperators: defaultColumnTypes['string'].filterOperators.filter((o)=>{
			// 	return [
			// 		'equals',
			// 		'isAnyOf'].includes(o.value);
			// }),
		},
		{ field: 'fieldName', headerName: 'Field', minWidth: 180,
			valueFormatter: formatFieldName
		},
		{ field: 'fieldType', headerName: 'Type', minWidth: 200 },
		{ field: 'from', headerName: 'From', minWidth: 200 },
		{ field: 'to', headerName: 'To', minWidth: 200 },
		{ field: 'createdAt', headerName: 'Date', minWidth: 200, type: 'dateTime',
			valueFormatter: formatDateTime,
			valueGetter: getDate
		},
		{ field: 'createdBy.name', headerName: 'User', minWidth: 200, type: 'string',
			valueGetter: (params)=>params?.row?.createdBy?.name,
			sortable: true, filterable: true
		},
		{ field: 'requestId', headerName: 'RequestId', minWidth: 200 },
	], []);
	const [searchParams] = useSearchParams();
	let initialFilter = {
		items: [],
		linkOperator: 'and',
		quickFilterValues: [],
		quickFilterLogicOperator: 'and'
	};
	if (searchParams.get('requestId')) {
		const requestId = searchParams.get('requestId');
		initialFilter = {
			items: [
				{
					field: 'requestId',
					operator: 'equals',
					id: 19809,
					value: requestId
				}
			],
			linkOperator: 'and',
			quickFilterValues: [],
			quickFilterLogicOperator: 'and'
		};
	} else if (searchParams.get('typeName')) {
		initialFilter = {
			items: [
				{
					field: 'typeName',
					operator: 'equals',
					id: 19809,
					value: searchParams.get('typeName')
				},
				{
					field: 'typeId',
					operator: 'equals',
					id: 19809,
					value: searchParams.get('typeId')
				}
			],
			linkOperator: 'and',
			quickFilterValues: [],
			quickFilterLogicOperator: 'and'
		};
	} else {
		const now = new Date();
		initialFilter = {
			items: [
				{
					field: 'createdAt',
					operator: 'onOrAfter',
					value: `${new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0]}T00:00:00`
				},
				{
					field: 'typeName',
					operator: 'isAnyOf',
					value: columns.find((c)=>c.field === 'typeName')?.valueOptions
				},
			],
			linkOperator: 'and',
			quickFilterValues: [],
			quickFilterLogicOperator: 'and'
		}
	}
	const initialGridState = {
		columns: {
			columnVisibilityModel: {
				createdAt: true,
				createdBy: true,
				fieldName: true,
				fieldType: false,
				action: true,
				from: true,
				to: true,
				requestId: false,
				typeId: false,
			},
		},
		filter:{
			filterModel: initialFilter,
			visibleRowsLookup: {},
			filteredDescendantCountLookup: {},
			filteredRowsLookup: {}
		},
		sorting: {
			sortModel: [{
				field: 'createdAt',
				sort: 'desc'
			}],
		}
	};
	const [gridState, setGridState] = useState(initialGridState);
	const [refresh, setRefresh] = useState(true);
	const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 100});
	const [filter, setFilter] = useState(initialFilter);
	const [sort, setSort] = useState([]);
	//const [columnModel, setColumnModel] = useState(initialGridState.columns.columnVisibilityModel)
	const [rowCount, setRowCount] = useState(0);
	const [rows, setRows] = useState([]);
	useEffect(() => {
		const order = sort.map(s=>{
			return [s.field, s.sort];
		});
		const { page, pageSize } = paginationModel;
		api.findAll({params:{page, pageSize, order, filter }})
			.then((data) => {
				const {count,rows} = data ?? {};
				setRowCount(count);
				setRows(rows ?? []);
			});
	}, [refresh, paginationModel, sort, filter]);

	return (
		<div style={{ flexGrow: 1 }}>
			<Stack margin={2}>
				<DataGridPremium
					sx={{
						[`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
							outline: 'none',
						},
						[`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
							{
								outline: 'none',
							},
					}}
					autoHeight
					rows={ rows }
					columns={ columns }
					initialState={gridState}
					onStateChange={(state)=>setGridState(state)}
					disableRowGrouping
					pagination
					paginationMode='server'
					rowCount={rowCount}
					onPaginationModelChange={(model)=>setPaginationModel(model)}
					onSortModelChange={(model)=>setSort(model)}
					onFilterModelChange={(model)=>setFilter(model)}
					slots={ {
						toolbar: CoreDataGridToolbarBasic,
						loadingOverlay: LinearProgress
					} }
					slotProps={ {
						toolbar: { handleRefresh: ()=>setRefresh(!refresh) }
					} }
				/>
			</Stack>
		</div>
	);
};

export default AuditRecordGrid;

import updateGoogleTagUser from './GoogleTag';
// @ts-ignore
import { useAuth } from 'base-shell/lib/providers/Auth';

export type UserJWT = {
	given_name:string,
	email:string,
	family_name:string,
	picture:string,
	sub:string
}

export type AuthenticationData = {
	user: UserJWT,
	token: string,
	roles: string[],
	department: string,
	building: string
}

export type AuthenticationResponseData = {
	response: AuthenticationData
}

const decodeJwt = function parseJwt (token:string) {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
};
export const useAuthAdapter = ()=> {
	const { auth, setAuth } = useAuth();
	return (userData:AuthenticationData)=> {
		const {
			user,
			token,
			roles,
			department,
			building
		} = userData;

		const {given_name: givenName, email, family_name: familyName, picture, sub: userId} = user;
		const { exp, permissions } = decodeJwt(token);
		setAuth({
			isAuthenticated: true, ...{token, displayName: `${givenName} ${familyName}`, email, roles, permissions},
			photoURL: picture,
			exp,
		});
		if (department || building) {
			updateGoogleTagUser(userId, department, building);
		}
	};
};

import React, { forwardRef } from 'react';
import { Toolbar, IconButton } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { SearchField } from '../../components/SearchField';
import FilterDrawer from 'material-ui-shell/lib/components/FilterDrawer';
import Page from 'material-ui-shell/lib/containers/Page';
import VirtualList from 'material-ui-shell/lib/containers/VirtualList';
import { useFilter } from 'material-ui-shell/lib/providers/Filter';
import List from '@mui/material/List';
import { useSnackbar } from 'notistack';

const ListPage = forwardRef((props, ref) => {
	const {
		fields = [],
		list: source = [],
		getPageProps = () => {},
		listContainerStyle = {},
		listProps,
		Row,
		name,
		trailing = null,
		leading = null,
		disableSearch = false,
		disableFilter = false,
		disablePrint = false,
		top = null,
		bottom = null,
		parseList = (l) => l,
		isLoading,
		searchFieldProps = {}
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const { openFilter, getList, getFilter, setSearch } = useFilter();
	const { queries = [], search = {} } = getFilter(name);
	const { value: searchValue = '' } = search;

	let list = [];
	try {
		list = parseList(getList(name, source, fields));
	} catch (e) {
		enqueueSnackbar(e.message, {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			}});
	}

	return (
		<Page
			isLoading={isLoading}
			contentStyle={{ overflow: 'hidden' }}
			appBarContent={
				<Toolbar disableGutters>
					{leading}
					{!disableSearch && (
						<SearchField
							initialValue={searchValue}
							onChange={(v) => {
								setSearch(name, v);
							}}
							{...searchFieldProps}
						/>
					)}
					{fields.length > 0 && !disableFilter && (
						<IconButton color="inherit" onClick={() => openFilter(name)}>
							<FilterList
								color={queries.length > 0 ? 'secondary' : undefined}
							/>
						</IconButton>
					)}
					{trailing}
				</Toolbar>
			}
			{...getPageProps(list)}
		>
			{top}
			<VirtualList list={list} name={name} listProps={listProps} Row={Row} />
			{!disablePrint && (
				<div style={{ display: 'none' }}>
					<List ref={ref} className='print-container'>
						{list.map(r=><Row data={r} sx={{backgroundColor: '#FFF', color: '#1A2027' }} isPrint={true} />)}
					</List>
				</div>
			)}
			{bottom}
			{fields.length > 0 && <FilterDrawer fields={fields} name={name} />}
		</Page>
	);
});
ListPage.displayName = 'ListPage';
export default ListPage;
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PageTitle from '../../components/hooks/PageTitle';
import ErrorDisplay from '../../components/ErrorDisplay';
import { useUserApi } from '../../services/user-api';
import { User } from '../../components/User/types';
// @ts-ignore
import Page from 'material-ui-shell/lib/containers/Page';
import AdminToolbar from '../../components/Admin/AdminToolbar';

interface UserDetailProps {
  // readonly: boolean
}
const IAMUser: React.FC<UserDetailProps> = () => {
	const [ user, setUser ] = useState<User>();
	const location = useLocation();
	const id = location.pathname.split('/')[4];
	const handleError = ErrorDisplay();
	const api = useUserApi(handleError);
	PageTitle({ id: 'xxxdetail', defaultTitle: user?.name });

	useEffect(()=>{
		api.getSingle(id).then((data)=>setUser(data));
	}, [api, id]);

	return (
		<Page
			pageTitle={ user?.name }
			isLoading={api.isLoading}
			appBarContent={
				<AdminToolbar/>
			}
		></Page>
	);
};

export default IAMUser;

import { useState } from 'react';
import { ApiCore } from './common/api-core';
import useApiClient from './common/api-client';

const url = '/user';
const plural = '/file';
const single = '/file';

// plural and single may be used for message logic if needed in the ApiCore class.
const API_BASE_URL = '/api';
const apiOptions = {
	getAll: true,
	getSingle: true,
	post: true,
	put: false,
	patch: false,
	delete: false,
	url: url,
	plural: plural,
	single: single
};

export const useUserApi = ( errorCallback: any ) => {
	const client = useApiClient( { baseURL: API_BASE_URL } );
	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const api = new ApiCore( apiOptions, client );

	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) return;
		const data = response.data;
		if ( errorCallback ) {
			return errorCallback( data );
		}
		throw data;
	};

	const uploadProfileImage = async (file: any) => {
		setIsLoading( true );
		const formData = new FormData();
		formData.append('file', file);

		const url = `${ apiOptions.url }/me/upload`;
		return api.getProvider().post( url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const getSingle = async (id:number|string) => {
		setIsLoading(true);
		return api.getSingle(id)
			.then(handleResponse)
			.catch(handleError);
	};
	const getAll = async (config:any) => {
		setIsLoading(true);
		return api.getAll(config)
			.then(handleResponse)
			.catch(handleError);
	};

	return {
		isLoading,
		hasError,
		getSingle,
		getAll,
		uploadPicture: uploadProfileImage
	};
};

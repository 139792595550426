import { useAbility } from '@casl/react';
import Grid from '@mui/material/Grid';
import { useAuth } from 'base-shell/lib/providers/Auth';
import Page from 'material-ui-shell/lib/containers/Page';
import { useSnackbar } from 'notistack';
import React, {
	useEffect,
	useState
} from 'react';
import {
	useLocation,
	useNavigate
} from 'react-router-dom';

import { AbilityContext } from '../../components/AbilityContext.js';
import ErrorDisplay from '../../components/ErrorDisplay';
import GlobalSearch from '../../components/GlobalSearch';
import useIntlPlus from '../../components/hooks/IntlPlus';
import PageTitle from '../../components/hooks/PageTitle';
import { PartnerContext } from '../../components/Vendor/PartnerContext';
import PartnerProperties from '../../components/Vendor/PartnerProperties';
import PartnerSummary from '../../components/Vendor/PartnerSummary';
import { PartnerDetailType } from '../../components/Vendor/types.js';
import { useVendorApi } from '../../services/vendor-api';

const initial: PartnerDetailType = {
	alias: '',
	email: '',
	id: 0,
	name: '',
	phone: '',
	type: '',
	types: []
};

const PartnerDetail = () => {
	const intl = useIntlPlus();
	const { auth } = useAuth();
	const ability = useAbility( AbilityContext );
	const [ partner, setPartner ] = useState<PartnerDetailType>( initial );
	const location = useLocation();
	const [ host, route, id, tab = 'home' ] = location.pathname.split( '/' );
	const handleError = ErrorDisplay();
	const { isLoading, getPartnerDetails } = useVendorApi( handleError );
	const [ editor, setEditor ] = useState( false );
	const [readonly, setReadonly] = useState(false);
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const { enqueueSnackbar } = useSnackbar();

	const handleChange = (event: any, newValue:string) => {
		const title = `${partner?.name} (${ intl.formatMessage({id: newValue, defaultMessage: newValue})})`;
		document.title = title;
		const newPath = `/${route}/${id}/${newValue}`;
		navigate(newPath);
		setAnchorEl(null);
	};
	function applyTabProps(index:number|string) {
		return {
			id: `full-width-tab-${index}`,
			'aria-controls': `full-width-tabpanel-${index}`,
		};
	}

	const copyLink = async () =>{
		const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
		await navigator.clipboard.writeText(fullUrl);
		enqueueSnackbar('Copied link to clipboard', {
			variant: 'info',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			}
		});
	};

	useEffect( () => {
		const editor = ability.can( 'update', 'Partner' );
		setEditor( editor );
		if ( auth?.isAuthenticated ) {
			getPartnerDetails( Number( id ) )
				.then( ( data: PartnerDetailType ) => {
					setPartner( data );

				} );
		}
	}, [ id, auth ] );


	PageTitle( { id: 'xxxdetail', defaultTitle: partner?.name ?? id } );
	return (
		<PartnerContext.Provider value={ { partner, setPartner } }>
			<Page
				style={ { height: '100%', width: '100%', alignContent: 'space-around' } }
				pageTitle={ partner?.name  }
				isLoading={ isLoading || !partner?.id }
				appBarContent={
					<>
						<GlobalSearch/>
					</>
				}
				// tabs={
				// 	<AppBar position="static">
				// 		<Box sx={{ display: { xs: 'flex', md: 'flex' }, justifyContent: 'space-between' }}>
				// 			<Tabs
				// 				value={ tab }
				// 				onChange={ handleChange }
				// 				textColor="inherit"
				// 				indicatorColor="secondary"
				// 				variant='scrollable'
				// 				scrollButtons='auto'
				// 				allowScrollButtonsMobile={true}
				// 			>
				// 				<Tab label={intl.formatMessage({id:'home', defaultMessage:'Home'})} icon={<HomeIcon/>} aria-label='Home' value='home' {...applyTabProps('home')} disabled={readonly}/>
				// 				{/*<Tab label={intl.formatMessage({id:'properties', defaultMessage:'Communities'})} value='properties' icon={<Assessment/>} {...applyTabProps('properties')} disabled={readonly} />*/}
				// 				{/*<Tab label="Properties" icon={<Properties/>} aria-label='Properties' value='contacts' {...applyTabProps('properties')} disabled={readonly}/>*/}
				// 				{/*<Tab label="Companies" icon={<Storefront/>} value='vendors' {...applyTabProps('vendors')} disabled={readonly}/>*/}
				// 				{/*{ ability.can('retrieve', 'Project')*/}
				// 				{/*	&& <Tab label="Projects" icon={<Work/>} value="projects" { ...applyTabProps('projects') } disabled={ readonly } /> }*/}
				// 				{/*<Tab label="Notes" value='notes' icon={<Notes/>} {...applyTabProps('notes')} disabled={readonly}/>*/}
				// 			</Tabs>
				// 			<Box sx={{display: 'flex', alignItems: 'center'}}>
				// 				<AuditInfo auditable={ partner }  onClick={(event:any)=>handleChange(event, 'audit')}/>
				// 				<IconButton
				// 					size="small"
				// 					color='inherit'
				// 					onClick={ ()=>copyLink() }
				// 					title= 'Copy Link'
				// 				>
				// 					<LinkIcon />
				// 				</IconButton>
				// 			</Box>
				// 		</Box>
				// 	</AppBar>
				// }

			>
				<Grid container width="100%" rowSpacing={ 1 } columnSpacing={ { xs: 1, sm: 1, md: 2 } } padding={ 1 }>
					<Grid item xs={ 12 } md={ 12 }>
						<PartnerSummary editable={ editor }/>
					</Grid>
					<Grid item xs={ 12 } md={ 12 }>
						<PartnerProperties editable={ editor }/>
					</Grid>
				</Grid>
				{/* @ts-ignore */ }
				{/*<Box*/}
				{/*	style={ { height: '100%', width: '100%', alignContent: 'space-around'} }*/}
				{/*	index={tab}*/}
				{/*>*/}
				{/*	<TabPanel value={tab} index='home'>*/}
				{/*		<PartnerSummary editable={ editor }/>*/}
				{/*	</TabPanel>*/}
				{/*	<TabPanel value={tab} index='properties'>*/}
				{/*		<PartnerProperties editable={ editor }/>*/}
				{/*	</TabPanel>*/}
				{/*	<TabPanel value={tab} index='notes'>*/}
				{/*		<NoteGrid*/}
				{/*			notable={partner}*/}
				{/*			notableType='partner'*/}
				{/*			editable={editor}/>*/}
				{/*	</TabPanel>*/}
				{/*	<TabPanel value={tab} index='audit'>*/}
				{/*		<AuditableHistoryGrid*/}
				{/*			auditable={partner}*/}
				{/*			auditableType='vendor'*/}
				{/*			editable={!readonly}/>*/}
				{/*	</TabPanel>*/}
				{/*</Box>*/}
			</Page>
		</PartnerContext.Provider>
	);
};
export default PartnerDetail;

import React, { useEffect, useState } from 'react';
import { Vendor } from '../../components/Vendor/types.js';
import CompanyGrid from '../../components/Vendor/CompanyGrid';
import { useVendorApi } from '../../services/vendor-api';
import ErrorDisplay from '../../components/ErrorDisplay';
import useWindowSize from '../../components/WindowSize';
import useLocalStorage from 'react-use-localstorage';

// @ts-ignore
import { useAuth } from 'base-shell/lib/providers/Auth';

const Companies = () => {
	const {auth} = useAuth();
	const [data, setData] = useState<Vendor[]>([]);
	const [refresh, setRefresh] = useState(true);
	const handleError = ErrorDisplay();
	const vendorApi = useVendorApi(handleError);
	const windowSize = useWindowSize();
	const [view] = useLocalStorage('view','auto');

	useEffect(()=>{
		if (auth?.isAuthenticated) {
			setRefresh(true);
		}
	}, [auth]);
	useEffect(() => {
		if (refresh) {
			setRefresh(false);
			vendorApi.getVendors()
				.then((data: Vendor[]) =>
				{
					console.dir(data)
					setData(data || []);
				});
		}
	}, [refresh, vendorApi]);
	return (
			<CompanyGrid data={data} setRefresh={setRefresh} api={vendorApi}/>
	);
};
export default Companies;

let working = false;
let attemptCount = 0;
const maxAttempts = 4;
const backoffFactor = 1; // adjust this value as needed

export const reAuth = ()=>{
	if (!working) {
		working = true;
		return tryPrompt(0);
	}
};
export const getAuth = () =>{
	const auth = localStorage.getItem('auth');
	return JSON.parse( auth ?? '{"isAuthenticated": false}');
};


const tryPrompt = (delay:number = 0) => {
	setTimeout(() => {
		if (window.google?.accounts?.id) {
			console.debug('Initiating reAuth');
			window.google.accounts.id.prompt((notification: any) => {
				if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
					window.google.accounts.id.renderButton(
						document.getElementById('GSIButton') as HTMLButtonElement,
						{ type: 'standard', theme: 'outline', size: 'large' } // customization attributes
					);
				}
			});
			working = false;
		} else {
			console.debug('Delaying reAuth');
			if (attemptCount < maxAttempts) {
				attemptCount++;
				const delay = Math.pow(backoffFactor, attemptCount) * 1000; // wait exponentially longer for each retry
				tryPrompt(delay);
			} else {
				working = false;
				console.warn(
					`Google Accounts ID not available after ${maxAttempts} attempts. Giving up.`
				);
			}
		}
	}, delay); // initial delay of 0ms for first attempt
};

import { useState } from 'react';
import { OperatingEntitySchema } from '../components/OperatingEntity/schema';
import { OperatingEntity } from '../components/OperatingEntity/types.js';
import useApiClient from './common/api-client';
import { ApiCore } from './common/api-core';

const url = 'operating-entity';

const API_BASE_URL = '/api';
const apiOptions = {
	getSingle: true,
	getAll: true,
	post: true,
	put: true,
	patch: true,
	delete: true,
	url: url,
};

export const useOperatingEntityApi = ( errorCallback: Function ) => {
	const client = useApiClient( { baseURL: API_BASE_URL } );
	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const api = new ApiCore( apiOptions, client );

	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}
		const data = response.data;
		if ( errorCallback ) {
			return errorCallback( data );
		}
		throw data;
	};

	const getAll = async () => {
		setIsLoading( true );
		return api.getAll()
			.then( handleResponse )
			.catch( handleError );
	};

	const getOne = async ( id: number ) => {
		setIsLoading( true );
		return api.getSingle( id )
			.then( handleResponse )
			.catch( handleError );
	};

	const save = async ( instance: OperatingEntity ) => {
		if ( instance.id ) {
			return api.put( instance )
				.then( handleResponse )
				.then( response => {
					return response;
				} )
				.catch( handleError );
		}
		return api.post( instance )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const remove = async ( id: number ) => {
		return api.delete( id )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	return {
		typeSingle: 'OperatingEntity',
		typePlural: 'OperatingEntities',
		createSchema: OperatingEntitySchema,
		updateSchema: OperatingEntitySchema,
		isLoading,
		hasError,
		getAll,
		getOne,
		save,
		remove
	};
};

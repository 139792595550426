import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getAuth, reAuth } from './api-utils';

/**
 * Axios wrapper with retry and basic configuration for API Access.
 *
 * @param config
 * @return {AxiosInstance}
 */
export default function useApiClient(config: AxiosRequestConfig):AxiosInstance {
	const client = Axios.create(config);
	client.interceptors.request.use((config)=>{
		const auth = getAuth();
		if (auth && auth.isAuthenticated) {
			// @ts-ignore
			config.headers = { ...config.headers,
				//'Content-Type': 'application/json',
				'Authorization': `Bearer ${auth.token}`
			};
		}
		return config;
	}, (error) => Promise.reject(error));
	client.interceptors.response.use((response) => {
		return response;
	}, async function (error) {
		if ([401].includes(error.response.status)) {
			reAuth();
			return Promise.resolve();
		}
		return Promise.reject(error);
	});
	return client;
}

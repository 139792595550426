import { useAbility } from '@casl/react';
import { Link } from '@mui/material';
import React from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Link as RouterLink } from 'react-router-dom';
import { AbilityContext } from '../AbilityContext.js';
import useGridHelper from '../hooks/GridHelper';
import useIntlPlus from '../hooks/IntlPlus';
import PropertiesAutocomplete from '../PropertiesAutocomplete';

function useOEGridColumns() {
	const intl = useIntlPlus();
	const ability = useAbility( AbilityContext );
	const { getDate, formatDateTime, renderRestrictedValue } = useGridHelper();
	const formatUser = ( params: any ) => {
		if ( !params.value ) {
			return params.value;
		}
		return params.value.name;
	};
	return React.useMemo<GridColDef[]>( () => [
		{ field: 'name', headerName: 'Name', editable: true, flex: 1 },
		{
			field: 'fein',
			headerName: intl.formatMessage( { id: 'fein', defaultMessage: 'FEIN' } ),
			description: 'Federal Employer Identification Number',
			minWidth: 100,
			editable: true,
			readOnly: ( params: any ) => ability.cannot( 'update', 'OperatingEntity.fein' ),
			//@ts-ignore
			renderCell: ( p ) => renderRestrictedValue( { ...p, action: 'update', subject: 'Property' } ),
			groupingValueGetter: () => '',
			type: 'string',
			groupable: false,
			aggregable: false,

		},
		{
			field: 'communities',
			headerName: 'Community',
			editable: true,
			flex: 1,
			valueFormatter: ( { value } ) => {
				return value && value.map( ( c: any ) => c?.name ).join();
			},
			renderCell: ( { value } ) => {
				return value && value.map( ( c: any, index: number ) => {
					return <div>
						<Link id={ `property_name_link_${ c.id }` } component={ RouterLink } to={ `/communities/${ c.id }` }>{ c.name }</Link>{ value.length > index+1 ?  ', ' : '' }
					</div>;
				} );
			},
			renderEditCell: ( params ) => (
				<PropertiesAutocomplete
					renderInput={ ()=>{return '';}}
					options={ [] } label={ params.headerName } initialValue={ [] }
					readOnly={ ability.cannot( 'update', 'OperatingEntity' ) } { ...params }
					id={params.field}
				/>
			),
		},
		{ field: 'createdAt', headerName: 'Created', width: 160, type: 'date',
			valueFormatter: formatDateTime,
			valueGetter: getDate
		},
		{ field: 'createdBy', headerName: 'Created By', minWidth: 200, type: 'user',
			valueFormatter: formatUser
		},
		{ field: 'updatedAt', headerName: 'Updated', width: 160, type: 'date',
			valueFormatter: formatDateTime,
			valueGetter: getDate
		},
		{ field: 'updatedBy', headerName: 'Updated By', minWidth: 200, type: 'user',
			valueFormatter: formatUser
		},
	], [formatDateTime, getDate]);
}
export default useOEGridColumns;

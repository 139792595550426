import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import { ContentCopyOutlined } from '@mui/icons-material';

interface CopyButtonProps {
  label?: string
  value?: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ label = 'Copy', value='' }) => {
	const { enqueueSnackbar } = useSnackbar();

	const handleCopyClick = async () => {
		await navigator.clipboard.writeText(value);
		enqueueSnackbar(`Copied ${label} to clipboard`, {
			variant: 'info',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
		});
	};

	return (
		<Tooltip title="Copy">
			<IconButton size="small" onClick={handleCopyClick}>
				<ContentCopyOutlined sx={{ fontSize: '16px' }} />
			</IconButton>
		</Tooltip>
	);
};

export default CopyButton;

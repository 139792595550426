import React, { useEffect, useState } from 'react';
import { useMarketApi } from '../../services/market-api';
import { MarketGrid } from '../../components/Market/MarketGrid';
import ErrorDisplay from '../../components/ErrorDisplay';
import useWindowSize from '../../components/WindowSize';
import useLocalStorage from 'react-use-localstorage';
import { useAuth } from 'base-shell/lib/providers/Auth';

const Markets = () => {
	const {auth} = useAuth();
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(true);
	const handleError = ErrorDisplay();
	const api = useMarketApi(handleError);
	const windowSize = useWindowSize();
	const [view] = useLocalStorage('view','auto');
	// const isGrid = view === 'grid' ? true :
	// 	view === 'list' ? false : windowSize.width > 600;
	useEffect(()=>{
		if (auth?.isAuthenticated) {
			setRefresh(true);
		}
	}, [auth]);
	useEffect(() => {
		if (refresh) {
			setRefresh(false);
			api.getAll()
				.then((data) => {
					setData(data || []);
				});
		}
	}, [refresh, api]);
	return ( <MarketGrid data={data} setRefresh={setRefresh} api={api}/> );
};
export default Markets;

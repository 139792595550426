import {
	handleError,
	handleResponse
} from './response';
import { AxiosInstance } from 'axios';

/**
 * Wrapper over Axios provides basic api constructs.
 *
 */
export default class ApiProvider {
	private client: AxiosInstance;

	/**
	 *
	 * @param client axios client (via api-client)
	 */
	constructor( client: AxiosInstance ) {
		this.client = client;
	}

	/** @param {string} resource
	 *  @param {object} config
	 * */
	async getAll( resource: string, config?: any ) {
		try {
			let response = await this.client
			                         .get( `${ resource }`, config );
			return await handleResponse( response );
		}
		catch ( error ) {return handleError( error );}
	}

	/**
	 * @param resource
	 * @param {string} id
   * @param {object} config
   **/
	async getOne( resource: any, id: any, config?: any ) {
		try {
			let response = await this.client
			                         .get( `${ resource }/${ id }`, config );
			return await handleResponse( response );
		}
		catch ( error ) {
			return handleError( error );
		}
	}

	/**
	 *   @param {string} resource
	 *   @param {object} model
   *   @param {object} config
	 **/
	async post( resource: any, model: any, config?: any ) {
		try {
			let response = await this.client
			                         .post( `${ resource }`, model, config );
			return await handleResponse( response );
		}
		catch ( error ) {return handleError( error );}
	}

	/**
	 * @param {string} resource
	 * @param {object} model
   * @param {object} config
	 **/
	async put( resource: any, model: any, config?: any ) {
		try {
			let response = await this.client
			                         .put( `${ resource }`, model, config );
			return await handleResponse( response );
		}
		catch ( error ) {return handleError( error );}
	}

	/**
	 * @param {string} resource
	 * @param {object} model
   * @param {object} config
	 **/
	async patch( resource: any, model: any, config?: any ) {
		try {
			let response = await this.client
			                         .patch( `${ resource }`, model, config );
			return await handleResponse( response );
		}
		catch ( error ) {return handleError( error );}
	}

	async delete( resource: any, id: any, config?: any ) {
		try {
			let response = await this.client
			                         .delete( `${ resource }/${ id }`, config );
			return await handleResponse( response );
		}
		catch ( error ) {return handleError( error );}
	}
}


import {
	GridCellParams,
	GridFilterItem,
	GridFilterOperator,
	GridColDef
} from '@mui/x-data-grid-premium';

// const PhaseFilterOperator: GridFilterOperator = {
// 	label: 'Phase',
// 	value: 'phase',
// 	getApplyFilterFn: (filterItem: GridFilterItem, column: GridStateColDef) => {
// 		if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
// 			return null;
// 		}
//
// 		return (params: GridCellParams): boolean => {
// 			return Number(params.value?.name) >= Number(filterItem.value?.name);
// 		};
// 	},
// 	InputComponent:,
// 	InputComponentProps: { type: 'string' },
// };


/**
 * check if is an object before attempting to return value since grouping requires a primitive
 * so that groupingValueGetter returns such.  Couple that with filtering on a grouped column uses groupingValueGetter
 * but valueGetter when not, there are issues... so this must handle both.
 * @param value
 */
const getValue = (value : {name:string}|string)=>{
	return  value && typeof value === 'object' ? value?.name : value;
};

export const NamedObjectFilter = (operator: GridFilterOperator) => {
	// @ts-ignore
	const getApplyFilterFn: GridFilterOperator['getApplyFilterFn'] = (filterItem: GridFilterItem, column: GridColDef) => {
		if (!filterItem.field || !filterItem.value || !filterItem.operator) {
			return null;
		}
		const innerFilterFn = operator.getApplyFilterFn(filterItem, column);
		if (!innerFilterFn) {
			return innerFilterFn;
		}

		return (params: GridCellParams): boolean => {
			// @ts-ignore
			const value = Array.isArray(params.value) ? params.value.map(v=>getValue(v)).join(', ') : getValue(params.value);
			return innerFilterFn({...params, value});
		};
	};

	return {
		...operator,
		getApplyFilterFn,
	};
};


export const emptyOrNaNFilterOperator: GridFilterOperator = {
	label: 'is empty',
	headerLabel: 'is empty',
	getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => (params: GridCellParams) => false,
	requiresFilterValue: false,
	value:'emptyOrNaN',
};


export const notEmptyOrNaNFilterOperator: GridFilterOperator = {
	label: 'is not empty',
	headerLabel: 'is not empty',
	getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => (params: GridCellParams) => false,
	requiresFilterValue: false,
	value: 'notEmptyOrNaN',
};

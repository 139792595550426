import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { OpenInNewOutlined } from '@mui/icons-material';

interface OpenInNewButtonProps {
  title?: string
  href: string
  target?: string
}

const OpenInNewButton: React.FC<OpenInNewButtonProps> = ({ target = '_blank', title='Open' , href}) => {

	return (
		<Tooltip title={title}>
			<IconButton size="small" href={href} target={target}>
				<OpenInNewOutlined sx={{ fontSize: '16px' }} />
			</IconButton>
		</Tooltip>
	);
};

export default OpenInNewButton;

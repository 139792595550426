import * as React from 'react';
import Page from 'material-ui-shell/lib/containers/Page';
import { useIntl } from 'react-intl';
import ErrorDisplay from '../../components/ErrorDisplay';
import PageTitle from '../../components/hooks/PageTitle';
import AdminToolbar from '../../components/Admin/AdminToolbar';
import { useIamApi } from '../../services/iam-api';
import IAMRulesGrid from '../../components/Iam/IAMRulesGrid';
import { useSearchParams } from 'react-router-dom';


export default function IAMRules() {
	PageTitle({ id: 'iamRules', defaultTitle: 'Rules' });
	const handleError = ErrorDisplay();
	const api = useIamApi(handleError);
	const intl = useIntl();
	const [searchParams] = useSearchParams();

	return (
		<Page
			pageTitle={ intl.formatMessage({ id: 'iamRules' }) }
			isLoading={api.isLoading}
			appBarContent={ <AdminToolbar/>	}
		>
			<IAMRulesGrid user={searchParams.get('user')}/>
		</Page>
	);
}


export const ANY_ROLE = /.*/;
/**
 * Check if auth contains a role matching pattern
 * @param auth {object: {roles:[string]}}
 * @param role {RegExp|string|string[]} used as match expression against user roles.  If not specified, defaults to any role.
 * @return {boolean}
 */
export const hasRole=(auth, role = ANY_ROLE)=>{
	if (Array.isArray(role)) {
		return role.findIndex(r=>hasRole(auth, r)) > -1;
	}
	if (typeof role === 'string') {
		if (/[a-z]/.test(role)) { //role name
			role = new RegExp('^' + role + '$');
		}
		else {
			role = new RegExp(role);
		}
	}
	return (!!auth?.roles?.find(r => r?.match(role)));
};

import { useAuth } from 'base-shell/lib/providers/Auth';
import GoogleSignin
	from '../components/GoogleSignin';
import React, { useContext, useEffect } from 'react';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { AbilityContext } from '../components/AbilityContext';
import { unpackRules } from '@casl/ability/extra';
const AuthorizedRoute = (_ref)=> {
	const { children, showChildren = false } = _ref;
	const {auth} = useAuth();
	const updateContext = useUnleashContext();
	const ability = useContext(AbilityContext);

	useEffect(() => {
		// context is updated with userId
		const userId = auth?.email;
		updateContext({ userId });
		ability.update(unpackRules(auth?.permissions ?? []));
	}, [auth, updateContext, ability]);
	return <>
		<GoogleSignin
			clientId={window.env.googleClientId}
			autoSelect={true}
			cancelOnTapOutside={false}
		/>
		{ auth?.isAuthenticated || showChildren ? children : null }
	</>;
};

export default AuthorizedRoute;

import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

type TaskFilterProps = {
    data: any
    editable: boolean
    updateContent: (arg: any) => any
	maxRows?: number
}
const JsonContent = ({data, editable, updateContent, maxRows = 50}: TaskFilterProps) => {
    const [input, setInput] = useState(JSON.stringify(data || {}));
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    useEffect(() => {
        setInput(JSON.stringify(data || {}, null, 2))
    }, [data])

    const handleInputChange = (e: any) => {
        const newValue = e.target.value;
        setInput(newValue);

        let parsed;

        try {
            parsed = JSON.parse(newValue);
            setError(false);
            updateContent(parsed)
            setHelperText('Valid JSON');
        } catch (err) {
            setError(true);
            setHelperText('Invalid JSON');
        }
    }

    return (
            <>
                <TextField
                        disabled={!editable}
                        error={error}
                        helperText={helperText}
                        maxRows={maxRows}
                        multiline
                        fullWidth
                        variant="outlined"
                        value={input}
                        onInput={handleInputChange}
                />
                {error && <Alert severity="error">{helperText}</Alert>}
            </>
    );
}

export default JsonContent;

import useIntlPlus, { DateTimeFormat } from './hooks/IntlPlus';
import { Typography } from '@mui/material';
import React from 'react';
import { Audit } from './types';
interface LastUpdatedProps {
  audit: Audit
}
const LastUpdated: React.FC<LastUpdatedProps> = ({audit}) => {
	const intl = useIntlPlus();

	return(
		<Typography variant="caption" sx={{ display: 'flex', justifyContent: 'end', marginLeft: 'auto' }}>
  Last Updated: { audit?.updatedAt ? `${ intl.formatDate(audit?.updatedAt, DateTimeFormat) }, `: null }
			{audit?.updatedBy?.name}
		</Typography>
	);
};

export default LastUpdated;
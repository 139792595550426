import React, { useEffect, useState } from 'react';
import ErrorDisplay from '../../components/ErrorDisplay';
import { useAuth } from 'base-shell/lib/providers/Auth';
import { UserGrid } from '../../components/User/UserGrid';
import { useUserApi } from '../../services/user-api';
import Page from 'material-ui-shell/lib/containers/Page';
import { useIntl } from 'react-intl';
import AdminToolbar from '../../components/Admin/AdminToolbar';

const IAMUsers = () => {
	const intl = useIntl();
	const {auth} = useAuth();
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(true);
	const handleError = ErrorDisplay();
	const api = useUserApi(handleError);

	useEffect(()=>{
		if (auth?.isAuthenticated) {
			setRefresh(true);
		}
	}, [auth]);
	useEffect(() => {
		if (refresh) {
			setRefresh(false);
			api.getAll()
				.then((data) => {
					setData(data || []);
				});
		}
	}, [refresh, api]);
	return (
		<Page
			pageTitle={ intl.formatMessage({ id: 'users', defaultMessage: 'Users' }) }
			isLoading={api.isLoading}
			appBarContent={
				<AdminToolbar/>
			}
		>
			<UserGrid data={data} setRefresh={setRefresh} api={api}/> );
		</Page>);
};
export default IAMUsers;

import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, debounce, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import useAPI from './hooks/API';
import { useAuth } from 'base-shell/lib/providers/Auth';

const APIAutocomplete = (props) => {
	const {auth} = useAuth();
	const {url, empty = [], loadAllOptions = false, disableClearable = false} = props;
	const [options, setOptions] = useState([]);
	const [query, setQuery] = useState('');
	const api = useAPI();
	const defaultGetOptionLabel = (option) => option.name ? `${ option.name }` : options.find(o => o.id === option)?.name;
	const getOptionLabel = props.getOptionLabel ?? defaultGetOptionLabel;
	const find = (query) => {
		if (!query && !loadAllOptions) {
			return;
		}
		api.getAll(url, {params: {query}})
			.then(result => {
				setOptions(Array.isArray(result?.data) ? result?.data : empty);
			})
			.catch(error => {
				setOptions(empty);
				console.error(error);
			});
	};
	const debouncedFind = useMemo(() => debounce(find, 500), []);
	useEffect(() => {
		debouncedFind(query);
	}, [query, auth]);
	return (<Autocomplete
		{ ...props }
		key={ props.id }
		defaultValue={ (props.defaultValue ? props.defaultValue : props.multiple ? [] : undefined) }
		disableClearable={ disableClearable }
		filterOptions={ (x) => x }
		options={ options ?? empty }
		getOptionLabel={ getOptionLabel }
		onInputChange={ (event, value, reason) => {
			if (reason === 'input') {
				setQuery(value);
			}
		} }
		renderInput={ (params) => (
			<TextField { ...props } { ...params } />
		) }
	>
	</Autocomplete>);
};

APIAutocomplete.propTypes = {
	id: PropTypes.any,
	label: PropTypes.string,
	url: PropTypes.string.isRequired,
	empty: PropTypes.array,
	loadAllOptions: PropTypes.bool,
	isOptionEqualToValue: PropTypes.func,
	defaultValue: PropTypes.array,
	value: PropTypes.any,
	renderInput: PropTypes.func,
	renderTags: PropTypes.func,
	filterOptions: PropTypes.func,
	getOptionLabel: PropTypes.func,
	disableClearable: PropTypes.bool,
	multiple: PropTypes.bool,
	freeSolo: PropTypes.bool,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	required: PropTypes.bool
};
export default APIAutocomplete;

import * as React from 'react';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import Page from 'material-ui-shell/lib/containers/Page';
import PageTitle from '../hooks/PageTitle';
import GlobalSearch from '../GlobalSearch';
import useIntlPlus from '../hooks/IntlPlus';
import capitalize from 'lodash/capitalize';
import CoreDataGrid from './CoreDataGrid';

export default function CoreDataGridPage({title, data, setRefresh, api, columns, AddDialog = null, getDetailPanel=null, scope= null, enableMeMode = false, ...rest }) {
	const gridApiRef = useGridApiRef();
	PageTitle({ id: api.typePlural?.toLowerCase() });
	const intl = useIntlPlus();
	return (
		<Page
			pageTitle={title ?? intl.formatMessage({ id: api.typePlural?.toLowerCase() }) + (scope ? ` (${ capitalize(scope) })` : '') }
			appBarContent={
				<GlobalSearch/>
			}
		>
			{columns &&
			<CoreDataGrid
				api = { api }
				gridApiRef={ gridApiRef }
				data = { data }
				setRefresh={ setRefresh }
				columns={ columns }
				AddDialog={ AddDialog }
				getDetailPanel = { getDetailPanel }
				scope={ scope }
				enableMeMode={ enableMeMode }
				{ ...rest }
			/>}
		</Page>
	);
}

import React from 'react';
import { EditDataDialog } from '../EditDataDialog';
import InputField from '../InputField';
import PropertiesAutocomplete from '../PropertiesAutocomplete';
import { OperatingEntitySchema } from './schema';
import { OperatingEntity } from './types';

interface AddNoteDialogProps {
  open:boolean,
  onClose: any,
  onSave: any,
  notableType: string,
  data?: OperatingEntity
}
const OEAddDialog: React.FC<AddNoteDialogProps> = (
	{ open,
		onClose,
		onSave,
		data
	}) => {

	return (
		<EditDataDialog
			title='Add Operating Entity'
			open={ open }
			onClose={ onClose }
			onSave={ onSave }
			schema={OperatingEntitySchema}
			data={data}
			method='PUT'
		>
			<InputField
				id='name'
				label='Name'
				type='string'
				readOnly={data?.name}
			/>
			<InputField
				id='fein'
				label='FEIN'
				type='string'
				required={data?.fein}
			/>
			<PropertiesAutocomplete
				renderInput={ ()=>{return '';}}
				options={ [] }
				label='Communities'
				initialValue={ [] }
				readOnly={ false }
				id='communities'
			/>

		</EditDataDialog>);
};
export default OEAddDialog;

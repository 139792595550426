import {
	createContext,
	useContext
} from 'react';
import {
	PartnerDetailType,
} from './types.js';

export const defaultPartnerContextValue = Symbol();

export type PartnerContextType = {
	partner: PartnerDetailType;
	setPartner: ( partner: PartnerDetailType ) => any;
}
export type PartnerContextTypeOrDefault = PartnerContextType | typeof defaultPartnerContextValue

export const PartnerContext = createContext<PartnerContextTypeOrDefault>( defaultPartnerContextValue );



export const usePartnerContext = (): PartnerContextType => {
	let context = useContext( PartnerContext );
	if ( context === defaultPartnerContextValue ) {
		throw new Error( 'missing context ' );
	}
	return context;
};

export const TypeUrlMap = {
	Property: 'communities',
	Project: 'projects',
	Vendor: 'vendors',
	get: (row)=>{
		return row.typeName === 'Vendor' ?
			`/${TypeUrlMap[row.typeName]}` :
			`/${TypeUrlMap[row.typeName]}/${ row.typeId }`;
	}
};

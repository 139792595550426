import React from 'react';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			style={ { height: '100%', width: '100%', alignContent: 'space-around'} }
			{...other}
		>
			{value === index && children }
		</div>
	);
}
export default TabPanel;

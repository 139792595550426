import Joi from 'joi';
// @ts-ignore
export const WatchlistSchema = Joi.object({
	id: Joi.number().optional().label('Id'),
	name: Joi.string().optional().label('Name'),
	properties: Joi.array().optional().label('Communities'),
}).label('Watchlist');

export const WatchlistPropertySchema = Joi.object({
	id: Joi.number().optional().label('Id'),
	watchlist: Joi.object().label('Watchlist'),
	property: Joi.object().optional().label('Community'),
}).label('Watchlist Community');

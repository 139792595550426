import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import ImageTile from '../ImageTile';
// @ts-ignore
import Tile from '../Tile';
import { AutoStories, Map, OpenInNew, Print } from '@mui/icons-material';
import ProjectMilestonesTile from './ProjectMilestonesTile';
import Box from '@mui/material/Box';
import ProjectTasksTile from './ProjectTasksTile';
import { ProjectRisks } from './ProjectRisks';
import useIntlPlus, { DateTimeFormat } from '../hooks/IntlPlus';
import { useReactToPrint } from 'react-to-print';
import DisplayField from '../DisplayField';

import { Project } from './types';
import LastUpdated from '../LastUpdated';

export interface ProjectGridDetailPanelProps {
  row: Project;
  readonly: boolean | undefined;
  sx: object;
  isPrint: boolean;
}

const ProjectGridDetailPanelGlobal: React.FC<ProjectGridDetailPanelProps> = ({
	row,
	readonly = true,
	sx = { overflow: 'clip' },
	isPrint = false,
}) => {
	const [project] = useState<Project>(row);
	const intl = useIntlPlus();
	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: project.name,
	});

	return (
		<Grid container spacing={1} sx={sx} ref={componentRef}>
			<Typography
				variant="caption"
				sx={{ position: 'absolute', top: 2, right: 4 }}
			>
				<Link
					component={RouterLink}
					to={`/projects/${project?.code}/${project?.subCode}/audit`}
					target={project?.name}
				>
          Last Updated: {intl.formatDate(project?.updatedAt, DateTimeFormat)},{' '}
					{project?.updatedBy?.name}
				</Link>
			</Typography>
			<Typography variant="caption" className="print-hide" sx={{ position: 'absolute', top: 10, right: 10 }}>
				{project?.storyboardLink && (
					<IconButton
						size="medium"
						href={project?.storyboardLink}
						target="storyboard"
						title="View Storyboard"
					>
						<AutoStories style={{ fontSize: '36px' }} />
					</IconButton>
				)}
				{project?.mapLink && (
					<IconButton
						size="medium"
						href={project?.mapLink}
						target="map"
						title="View Map"
					>
						<Map style={{ fontSize: '36px' }} />
					</IconButton>
				)}
				<IconButton size="medium" onClick={handlePrint}>
					<Print style={{ fontSize: '36px' }} />
				</IconButton>
				{project?.code && (
					<IconButton
						size="medium"
						href={`/projects/${project?.code}/${project?.subCode}`}
						target={project?.name}
						title={`View ${project?.name}`}
					>
						<OpenInNew style={{ fontSize: '36px' }} />
					</IconButton>
				)}
			</Typography>
			<Grid item container spacing={1} sm={12} paddingTop={1}>
				<Grid item xs={12} sm={8}>
					<Typography variant="h5" sx={{ fontWeight: 'bold' }} title='Project Name'>
						{project?.name}
					</Typography>
					<Typography variant="h6"
						sx={{ fontSize: '1rem', whiteSpace: 'nowrap', textOverflow:'ellipsis', overflow: 'hidden' }}
						title={project?.headline}>
						{project?.headline}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4} container
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						flexDirection: 'row',
						wrap: 'nowrap',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'end', verticalAlign:'bottom' }}>
						<Typography
							variant="h6"
							sx={{
								fontWeight: 'bold',
								fontSize: '1.1rem',
								marginLeft: '2px',
								marginRight: '8px',
							}}
						>
            Phase:
						</Typography>
						<Typography
							variant="h6"
							sx={{ alignItems: 'center', fontSize: '.95rem' }}
						>
							{project?.phase?.name}
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={8} md={8} sx={{ height: 'calc(100% - 48px)' }}>
				<Grid container spacing={.5}>
					<Grid item xs={12} sm={7}>
						<ImageTile
							title="Project Image"
							title2={project?.status}
							readonly={readonly}
							data={project}
							sx={{ height: 250, width: '100%' }}
						/>
						<Grid container spacing={0} item xs={12} paddingTop={1} paddingBottom={1}>
							<Grid item xs={6} sm={6}>
								<Tile
									title='Stats'
									readonly={readonly}
									fields={[
										{
											id: 'deliveryYear',
											label: 'DeliveryYear',
											type: 'number',
										},
										// { id: 'buildingCount', label: 'Buildings', type: 'number', },
										{ id: 'unitCount', label: 'Units', type: 'number' },
										{ id: 'bedCount', label: 'Beds', type: 'number' },
										project?.groupCode === 'M' ? {
											id: 'university',
											label: 'University',
											value: project?.property?.university?.alias,
										}:null,
										{
											id: 'market',
											label: 'Market',
											href: project?.property?.market?.infoLink,
											value: project?.property?.market?.name,
										},
										{
											id: 'address',
											label: 'Location',
											type: 'address',
											value: {
												streetAddress: project?.property?.streetAddress,
												city: project?.property?.city,
												state: project?.property?.state,
												postalCode: project?.property?.postalCode,
											},
											href: project?.mapLink
										},
									]}
									data={project}
									sx={{ height: 204, width: '100%' }}
								/>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Tile
									title='Team'
									readonly={readonly}
									fields={[
										{
											id: 'architects',
											label: 'Architect',
											type: 'string',
											value: project?.architects
												?.map((v) => v?.alias)
												?.join(', '),
										},
										{
											id: 'generalContractors',
											description: 'General Contractors',
											label: 'GC',
											value: project?.generalContractors
												?.map((v) => v?.alias)
												?.join(', '),
										},
										{
											id: 'interiorDesigners',
											label: 'Designer',
											description: 'Interior Designers',
											value: project?.interiorDesigners
												?.map((v) => v?.alias)
												?.join(', '),
										},
										{
											id: 'equityPartners',
											label: 'Equity Partner',
											value: project?.property?.equityPartners
												?.map((v) => v?.alias)
												?.join(', '),
										},
										{
											id: 'lenders',
											label: 'Lender',
											value: project?.lenders?.map((v) => v?.alias)?.join(', '),
										},
										{
											id: 'developers',
											label: 'Co-Developers',
											value: project?.developers?.map((v) => v?.alias)?.join(', '),
										},
									]}
									data={project}
									sx={{ height: 204, width: '100%' }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={5} spacing={2} padding={0} paddingBottom={1}>
						<Grid item>
							<Tile
								title="Budget"
								data={project}
								readonly={readonly}
								sx={{ height: 250, width: '100%' }}
							>
								<DisplayField
									key="approvedProjectBudget"
									id="approvedProjectBudget"
									label="IC Approved Project Budget"
									value={project?.approvedProjectBudget}
									type="currency"
									title="IC Approved Project Budget"
									sx={{flex: 2}}
								/>
								<DisplayField
									key="projectBudget"
									id="projectBudget"
									label="Current Project Budget"
									value={project?.projectBudget}
									type="currency"
									title="Current Project Budget"
									sx={{flex: 2}}
								/>
								<DisplayField
									key="pursuitBudget"
									id="pursuitBudget"
									label="Pursuit Budget"
									value={project?.pursuitBudget}
									type="currency"
									title="Pursuit Budget"
									sx={{flex: 2}}
								/>
								<DisplayField
									key="projectSpent"
									id="projectSpent"
									label="Spend to Date"
									value={project?.projectSpent}
									type="currency"
									title="Spend to Date"
									sx={{flex: 2}}
								/>
								<DisplayField
									key="proformaHardCost"
									id="proformaHardCost"
									label="PF Hard Cost"
									value={project?.proformaHardCost}
									type="currency"
									title="Proforma Hard Cost Budget"
									sx={{flex: 2}}
								/>
								<DisplayField
									key="gcHardCost"
									id="gcHardCost"
									label="GC Hard Cost Estimate"
									value={project?.gcHardCost}
									type="currency"
									title="GC Hard Cost Estimate"
									sx={{flex: 2}}
								/>
								<DisplayField
									key="hardCostDelta"
									id="hardCostDelta"
									label="Hard Cost Delta"
									value={project?.hardCostDelta}
									type="percentage"
									title="Hard Cost Delta"
									color={project?.hardCostDelta > 0 ? 'red' : 'green'}
									sx={{flex: 2}}
								/>
								<DisplayField
									key="targetHardCost"
									id="targetHardCost"
									label="Target Hard Cost Budget"
									value={project?.targetHardCost}
									type="currency"
									title="Target Hard Cost Budget"
									sx={{flex: 2}}
								/>
							</Tile>
						</Grid>
						<Grid item paddingTop={1}>
							<Tile
								title="Financial Metrics"
								readonly={readonly}
								data={project}
								sx={{ height: 204, width: '100%' }}
								footer={<LastUpdated audit={project?.financialDataAudit} />}
							>
								<DisplayField
									key="yield"
									id="yield"
									label="Yield: (Untrended / Trended)"
									value={`${ intl.formatPercentage(project?.untrendedYield)} / ${ intl.formatPercentage(project?.trendedYield)}`}
									type="string"
									title="Yield: (Untrended / Trended)"
									sx={{flex: 1, justifyContent:'justify'}}
									color={project?.trendedYield > 0 ? 'green' : 'red'}
								/>
								<DisplayField
									key="irr"
									id="irr"
									label="IRR: (Unlevered / Levered)"
									value={`${ intl.formatPercentage(project?.unleveredIrr)} / ${ intl.formatPercentage(project?.leveredIrr)}`}
									type="string"
									title="IRR: (Unlevered / Levered)"
									sx={{flex: 1}}
									color={project?.leveredIrr > 0 ? 'green' : 'red'}
								/>
								<DisplayField
									key="budgetNote"
									id="budgetNote"
									label="Note"
									value={project?.financialDataNote}
									type="string"
									title="Budget Note"
									sx={{ justifyContent:'justify', whiteSpace: 'wrap', overflow: 'auto'}}
								/>
							</Tile>
						</Grid>
					</Grid>
				</Grid>
				<Box sx={{ height: 170 }}>
					<ProjectRisks
						riskLevel={project?.riskLevel}
						risks={project?.risks}
						variant="h6"
						sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}
					/>
					<ProjectTasksTile
						project={project}
						key="projectTasks"
						title="Critical Items"
						readonly={readonly}
						limit={5}
					/>
				</Box>
			</Grid>
			<Grid item xs={12} sm={4} md={4} sx={{paddingTop: '2px'}} >
				<ProjectMilestonesTile
					project={project}
					sx={{ height: 694, width: '100%', fontSize: '8pt' }}
					autoHeight={isPrint}
				/>
			</Grid>
		</Grid>
	);
};
export default ProjectGridDetailPanelGlobal;

import {
	Button,
	Card,
	CardContent,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormGroup,
	FormLabel,
	TextField
} from '@mui/material';

import { GridRowModelUpdate } from '@mui/x-data-grid-premium';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import React, {
	ChangeEvent,
	useEffect,
	useState
} from 'react';
import JsonContent from '../JsonContent';
import ScheduleInput from '../scheduling/ScheduleInput';
import {
	JobEditSaveDiaglogProps,
	TaskJobSchedule
} from './SyncJobTypes';

interface JobCardModel {
	job: TaskJobSchedule;
	schedule: string;

}

const JobEditDialog = ( { job, open, handleClose, apiRef, onSave }: JobEditSaveDiaglogProps ) => {

	const [ model, setModel ] =
		useState<JobCardModel>(
			{
				job,
				schedule: job.cronString
			} );

	useEffect( () => {
		if ( job ) {
			const newModel = {
				job,
				schedule: job.cronString
			};
			setModel( newModel );
		}
	}, [ job ] );

	const handleJobParameters = ( jobParameters: any ) => {
		console.log( 'handleJobParameters' );
		console.dir( jobParameters );
		setModel( {
			...model,
			job: {
				...model.job,
				jobParameters
			}
		} );
	};

	const handleJobConfiguration = ( jobConfiguration: any ) => {
		console.log( 'handleJobConfiguration' );
		console.dir( jobConfiguration );
		setModel( {
			...model,
			job: {
				...model.job,
				jobConfiguration
			}
		} );
	};


	const handleUpdateOrganization = ( event: ChangeEvent<HTMLInputElement> ) => {
		console.log( 'handleUpdateOrganization' );
		console.dir( event );
		const organizationKey = event.target.value;
		setModel( {
			...model,
			job: {
				...model.job,
				jobConfiguration: {
					...model.job.jobConfiguration,
					options: {
						...model.job.jobConfiguration.options,
						organizationKey
					}
				}
			}
		} );
	};

	const handleUpdateSubtasks = ( subTasks: string[] ) => {
		console.log( 'handleUpdateSubtasks' );
		console.dir( subTasks );
		setModel( {
			...model,
			job: {
				...model.job,
				jobConfiguration: {
					...model.job.jobConfiguration,
					subTasks
				}
			}
		} );
	};

	const handleUpdateSchedule = ( schedule: string ) => {
		console.log( `handleUpdateSchedule ${schedule}` );
		const job = {
			...model.job,
			cronString: schedule
		}
		setModel( {
			job,
			schedule
		} );
	};

	const toggleScheduled = () => {
		setModel( {
			...model,
			job: {
				...model.job,
				scheduled: !model.job.scheduled,
			},
		} );
	};

	const doHandleEditSave = () => {
		console.dir( model );

		if ( !model ) {
			return;
		}
		const newJob: TaskJobSchedule = {
			...model.job,
			cronString: model.schedule
		};

		const rowUpdates: GridRowModelUpdate[] = [
			newJob
		];
		console.dir( rowUpdates );
		onSave( newJob )
			.then( () => {console.log( 'success' );} );
		apiRef.current.updateRows( rowUpdates );

		handleClose();
	};


	return (
		<>
			<Dialog open={ open } onClose={ handleClose } fullWidth>
				<DialogTitle>Task Settings - { model.job?.taskDefinition.name }</DialogTitle>
				<DialogContent>
					<Card>
						<LocalizationProvider dateAdapter={ AdapterDayjs }>
							<CardContent>
								<FormGroup>
									<FormLabel>
										API Type:
									</FormLabel>
									<FormControl>
										<TextField
											disabled={ true }
											value={ model.job?.taskDefinition.taskType }>
										</TextField>
									</FormControl>

									<FormLabel>Parameters</FormLabel>
									<FormControl>
										<JsonContent
											editable={ true }
											data={ model.job.jobParameters }
											updateContent={ handleJobParameters }
										/>
									</FormControl>
									<FormLabel>Task Creation Options</FormLabel>
									<FormControl>
										<JsonContent
											editable={ true }
											data={ model.job.jobConfiguration }
											updateContent={ handleJobConfiguration }
										/>
									</FormControl>
								</FormGroup>

								{
									( model.job?.jobConfiguration &&
									  <>
										  {
											  ( model.job?.jobConfiguration.options?.organizationKey &&
											    <FormGroup>
												    <FormLabel>Organization</FormLabel>
												    <FormControl>
													    <TextField
														    value={ model.job?.jobConfiguration.options.organizationKey }
														    onChange={ handleUpdateOrganization }
													    />
												    </FormControl>
											    </FormGroup> )
										  }
										  { ( model.job?.jobConfiguration.subTasks &&
										      <FormGroup>
											      <FormLabel>Triggers Tasks</FormLabel>
											      <FormControl>
												      <JsonContent
													      editable={ true }
													      data={ model.job?.jobConfiguration.subTasks }
													      updateContent={ handleUpdateSubtasks }
												      /> </FormControl></FormGroup> )
										  }    </> )
								}


								<FormGroup>
									<FormLabel>Scheduled </FormLabel>
									<FormControl>
										<Checkbox
											checked={ model.job.scheduled }
											onChange={ toggleScheduled }
										/>
									</FormControl>
									{
										model.job.scheduled &&
										<FormControl>
											<ScheduleInput
												cronString={ model.job.cronString }
												editable={ true }
												callback={ handleUpdateSchedule }/>
										</FormControl>
									}

								</FormGroup>
							</CardContent>

						</LocalizationProvider>
					</Card>
				</DialogContent>
				<DialogActions>
					<Button onClick={ handleClose }>Cancel</Button>
					<Button onClick={ doHandleEditSave }>Save</Button>

				</DialogActions>
			</Dialog>
		</>
	)
		;
};

export default JobEditDialog;


import { useMemo, useState, Fragment } from 'react';
import { debounce, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import * as React from 'react';
import useAPI from '../hooks/API';
const ExternalPropertyAutocomplete = (
	{ propertyId, organization, readOnly, ...props}) => {
	const { url='/external/properties', empty=[], onChange: handleChange } = props;
	const api = useAPI();
	const [options, setOptions] = useState([]);
	const find = (query) => {
		if (!query) {
			setOptions(empty);
			return;
		}
		api.getAll(`${url}`, {
			params:{
				query,
				fields:'id,marketingName,organization',
				propertyId,
				organization
			}
		})
			.then(result => {
				setOptions(result?.data || empty);
			})
			.catch(error => {
				setOptions(empty);
				console.error(error);
			});
	};
	const debouncedFind = useMemo(() => debounce(find, 200), []);
	const [property, setProperty] = useState({});
	return (
		<Autocomplete
			{ ...props }
			disableClearable={readOnly}
			key={ `property-autocomplete-${ props.id }`}
			filterOptions={(x) => x}
			options={options}
			getOptionLabel={(option) => {
				// e.g value selected with enter, right from the input
				return option ? `${option.id}${ option.marketingName ? ' - ' + option.marketingName : ''}` : '';
			}}
			onInputChange={(event, value, reason) => {
				if (reason === 'input') {
					debouncedFind(value);
				}
			}}
			onChange={(event, newValue) => {
				if (newValue && newValue.inputValue) {
					const property = {propertyId};
					const {inputValue} = newValue;
					if (isNaN(Number(inputValue))) {
						property.marketingName = inputValue;
					} else {
						property.id = Number(inputValue);
					}
					setTimeout(() => {
						setProperty(property);
					});
				} else {
					handleChange(event, newValue, 'input');
				}
			}}
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			renderOption={(props, option) => <li {...props}>{option.id} - {option.marketingName} ({option.organization})</li>}
			renderInput={(params) =>
			{
				const inputProps = {...params?.inputProps, ...props.inputProps};
				return <TextField { ...props } { ...params } inputProps={inputProps} />;
			}}
		/>);
};

ExternalPropertyAutocomplete.propTypes = {
	id: PropTypes.any,
	url: PropTypes.string,
	empty: PropTypes.array,
	readOnly: PropTypes.bool,
	propertyId: PropTypes.number,
	organization: PropTypes.string,
	onChange: PropTypes.func
};


export default ExternalPropertyAutocomplete;

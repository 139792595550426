import {
	Button,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import Box from '@mui/material/Box';
// @ts-ignore
import Page from 'material-ui-shell/lib/containers/Page';
import React, {
	useEffect,
	useState
} from 'react';
import ErrorDisplay from '../../components/ErrorDisplay';
import PageTitle from '../../components/hooks/PageTitle';
import JsonContent from '../../components/JsonContent';
import { useReportingApi } from '../../services/reporting-ui-api';

type ReportConfigProps = {
	// readonly: boolean
}

type ReportConfigModel = {
	id: number;
	name: string;
	templateFileId: string;
	parentFolderId: string;
	//metadata: any;
}
const ReportConfiguration: React.FC<ReportConfigProps> = () => {
	const [ reportConfig, setReportConfig ] = useState<ReportConfigModel>();

	const handleError = ErrorDisplay();
	const api = useReportingApi( handleError );
	PageTitle( { id: 'report.admin', defaultTitle: 'Report Configuration' } );

	useEffect( () => {
		api.getReportConfiguration( 'Market Reports' )
		   .then( ( data: ReportConfigModel ) => setReportConfig( data ) );
	}, [] );

	const saveConfig = () => {
		console.log( 'saveConfig' );
		api.updateReportConfiguration( reportConfig! )
		   .then( ( data: ReportConfigModel ) => {
			   setReportConfig( data );
		   } );
	};
	// const handleUpdateMetadata = ( metadata: any ) => {
	// 	if ( reportConfig ) {
	// 		setReportConfig( {
	// 			...reportConfig,
	// 			metadata
	// 		} );
	// 	}
	// };

	return (
		<Box
		>
			{ reportConfig &&
			  <form onSubmit={ saveConfig }>
				  <Typography variant="h5" component="h2">
					  { reportConfig?.name }
				  </Typography>

				  <Stack sx={ { flexGrow: 1 } } margin={ 2 }>
					  <TextField
						  sx={ { padding: 2 } }
						  id="parentFolder"
						  label="Parent Folder"
						  type="string"
						  required
						  value={ reportConfig?.parentFolderId }
						  onChange={ ( event ) => {
							  setReportConfig( {
								  ...reportConfig!,
								  parentFolderId: event.target.value
							  } );
						  } }
					  />

					  <TextField sx={ { padding: 2 } }
					             id="templateId"
					             label="Template File"
					             type="string"
					             required
					             value={ reportConfig?.templateFileId }
					             onChange={ ( event ) => {
						             setReportConfig( {
							             ...reportConfig,
							             templateFileId: event.target.value
						             } );
					             } }
					  />
				  </Stack>
				  <Button type="submit" variant="contained">
					  Save
				  </Button>

				  {/*<Typography height={ '20px' }>Metadata</Typography>*/}
				  {/*<JsonContent*/}
					{/*  editable={ true }*/}
					{/*  data={ reportConfig.metadata }*/}
					{/*  updateContent={ handleUpdateMetadata }*/}
					{/*  maxRows={ 500 }*/}
				  {/*/>*/}

			  </form>
			}
		</Box>
	);
};

export default ReportConfiguration;

 import React from 'react';
import { ListPage } from 'material-ui-shell/lib/containers/Page';
import {
	Avatar,
	Divider,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Typography
} from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import EditIcon from '@mui/icons-material/Edit';
import { useIntl } from 'react-intl';
import PageTitle from '../hooks/PageTitle';
import PropTypes from 'prop-types';

const fields = [
	{
		name: 'code',
		label: 'Code'
	},
	{
		name: 'stateCode',
		label: 'State'
	},
	{
		name: 'name',
		label: 'Name'
	},
	{
		name: 'marketingName',
		label: 'Entrata Name'
	},
	{
		name: 'createdAt',
		label: 'Created',
		type: 'date'
	}
];

const Row = ({ index, style, data }) => {
	const { id, code, alias, name, createdAt } = data || {};
	PageTitle({ id: 'list', defaultTitle: 'List' });

	return (
		<div key={ `${ code }_${ index }` } style={ style }>
			<ListItem alignItems="flex-start">
				<ListItemAvatar>
					<Avatar>
						<ApartmentIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemSecondaryAction>
					<IconButton
						href={ `/communities/${ id }` } edge="end" aria-label="Edit">
						<EditIcon />
					</IconButton>
				</ListItemSecondaryAction>
				<ListItemText
					primary={ <Typography>{ code }</Typography> }
					secondary={
						<Typography
							component="span"
							variant="body2"
							color="textSecondary"
						>
							<div>{ name ? name : '' }</div>
							<div>{ `${ data.streetAddress }, ${ data.city }, ${ data.state.name }` }</div>
						</Typography>
					}
				/>
			</ListItem>
			<Divider />
		</div>
	);
};
Row.propTypes = {
	index: PropTypes.number,
	style: PropTypes.object,
	data: PropTypes.array
};

const PropertyList = ({data, api}) =>
{
	const intl = useIntl();
	return (
		<ListPage
			name="property_list"
			list={ data }
			isLoading={api.isLoading}
			fields={ fields }
			Row={ Row }
			listProps={ { itemSize: 120 } }
			getPageProps={ (data) => {
				return {
					pageTitle: intl.formatMessage(
						{
							id: 'properties_list',
							defaultMessage: 'Property {count} rows'
						},
						{ count: data.length }
					)
				};
			} }
		/>
	);
};
PropertyList.propTypes = {
	data: PropTypes.array
};
export default PropertyList;

import { TextField } from '@mui/material';
import * as React from 'react';
import { EditDataDialog } from '../EditDataDialog';
import { useIntl } from 'react-intl';

interface University {
  id: number,
  name: string,
  alias: string,
  createdAt: Date,
  updatedAt: Date
}

interface UniversityEditProps {
  open: boolean,
  onClose: any,
  onSave: any,
  university: University|any
}

export const UniversityEdit: React.FC<UniversityEditProps> = ({ open, onClose, onSave, university }) =>
{
	const intl = useIntl();

	return (
		<EditDataDialog
			open={ open }
			data={ university }
			onClose={ onClose }
			onSave={ onSave }
			//schema={ project.id ? UpdateProjectSchema : CreateProjectSchema }
			typeLabel={ intl.formatMessage({id:'university',defaultMessage:'University'})}
			disableAuditNote={true}
		>
			<TextField
				id="name"
				label="Name"
				//description={'University Full Name'}
				type="string"
				required
			/>
			<TextField
				id="alias"
				label="Short Name"
				//description="University Short Name"
				type="string"
				required
			/>
			{/*<APIAutocomplete*/}
			{/*	id="market"*/}
			{/*	url={ '/university' }*/}
			{/*	getOptionLabel={ (option) => option.name }*/}
			{/*	filterOptions={(o)=>o}*/}
			{/*	filterSelectedOptions*/}
			{/*/>*/}
		</EditDataDialog>);
};

export default UniversityEdit;

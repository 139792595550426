import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';

const COLORS:Map<string,{backgroundColor: string, color: string}> = new Map();
COLORS.set('low', { backgroundColor: 'inherit', color: 'inherit' });
COLORS.set('medium', { backgroundColor: 'orange', color: 'white' });
COLORS.set('high', { backgroundColor: 'red', color: 'white' });

interface ProjectRiskProps extends TypographyProps {
	riskLevel: string
	risks: []
}
export const ProjectRisks: React.FC<ProjectRiskProps> =  (props) => {
	const { riskLevel, risks, ...rest } = props;
	const colors = COLORS.get(riskLevel ?? 'low');
	return(
		<Box sx={{ display: 'flex', alignItems: 'center' }} {...colors }>
			<Typography {...rest} variant='h6' sx={{ fontWeight: 'bold', fontSize: '1.1rem', marginLeft: '2px', marginRight: '8px' }}>Risks:</Typography>
			<Typography {...rest} variant='h6' sx={{ alignItems: 'center', fontSize: '.95rem' }}>{risks?.join(', ') ?? ''}</Typography>
		</Box>
	);
};

import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Link, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { Email, OpenInNew, VideoCall } from '@mui/icons-material';

interface Contact {
  id: number;
  name: string;
  email: string;
  externalId?: string;
  picture: string;
}

interface ContactCardProps {
  contact?: Contact;
  contactId?: number
}

const StyledCard = styled(Card)(({ theme }) => ({
	// @ts-ignore
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	// @ts-ignore
	...theme.typography.body2,
	// @ts-ignore
	color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.secondary,
}));

const ContactCard: React.FC<ContactCardProps> = ({ contact }) => {
	return (
		<StyledCard elevation={3} sx={{ width: 284, height:'100%', padding: 0, margin: -1 }}>
			<CardContent>
				<Stack direction="row" spacing={1}>
					<Avatar
						alt={contact?.name}
						src={contact?.picture}
						sx={{ width: 64, height: 64 }}
					/>
					<Stack>
						<Typography variant="subtitle1" component="div">
							{contact?.name}
						</Typography>
						<Typography variant="caption" component="div">
							{contact?.email}
						</Typography>
						<Box marginTop="auto" width="100%" alignContent='center'>
							{contact?.externalId && <Link
								href={`https://contacts.google.com/person/${contact?.externalId}`}
								target="_blank"
								rel="noopener noreferrer"
								underline="none"
								title="View Contact"
							>
								<OpenInNew />
							</Link>}
							<Link
								href={`mailto:${contact?.email}`}
								target="_blank"
								rel="noopener noreferrer"
								underline="none"
								title={`Email ${contact?.name}`}
							>
								<Email />
							</Link>
							<Link
								href={`https://meet.google.com/new?calleeId=${contact?.email}&authuser=0&hl=en`}
								target="_blank"
								rel="noopener noreferrer"
								underline="none"
								title='Start Meet'
							>
								<VideoCall />
							</Link>
						</Box>
					</Stack>
				</Stack>
			</CardContent>
		</StyledCard>
	);
};

export default ContactCard;

import { useState } from 'react';
import { QueryParams } from '../components/grid/types';
import { DashboardDownloadRequestConfig } from '../components/Property/PropertyReports.js';
import { Property } from '../components/Property/types.js';
import { UnitTypeUnitSpace } from '../components/Property/Units/Units.types';
import useApiClient from './common/api-client';
import { ApiCore } from './common/api-core';

const url = 'reporting';
const plural = '/reporting';
const single = '/reporting';

// plural and single may be used for message logic if needed in the ApiCore class.
const API_BASE_URL = '/api';
const apiOptions = {
	getAll: false,
	getSingle: true,
	post: true,
	put: true,
	patch: false,
	delete: true,
	url: url,
	plural: plural,
	single: single,
};

export type ReportConfig = {
	name: string;
	templateFileId: string;
	parentFolderId: string;
}

export interface ReportTemplate {
	id: number
	name: string
	version?: number
	type: 'Property' | 'Project'
	config: {
		[key: string]: any
	}
}

export const useReportingApi = (errorCallback: any) => {
	const client = useApiClient({baseURL: API_BASE_URL});
	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const api = new ApiCore(apiOptions, client);

	const handleResponse = (response:any) => {
		setIsLoading(false);
		return response.data;
	};

	const handleError = (response:any) => {
		setIsLoading(false);
		const isError = response.status >= 400;
		setHasError(isError);
		if (!isError) {
			return;
		}
		const data = response.data;
		if (errorCallback) {
			return errorCallback(data);
		}
		throw data;
	};

	const getLook = async (id:string, config?:any) => {
		setIsLoading(true);
		const url = `${apiOptions.url}/look`;
		return api.getProvider().getOne(url, id, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const renderDashboards = async (dashboardConfig: DashboardDownloadRequestConfig) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/dashboards`;
		return api.getProvider().getAll(url, { params: {dashboardConfig}})
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const getPropertyBoard = async (property: Property) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/property-board`;
		return api.getProvider().getOne(url, property.id)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const getBoard = async (scope:string) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/board`;
		return api.getProvider().getOne(url, scope)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const getReportConfiguration = async (name: string) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/config`;
		return api.getProvider().getOne(url, name)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};


	const updateReportConfiguration = async (reportConfig: ReportConfig) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/config/`;
		console.dir(url);
		return api.getProvider().post(url, reportConfig)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const getReportTemplate = async (type: string, name: string, version?: number, config: any = {}) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/template/${type}/${name}`;
		return api.getProvider().getOne(url, version, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const getReport = async (id:number, config: any = {}) => {
		setIsLoading(true);
		return api.getSingle(id, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const saveReport = async (report: any, config?: any) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/`;
		return api.getProvider().post(url, report, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const submitReport = async (report: any, config: any = {}) => {
		return saveReport(report, { ...config, params: {...config?.params, action: 'submit'} });
	};

	const retractReport = async (report: any, config: any = {}) => {
		return saveReport(report, { ...config, params: {...config?.params, action: 'retract'} });
	};

	const publishReport = async (report: any, config: any = {}) => {
		return saveReport(report, { ...config, params: {...config?.params, action: 'publish'} });
	};

	const unPublishReport = async (report: any, config: any = {}) => {
		setIsLoading(true);
		report.published = '';
		return saveReport(report, { ...config, params: {...config?.params, action: 'unpublish'} });
	};


	const deleteReport = async (report: any, config?: any) => {
		setIsLoading(true);
		return api.delete(report.id, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const getReports = async (config?: any) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/`;
		return api.getProvider().getAll(url, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const getBudgetRateStatus = async (params?:string)=>{
		const url = `${ apiOptions.url }/budget-rate-status?${params}`;
		console.log(url)
		return api.getProvider().getAll(url)
		          .then(handleResponse)
		          .then(response => {
			          return response;
		          })
		          .catch(handleError);
	}


	return {
		isLoading,
		hasError,
		getLook,
		renderDashboards,
		getReportConfiguration,
		updateReportConfiguration,
		getPropertyBoard,
		getBoard,
		getReportTemplate,
		getReports,
		getReport,
		saveReport,
		submitReport,
		retractReport,
		publishReport,
		unPublishReport,
		deleteReport,
		getBudgetRateStatus,
	};
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Avatar, debounce, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useAPI from './hooks/API';
import { useAuth } from 'base-shell/lib/providers/Auth';
import Grid from '@mui/material/Grid';

const GoogleDirectorySearch = (props)=> {
	const { auth } = useAuth();
	const onChange = props.onChange;
	const [options, setOptions] = useState([]);
	const [value, setValue] = useState(props.value);
	const [query, setQuery] = useState();
	const api = useAPI();
	const find = (query) => {
		if (!query) return;
		api.getAll(`/people/${query}`)
			.then(result => {
				setOptions(result?.data || []);
			})
			.catch(error => {
				setOptions([]);
				console.error(error);
			});
	};
	const debouncedFind = useMemo(() => debounce(find, 200), []);
	const handleChange = async (event, newValue, reason) => {
		if (!reason) return;
		await onChange(event, newValue, reason);
		setValue(newValue);
	};
	useEffect(()=>{
		debouncedFind(query);
	}, [query, auth]);
	return (<Autocomplete
		{...props}
		value={value}
		onChange={ handleChange }
		filterOptions={(x) => x}
		options={options}
		getOptionLabel={(option) => option.name ? `${option.name}`
			+ (option.title ? ` - ${option.title}` : '') : ''
		}
		onInputChange={(event, value, reason) => {
			if (reason === 'input') {
				setQuery(value);
			}
		}}

		renderInput={(params) => (
			<TextField {...props} {...params} label={props.label} />
		)}
		isOptionEqualToValue={(option, value)=>{
			return option?.externalId === (value?.externalId);
		}}

		renderOption={(props, option) => {
			return (
				<li {...props}>
					<Grid container alignItems="center">
						<Grid item sx={{ display: 'flex', width: 44 }}>
							<Avatar sx={{ color: 'text.secondary' }} src={option.picture} />
						</Grid>
						<Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
							{option.name}
							<Typography variant="body2" color="text.secondary">
								{option.title}
							</Typography>
						</Grid>
					</Grid>
				</li>
			);
		}}
	>
	</Autocomplete>);
};

GoogleDirectorySearch.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.object,
	label: PropTypes.string,
	autoFocus: PropTypes.bool
};
export default GoogleDirectorySearch;
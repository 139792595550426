import Joi from 'joi';
// @ts-ignore
export const OperatingEntitySchema = Joi.object({
	name: Joi.string().optional().label('Name'),
	fein: Joi.string()
		.optional()
		.allow(null, '')
		.empty('').default(null)
		.regex(/^$|^[1-9]\d?-\d{7}$/, '##-#######')
		.label('FEIN'),
	communities: Joi.array().optional().label('Communities'),
}).label('Operating Entity');


import {
	DataGridPremium,
	GridCellParams,
	GridColDef,
	GridTreeNode,
	useGridApiRef
} from '@mui/x-data-grid-premium';
import * as React from 'react';
import {
	useCallback,
	useMemo,
} from 'react';
import styled from '@emotion/styled';
import {
	CardContent,
	CardContentProps,
} from '@mui/material';
import {
	Property,
	PropertyContact
} from './types.js';

const CenteredContent = styled(CardContent)(({ theme }) => ({
	padding: undefined,
}));

export interface PropertyStaffingRow {
	role: string,
	active?: number,
	vacant?: number
}

export type PropertyStaffingType = {
	[key: string]: PropertyStaffingRow; // Optional vacant property
};
// @ts-ignore
interface PropertyStaffingProps extends CardContentProps {
	property?: Property,
	propertyContacts?: PropertyContact[]
	value?: PropertyStaffingType,
	canUpdate: boolean,
	readonly?: boolean
}

const INITIAL_STATE: PropertyStaffingRow[] = [
	{ role: 'Community Manager', active: undefined, vacant: undefined, },
	{ role: 'Associate Community Manager', active: undefined, vacant: undefined, },
	{ role: 'Community Leasing Manager', active: undefined, vacant: undefined, },
	{ role: 'Engineering Manager', active: undefined, vacant: undefined, },
	{ role: 'Leasing Associate', active: undefined, vacant: undefined, },
	{ role: 'Community Concierge', active: undefined, vacant: undefined, },
	{ role: 'Community Technician', active: undefined, vacant: undefined, },
	{ role: 'Housekeeper', active: undefined, vacant: undefined, },
	{ role: 'Porter', active: undefined, vacant: undefined, },
];

export const initializeRows = (propertyContacts?: PropertyContact[]) => {
	const roleCounts = propertyContacts?.reduce((acc: Record<string, number>, item: PropertyContact) => {
		const role = item.role;
		acc[role] = (acc[role] || 0) + 1;
		return acc;
	}, {}) ?? {};
	const values = INITIAL_STATE.map((v:PropertyStaffingRow)=>{
		return {role: v.role, active: roleCounts[v.role] ?? 0, vacant: 0 };
	});
	return values;
};

const getRows = ( value?: PropertyStaffingType, propertyContacts?: PropertyContact[] ): PropertyStaffingRow[]=>{
	const values = value && Object.values(value);
	return (values && values.length) ? values : initializeRows(propertyContacts);
};

const PropertyStaffingContent:React.FC<PropertyStaffingProps> = ( { id='staffing', property, propertyContacts, onChange, value, canUpdate = false, readonly = false, ...cardContentProps }) => {
	const [error,setError] = React.useState('');
	const gridApi = useGridApiRef();
	const columns: GridColDef<PropertyStaffingRow>[] = useMemo(() => [
		{ field: 'role', headerName: 'Role', editable: false, groupable: true, flex:1.5, required: true },
		{ field: 'active', headerName: 'Active', editable: true, groupable: true, description: 'Active positions', flex:.45, required: true, type: 'number' },
		{ field: 'vacant', headerName: 'Vacant', editable: true, groupable: true, description: 'Vacant positions', flex:.45, required: true, type: 'number' },
	], []);
	const rows = getRows(value, propertyContacts);

	const isCellEditable = useCallback((params: GridCellParams<any, PropertyStaffingRow, PropertyStaffingRow, GridTreeNode>)=>{
		return canUpdate && !readonly;
	},[canUpdate, readonly]);

	const handleProcessRowUpdate = useCallback(async ( newRow: PropertyStaffingRow, oldRow: PropertyStaffingRow) => {
		const rows = Array.from(gridApi.current.getRowModels().values() ?? []);
		const values = rows.map(row=>[row.role, {role: row.role, active: row.active, vacant: row.vacant}]);
		const value = Object.fromEntries(values);
		value[newRow.role] = {role: newRow.role, active: newRow.active, vacant: newRow.vacant};
		const event: {target:{id:string,value:any}} = {target:{ id, value }};

		// @ts-ignore
		onChange && onChange(event);
		return newRow;
	},[gridApi, id, onChange]);

	// @ts-ignore
	return (
		<CenteredContent {...cardContentProps} sx={{padding: '0px', width:'100%',}}>
			<DataGridPremium
				apiRef={gridApi}
				// sx={ { margin: -2, marginTop:0 }}
				columns={ columns }
				rows={ rows }
				getRowId={ r => r.role }
				hideFooter
				disableColumnMenu
				disableColumnSelector
				processRowUpdate={ handleProcessRowUpdate }
				density={ 'compact' }
				isCellEditable={isCellEditable}
			/>
		</CenteredContent>
	);
};

export default PropertyStaffingContent;

import * as React from 'react';
import { useIntl } from 'react-intl';
// @ts-ignore
import Page from 'material-ui-shell/lib/containers/Page';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../AbilityContext';
import {
	DataGridPremium,
	GridActionsCellItem,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
	useGridApiContext
} from '@mui/x-data-grid-premium';
import { Button, LinearProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorDisplay from '../ErrorDisplay';
import { useSnackbar } from 'notistack';
import { Clear, Refresh } from '@mui/icons-material';
import PageTitle from '../hooks/PageTitle';
import UniversityEdit from './UniversityEdit';
import useLocalStorage from '../hooks/LocalStorage';
import GlobalSearch from '../GlobalSearch';

const defaultSortModel=[{ field: 'name', sort: 'asc' }];
const defaultFilterModel={ items:[] };
const defaultColumnVisibilityModel={
	id: false,
	name: true,
	alias: true,
	createdAt: false,
	updatedAt: false
};

interface UniversityGridProps {
  data: [],
  setRefresh: Function,
  api: { deleteUniversity: Function, saveUniversity: Function, isLoading: boolean }
}
export const UniversityGrid: React.FC<UniversityGridProps> = ({data, setRefresh, api}) => {
	const { enqueueSnackbar } = useSnackbar();
	const handleError = ErrorDisplay();
	const intl = useIntl();
	const { deleteUniversity, saveUniversity } = api;
	const newUniversity = {types:[]};
	const [university, setUniversity] = React.useState(newUniversity);
	const getDate = (params: any) => params.value ? new Date(params.value): params.value;
	const formatDate = (params: any) => {
		if (!params.value) return params.value;
		return intl.formatDate(params.value, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		});
	};
	PageTitle({ id: 'universities', defaultTitle: 'Universities' });
	const [gridState, setGridState] = useLocalStorage('university-grid-model',{
		columns: { columnVisibilityModel: defaultColumnVisibilityModel },
		sorting: { sortModel: defaultSortModel },
		filtering: { filterModel: defaultFilterModel },
		pinnedColumns:{
			right: ['updatedAt','actions'],
			left: []
		}
	});
	const ability = useAbility(AbilityContext);
	const [open, setOpen] = React.useState(false);
  const [canCreate] = React.useState(ability.can('create', 'University'));
	const [canEdit] = React.useState(ability.can('update', 'University'));
	const [canDelete] = React.useState(ability.can('delete', 'University'));
	const editRow = React.useCallback(
		(params: any) => () => {
			setUniversity(params.row);
			setOpen(true);
		},
		[]
	);
	const deleteRow = React.useCallback(
		(params: any) => async () => {
			try
			{
				const data = await deleteUniversity(params.row.id);
				if (data && data.deleted)
				{
					setRefresh(true);
					enqueueSnackbar(`Deleted ${ data.deleted || 0 } Universities`, {
						variant: 'info',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center'
						}
					});
				}
			} catch (e)
			{
				await handleError(e);
			}
		},
		[deleteUniversity, enqueueSnackbar, handleError, setRefresh]
	);
	const columns = React.useMemo(() => [
		{ field: 'actions', type: 'actions', maxWidth: 20, minWidth: 20, width: 20,
			getActions: (params: any) => [
				<GridActionsCellItem icon={ <EditIcon /> } onClick={ editRow(params) } label="Edit" showInMenu disabled={ !canEdit } />,
				<GridActionsCellItem icon={ <DeleteIcon /> } onClick={ deleteRow(params) } label="Delete" showInMenu disabled={ !canDelete } />
			]
		},
		{ field: 'name', headerName: 'Name', minWidth: 300, description: 'University Full Name' },
		{ field: 'alias', headerName: 'Short Name', minWidth: 300, description: 'University Short Name' },
		{ field: 'createdAt', headerName: 'Created', minWidth: 200, type: 'dateTime', valueFormatter: formatDate, valueGetter: getDate },
		{ field: 'createdBy', headerName: 'Updated By', minWidth: 200, type: 'string',
			valueGetter: (v:any) => v?.value?.name, editable: false, groupable: false
		},
		{ field: 'updatedAt', headerName: 'Updated', minWidth: 200, type: 'dateTime', valueFormatter: formatDate, valueGetter: getDate },
		{ field: 'updatedBy', headerName: 'Updated By', minWidth: 200, type: 'string',
			valueGetter: (v:any) => v?.value?.name, editable: false, groupable: false
		},
	], [
		//editRow
	]);
	const handleClickAdd = async () =>
	{
		setOpen(true);
	};
	const handleClose = (saved: boolean) =>
	{
		setOpen(false);
		setUniversity(newUniversity);
		setRefresh(saved);
	};

	function CustomToolbar()
	{
		const apiRef = useGridApiContext();
		return (
			<GridToolbarContainer>
				<Button
					size="small"
					startIcon={ <AddIcon /> }
					onClick={ handleClickAdd }
					disabled={ !canCreate }
					title={ intl.formatMessage({ id: 'add_university', defaultMessage: 'Add University' }) }
				>
					{ intl.formatMessage({ id: 'add', defaultMessage: 'Add' }) }
				</Button>
				<Button
					size="small"
					startIcon={ <Refresh /> }
					onClick={ () =>
					{
						setRefresh(true);
					} }
					title={ intl.formatMessage({ id: 'refresh-grid', defaultMessage: 'Refresh Data' }) }
				>
					{ intl.formatMessage({ id: 'refresh', defaultMessage: 'Refresh' }) }
				</Button>
				<GridToolbarColumnsButton/>
				<GridToolbarFilterButton/>
				<GridToolbarExport />
				<Button
					size="small"
					startIcon={ <Clear /> }
					onClick={ () => {
						apiRef.current.restoreState({
							...gridState,
							columns: { columnVisibilityModel: defaultColumnVisibilityModel },
							sorting: { sortModel: defaultSortModel },
							filter: { filterModel: defaultFilterModel },
							pinnedColumns: {}
						});
					} }
					title={ intl.formatMessage({ id: 'reset-grid-config', defaultMessage: 'Reset Grid Settings' }) }
				>
					{ intl.formatMessage({ id: 'reset', defaultMessage: 'Reset' }) }
				</Button>
			</GridToolbarContainer>
		);
	}

	return (
		<Page
			pageTitle={ intl.formatMessage({ id: 'universities', defaultMessage: 'Universities' }) }
			isLoading={api.isLoading}
			appBarContent={
				<GlobalSearch/>
			}
		>
			<div style={ { height: '99%', width: '99%', alignContent: 'space-around' } }>
				<UniversityEdit
					open={ open }
					onClose={ handleClose }
					university={ university }
					onSave={ saveUniversity }
				/>
				<DataGridPremium
					rows={ data }
					columns={ columns }
					pagination
					disableRowGrouping={true}
					checkboxSelection={ false }
					checkboxSelectionVisibleOnly={ false }
					initialState={gridState}
					onStateChange={(state)=>setGridState(state)}
					editMode="row"
					sortingOrder={ ['desc', 'asc'] }
					slots={ {
						toolbar: CustomToolbar,
						loadingOverlay: LinearProgress,
					} }
				/>
			</div>
		</Page>
	);
};

import useGridHelper from '../hooks/GridHelper';
import { renderStatus } from '../grid/renderStatus';
import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';

export default function useProjectMilestoneGridColumns():GridColDef[] {
	const { getDate, formatDate } = useGridHelper();

	return useMemo<GridColDef[]>(() => [
		{
			field: 'id', headerName: 'ID', minWidth: 10, description: 'Milestone Group', hide: true, sortable: false,
			filterable: false
		},
		{
			field: 'group', headerName: 'Group', minWidth: 200, width:220,
			valueGetter: (p)=>p.value?.name,
			groupingValueGetter:({ row })=>row.group?.name,
			//valueFormatter: ({value})=>value?.name,
			description: 'Milestone Group', sortable: false, filterable: true
		},
		{
			field: 'name', headerName: 'Milestone', minWidth: 220, flex: 1,
			description: 'Milestone Name', editable: true, sortable: false,
		},
		{
			field: 'phase', headerName: 'Phase', minWidth: 100, description: 'Phase', sortable: false,
			valueGetter: p=>p?.value?.name, hide: true,
		},
		{
			field: 'dueAt', headerName: 'Due', minWidth: 80, width: 90, maxWidth: 100,
			description: 'Due Date', editable: true, type: 'date', sortable: false,
			valueFormatter: formatDate,
			//valueGetter: getDate
		},
		{
			field: 'completedAt', headerName: 'Completed', minWidth: 80, width: 80,
			description: 'Completion Date', editable: true, type: 'date', sortable: false,
			valueFormatter: formatDate,
			//valueGetter: getDate
		},
		{
			field: 'daysRemaining', headerName: 'Days Remaining', minWidth: 140, description: 'Days until due', editable: false, type: 'number', sortable:false
		},
		{
			field: 'status', headerName: 'Status', minWidth: 120, width: 120,
			description: 'Status', editable: false, sortable: false,
			valueGetter: (p)=>p.value?.id,
			groupingValueGetter: (p)=>p.value?.id,
			renderCell: renderStatus,
		},
		{
			field: 'updatedAt', headerName: 'Updated', minWidth: 140, type: 'dateTime',
			valueFormatter: formatDate, sortable: false,
			valueGetter: getDate
		},
		{
			field: 'updatedBy', headerName: 'Updated By', minWidth: 140,
			valueGetter: (v) => v?.value?.name, groupable: false, editable: false,
			sortable: false
		},
	],[formatDate, getDate]);
}
import React, { useCallback, useEffect } from 'react';

import ErrorDisplay from '../ErrorDisplay';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import useLocalStorage from '../hooks/LocalStorage';
import { useAuditApi } from '../../services/audit';
import useGridHelper from '../hooks/GridHelper';
import PropTypes from 'prop-types';
import { getModifiedValues } from '../../utils';
import { AbilityContext } from '../../components/AbilityContext';
import { useAbility } from '@casl/react';
import capitalize from 'lodash/capitalize';
import { useSnackbar } from 'notistack';

const AuditableHistoryGrid = ({ auditable, auditableType, editable = false,
	style = { height: '100%', width: '100%', alignContent: 'space-around', overflow:'auto' }
}) => {
	const handleError = ErrorDisplay();
	const api = useAuditApi(handleError);
	const { getDate, formatDateTime, formatFieldName } = useGridHelper();
	const ability = useAbility(AbilityContext);
	const { enqueueSnackbar } = useSnackbar();

	const formatUser = (params) => {
		if (!params.value) return params.value;
		return params.value.name;
	};
	const columns = React.useMemo(() => [
		// { field: 'id', headerName: 'ID', minWidth: 100, hidden: true, hideable: false, filterable: false, groupable: false, disableColumnMenu: true },
		{ field: 'createdAt', headerName: 'Date', minWidth: 200, type: 'date',
			valueFormatter: formatDateTime,
			valueGetter: getDate
		},
		{ field: 'createdBy', headerName: 'User', minWidth: 200, type: 'user',
			valueFormatter: formatUser
		},
		{ field: 'action', headerName: 'Action', minWidth: 100 },
		{ field: 'fieldName', headerName: 'Field', minWidth: 180, valueFormatter: formatFieldName },
		{ field: 'fieldType', headerName: 'Type', minWidth: 200 },
		{ field: 'from', headerName: 'From', minWidth: 200 },
		{ field: 'to', headerName: 'To', minWidth: 200 },
		{ field: 'requestId', headerName: 'RequestId', minWidth: 200 },
		{ field: 'note', headerName: 'Note', minWidth: 200,
			editable: ability.can('update', capitalize(auditableType)),
		},
	], []);
	let [rows, setRows] = React.useState([]);
	const [gridState, setGridState] = useLocalStorage(`${auditableType}-audit-grid-model`,{
		columns: {
			columnVisibilityModel: {
				id: false,
				createdAt: true,
				createdBy: true,
				fieldName: true,
				fieldType: false,
				action: true,
				from: true,
				to: true,
				requestId: false,
			},
		},
		filter:{
			filterModel: {
				items: [],
				linkOperator: 'and',
				quickFilterValues: [],
				quickFilterLogicOperator: 'and'
			},
			visibleRowsLookup: {},
			filteredDescendantCountLookup: {},
			filteredRowsLookup: {}
		},
		sorting: {
			sortModel: [{
				field: 'createdAt',
				sort: 'desc'
			}],
		}
	});
	useEffect(() => {
		if (auditable?.id) {
			api.findByTypeId(auditableType, auditable.id)
				.then((data) => {
					setRows(data ?? []);
				});
		}
	}, [auditable?.id]);
	const processRowUpdate = useCallback(
		async (newRow, oldRow) => {
			const auditRecord = oldRow.id ? { ...getModifiedValues(newRow, oldRow), id:oldRow.id } : newRow;
			const response = await api.save(auditRecord);
			if (response && response.id) {
				enqueueSnackbar(`Saved Successfully`, {
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center'
					}
				});
			}
			return response ?? oldRow;
		},
		[api],
	);
	return (
		<div style={ style }>
			<DataGridPremium
				getRowId={(row)=>row.id}
				rows={ rows }
				columns={ columns }
				initialState={gridState}
				onStateChange={(state)=>setGridState(state)}
				disableRowGrouping={true}
				disableAggregation={true}
				slots={{
					toolbar: GridToolbar
				}}
				processRowUpdate={processRowUpdate}
				onProcessRowUpdateError={(error)=>console.error(error)}
			/>
		</div>
	);
};

AuditableHistoryGrid.propTypes={
	auditable: PropTypes.object,
	auditableType: PropTypes.string,
	editable: PropTypes.bool
};
export default AuditableHistoryGrid;

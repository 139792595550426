import React from 'react';
import {MenuItem, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import { Lock } from '@mui/icons-material';
const defaultRenderOption = (option) => (
	<MenuItem key={option.code} value={option.code}>
		{option.name}
	</MenuItem>
);
const Select = ({id, label, value, options, onChange, error, helperText, defaultValue, inputProps, renderOption, multiple=false, readOnly, required, variant='standard'})=> {
	renderOption = renderOption ?? defaultRenderOption;

	const _inputProps = {
		readOnly,
		...inputProps,
		endAdornment: readOnly ? <InputAdornment position="end" ><Lock fontSize='small'/></InputAdornment>: undefined
	};
	return (<TextField
		id={id}
		select
		label={label}
		variant={variant}
		value={value}
		defaultValue={defaultValue}
		onChange={
			(e) => {
				let event = {...{target: {id, value: e.target.value}}, ...e};
				onChange(event, event.target.value);
			}
		}
		error={error}
		helperText={helperText}
		required={required}
		margin="dense"
		fullWidth
		multiple={multiple}
		readOnly={readOnly}
		inputProps={ _inputProps }
	>
		{options.map && options?.map(renderOption)}
	</TextField>);
};

Select.propTypes = {
	id: PropTypes.any,
	label: PropTypes.string,
	value: PropTypes.any,
	options: PropTypes.array,
	onChange: PropTypes.func,
	error: PropTypes.any,
	helperText: PropTypes.string,
	defaultValue: PropTypes.any,
	inputProps: PropTypes.object,
	renderOption: PropTypes.func,
	multiple: PropTypes.bool,
	readOnly: PropTypes.bool,
	required: PropTypes.bool,
	variant: PropTypes.string
};
export default Select;

import React, { useEffect, useState } from 'react';

import ErrorDisplay from '../ErrorDisplay';
import { useAuth } from 'base-shell/lib/providers/Auth';
import { useProjectApi } from '../../services/project-api';
import {
	Card,
	CardContent, CardHeader,
	IconButton
} from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import useGridHelper from '../hooks/GridHelper';
import PropTypes from 'prop-types';
import { DataGridPremium, GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD } from '@mui/x-data-grid-premium';
import useProjectMilestoneGridColumns from './ProjectMilestoneGridColumns';
import { orderBy } from 'lodash';

const StyledCard = styled(Card)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: 0,
	width: '100%',
	textAlign: 'left',
	verticalAlign: 'top',
	color: theme.palette.text.secondary,
}));

const ProjectMilestonesTile = ({ project, title, href, sx, autoHeight=false }) => {
	const { auth } = useAuth();
	const handleError = ErrorDisplay();
	const projectApi = useProjectApi(handleError);
	const [rows, setRows] = useState([]);
	const { GridAggregationFunctions } = useGridHelper();
	const navigate = useNavigate();
	const columns = useProjectMilestoneGridColumns();
	const [gridState] = useState({
		columns: {
			columnVisibilityModel: {
				'id': false,
				'group': false,
				'phase': false,
				'name': false,
				'dueAt': true,
				'completedAt': false,
				'daysRemaining': false,
				'updatedAt': false,
				'updatedBy': false
			},
			//dimensions: {[GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD]: {maxWidth: -1, minWidth: 220, flex: 1}},
			orderedFields: [GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD]
		},
		pinnedColumns:{ left: [GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD], right:[] },
		filter:{
			filterModel:{
				items:[
					// {
					// 	'field': 'status',
					// 	'operator': 'isAnyOf',
					// 	'value': [
					// 		'due',
					// 		'overdue',
					// 		'ok'
					// 	]
					// }
				],
				//logicOperator: 'and'
			},
		},
		aggregation: {
			model: {
				dueAt: 'max',
				completedAt: 'max',
				daysRemaining: 'max',
				status: 'milestoneStatus'
			},
		},
		sorting: {
			// sortModel: [{ field: 'group', sort:'asc' },{ field: 'dueAt', sort:'asc' }],
		},
		rowGrouping: {
			model: [
				'group'
			]
		}
	});

	useEffect(() => {
		if (project?.milestones) {
			const filtered = project?.milestones
				.filter(r=>!r.phaseId || r.phaseId === project?.phaseId)
			;
			setRows(orderBy(filtered, ['group.index', 'dueAt']));
		} else {
			project?.id && projectApi.getMilestones(project?.id)
				.then((data) => {
					if (Array.isArray(data)) {
						project.milestones = data;
						const filtered = data?.filter(r => !r.phaseId || r.phaseId === project?.phaseId);
						setRows(filtered);
					}
				});
		}
	}, [auth, project?.id]);
	const Actions = (
		<IconButton
			size="small"
			disabled={!project?.id}
			onClick={()=>navigate(`/projects/${project?.code}/${project?.subCode}/process/milestones`)}
		>
			<OpenInNew />
		</IconButton>);
	return (
		<StyledCard sx={sx}>
			{title && <CardHeader
				key={ title }
				title={ title }
				//subheader={error ?? ''}
				subheaderTypographyProps={{color:'red'}}
				sx={{'& .MuiCardHeader-title': { fontSize: '1.10rem'}}}
				action={ Actions }
			/> }
			<CardContent sx={{ padding: '0px', overflow: 'visible', maxHeight: '100%', width: '100%' }}>
				<div style={ { height: title ? sx?.height-48 : sx?.height, width: '100%', alignContent: 'space-around' } }>
					<DataGridPremium
						autoHeight={ autoHeight }
						rows={ rows }
						rowHeight={40}
						columns={ columns }
						initialState={gridState}
						defaultGroupingExpansionDepth={1}
						aggregationFunctions={ GridAggregationFunctions }
						groupingColDef={{
							leafField: 'name',
							hideDescendantCount: true,
							flex: autoHeight ? undefined : 1,
							width: autoHeight ? 220 : undefined
						}}
						sx={{
							fontSize: '0.875rem',
						}}
						hideFooter={true}
						disableSelectionOnClick={true}
						disableColumnMenu={true}
						disableColumnSelector={true}
						disableColumnFilter={true}
						disableDensitySelector={true}
						isCellEditable={(params) => false }
						density={'compact'}
						isGroupExpandedByDefault={
							node => node.groupingField === 'group' && rows.find(r=>r.group?.name === node.groupingKey && r.status?.id !== 'COMPLETED')
						}
					/>
				</div>
			</CardContent>
		</StyledCard>
	);};

ProjectMilestonesTile.propTypes = {
	project: PropTypes.object,
	title: PropTypes.string,
	href:PropTypes.string|undefined,
	sx: PropTypes.object,
	autoHeight: PropTypes.bool
};

export default ProjectMilestonesTile;
// @ts-ignore
import Page from 'material-ui-shell/lib/containers/Page';
import React, {
	useEffect,
	useState
} from 'react';
import AdminToolbar from '../../components/Admin/AdminToolbar';
import ReportConfiguration from '../../components/Admin/ReportConfiguration';
import ErrorDisplay from '../../components/ErrorDisplay';
import PageTitle from '../../components/hooks/PageTitle';
import { useReportingApi } from '../../services/reporting-ui-api';

const ReportAdmin: React.FC = () => {

	const handleError = ErrorDisplay();
	const api = useReportingApi( handleError );
	PageTitle( { id: 'report.admin', defaultTitle: 'Report Configuration' } );

	return (
		<Page
			pageTitle={ 'Report Configuration' }
			isLoading={ api.isLoading }
			appBarContent={
				<AdminToolbar/>
			}
		>
			<ReportConfiguration/>

		</Page>
	);
};

export default ReportAdmin;

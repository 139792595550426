import React, {
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import {
	Grid,
} from '@mui/material';
import { usePropertyApi } from '../../../../services/property-api';
import {
	ReportTemplate,
	useReportingApi
} from '../../../../services/reporting-ui-api';
import DisplayField from '../../../DisplayField';
import ErrorDisplay from '../../../ErrorDisplay';
import LookTileContent from '../../../Looker/LookTileContent';
import { ReportContext } from '../../../Reports/ReportContext';
import Tile from '../../../Tile.js';
import PropertyStaffingContent, { PropertyStaffingType } from '../../PropertyStaffing';
import {
	Property,
	PropertyContact
} from '../../types.js';
import {
	MultilineTextInputField,
	TextInputFieldProps
} from './MultilineTextInputField';
import { PropertyReportProps } from './types';

const maxLength = 120;
export interface PropertyUpdateReportState {
		communityName: string,
		market: string,
		communityManager: string,
		unitCount: number,
		bedCount: number,
		retailArea: number,
		propertyGroup: string,
		partner: string,
		lender: string,
		preleaseNumbers: any,
		weeklyLeasingStats1: any,
		weeklyLeasingStats2: any,
		cyPreleasePercentages: any,
		cyPreleaseNumbers: any
		preleaseYoYComparison: any,
		marketComparisonChart: any,
		marketComparisonTable: any,
		// residentEvents: string,
		marketOutreach: string,
		marketInsight: string,
		currentConcessions: string,
		operationsInsight: string,
		leasingRecommendation: string,
		staffing: PropertyStaffingType,
		staffingComment: string,
		status: string,
		teamCommentary: string,
		mcSupport: string,
		assetMarketingCommentary: string,
		rateCommentary: string,
		rejectionFeedback: string,
		keyDates: string,
		successes: string,
		challenges: string,
		actionItemUpdates: string,
		newActionItems: string
		recommendations: string,
		watchlistState: string,
		watchlistReason: string,
		watchlistComment: string,
		trending: any,
		weeklyMarketShare:any,
		goals:any,
		rating: number
}

interface PropertyWeeklyUpdateProps extends PropertyReportProps { }

const TYPE = 'Property';
const NAME = 'weekly_update';
const VERSION = 1;

const EMPTY_VALUE = window.env.reporting?.defaultEmptyValue ?? '';

const PropertyWeeklyPartnerReport: React.FC<PropertyWeeklyUpdateProps> = ( { initialState, canUpdate, onChange, ...props }) => {
	const reportingApi = useReportingApi(ErrorDisplay);
	const propertyApi = usePropertyApi( ErrorDisplay );
	const [property, setProperty] = useState<Property>(props.property) ;
	const [report] = useContext(ReportContext);
	const [template, setTemplate] = useState<ReportTemplate>(report.template);
	const [contacts, setContacts] = useState<PropertyContact[]>();
	const [state, setState, ] = useState<PropertyUpdateReportState>( report.data );
	const [readonly, setReadonly] = useState<boolean>(!!report.submitted);
	const [refresh, setRefresh] = useState<boolean>(!!props?.refresh);
	const updateState = useCallback((newState: Partial<PropertyUpdateReportState>) => {
		setState((prevState)=>{
			return {
				...prevState,
				...newState
			};
		});
		onChange( { data: newState });
	},[onChange]);

	const handleOnChange = useCallback((event: any)=>updateState( { [event.target.id]: event.target.value } ),[]);

	useEffect( () => {
		setReadonly(!!report.submitted);
		setRefresh(!!props.refresh);
	}, [report.submitted] );

	useEffect(()=>{
		if (!template?.id) {
			reportingApi.getReportTemplate( TYPE, NAME, VERSION )
				.then( newTemplate => {
					setTemplate( newTemplate );
				} );
		}
	}, [template?.id]);

	useEffect(()=>{
		if (!readonly && property?.id) {
			if ( !contacts ) {
				propertyApi.getContacts( property?.id )
					.then( contacts => {
						setContacts( contacts );
					} );
			}
			if ( ! state?.lender ) {
				propertyApi.getProjects( property?.id )
					.then( ( projects ) => {
						const lender = projects?.flatMap( ( p: any ) => p.vendors?.filter( ( v: any ) => v.types?.includes(
							'LENDER' ) )?.map( ( l: any ) => l.name ) );
						updateState( { lender: lender?.join( ',' ) } );
					} );
			}
		}

	},[readonly, property?.id, updateState]);

	useEffect(()=>{
		if (!readonly) {
			const defaultValue = template?.config?.communityManager?.defaultValue ?? EMPTY_VALUE;
			const communityManager = contacts?.find( (pc:PropertyContact) => pc.role === 'Community Manager' )?.contact?.name ?? state?.communityManager ?? defaultValue;
			updateState({
				communityManager,
			});
		}

	},[contacts, state?.communityManager, template?.config?.communityManager?.defaultValue, updateState]);

	useEffect(()=>{
		setRefresh(!!props?.refresh);
	},[props.refresh]);

	const CommentaryProps: TextInputFieldProps = {
		variant: 'standard',
		rows: 3,
		maxRows: 3 ,
		maxLength,
		fullWidth: true,
		required: true,
		size: 'small',
		sx: {fontSize:'.5em'},
		onChange: handleOnChange,
		inputProps: { readOnly: !canUpdate || readonly, maxLength }
	};

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Grid container justifyContent="space-between">
					<Grid item><DisplayField
						key='market' id='market' label='Market' sx={{fontSize:'.5em'}}
						value={state?.market} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='cm' id='cm' label='Community Manager'
						value={state?.communityManager} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='unitCount' id='unitCount' label='# Units'
						value={state?.unitCount} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='bedCount' id='bedCount' label='# Beds'
						value={state?.bedCount} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='retailArea' id='retailArea' label='Retail SqFt.'
						value={state?.retailArea} onChange={handleOnChange}
					/></Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={1}>
					{/* Replace the following with your graph components */}
					<Grid item xs={6}>
						<Tile title='Prelease Stats'
							sx={{
								height: '190px',
								fontSize:'smallest'}}>
							<LookTileContent
								id='cyPreleasePercentages'
								lookId={template?.config?.cyPreleasePercentages?.lookId ?? '99'}
								value={state?.cyPreleasePercentages}
								refresh={ refresh }
								sx={ {height: '60px'} }
								filters={ {
									'lease_diary_property_units.community_filter': state?.communityName
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
							<LookTileContent
								id='cyPreleaseNumbers'
								lookId={template?.config?.cyPreleaseNumbers?.lookId}
								value={state?.cyPreleaseNumbers}
								refresh={ refresh }
								sx={ {height: '60px'} }
								filters={ {
									'lease_diary_property_units.community_filter': state?.communityName,
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
						</Tile>
						<Tile title='Weekly Leasing Stats' sx={{height: '356px', fontSize:'smallest'}}>
							<LookTileContent
								key='weeklyLeasingStats1'
								id='weeklyLeasingStats1'
								lookId={template?.config?.weeklyLeasingStats1?.lookId}
								value={state?.weeklyLeasingStats1}
								refresh={ refresh }
								sx={ {height: '190px'} }
								filters={ {
									'subject_and_competitor_leasing.community': state?.communityName
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
							<LookTileContent
								key='weeklyLeasingStats2'
								id='weeklyLeasingStats2'
								lookId={template?.config?.weeklyLeasingStats2?.lookId}
								value={state?.weeklyLeasingStats2}
								refresh={ refresh }
								sx={ {height: '140px'} }
								filters={ {
									'lease_diary_property_units.community_for_filter': state?.communityName,
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
							<LookTileContent
								id='trending'
								lookId={'135'}
								value={state?.trending}
								refresh={ refresh }
								sx={ {height: '40px', display:'none'} }
								filters={ {
									'lease_diary_property_units.community_filter': state?.communityName
								} }
								readonly={readonly}
								onChange={ handleOnChange }
							/>
							<LookTileContent
								id='weeklyMarketShare'
								lookId={'136'}
								rowLimit={1}
								value={state?.weeklyMarketShare}
								refresh={ refresh }
								sx={ {height: '60px'} }
								filters={ {
									'subject_and_competitor_leasing.community': state?.communityName
								} }
								readonly={readonly}
								onChange={ handleOnChange }
							/>
							<LookTileContent
								id='goals'
								lookId={'137'}
								value={state?.goals}
								refresh={ refresh }
								sx={ {height: '60px'} }
								rowLimit={1}
								filters={ {
									'lease_diary_property_units.community': state?.communityName
								} }
								readonly={readonly}
								onChange={ handleOnChange }
							/>
						</Tile>
					</Grid>
					<Grid item xs={6}>
						<Tile title='Prelease YoY Comparison' sx={{height: '546px', fontSize:'smallest'}}>
							<LookTileContent
								id='preleaseYoYComparison'
								lookId={template?.config?.preleaseYoYComparison?.lookId}
								value={state?.preleaseYoYComparison}
								refresh={ refresh }
								type='image'
								// sx={ {height: '340px'} }
								imageHeight={300}
								// imageWidth={400}
								filters={ {
									'leasing_velocity_cy_vs_py.community': state?.communityName,
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
						</Tile>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={1}>
					<Grid item xs={5}>
						<Tile title='Market Comparison' sx={{height: '700px', fontSize:'smallest'}}>
							<LookTileContent
								id='marketComparisonChart'
								lookId={template?.config?.marketComparisonChart?.lookId}
								title='Market Comparison'
								value={state?.marketComparisonChart}
								refresh={ refresh }
								sx={ {height: '360px'} }
								//imageWidth={460}
								imageHeight={355}
								type='image'
								filters={ {
									'subject_and_competitor_leasing.community': state?.communityName
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
							<LookTileContent
								id='marketComparisonTable'
								lookId={ template?.config?.marketComparisonTable?.lookId } // '120' }
								title='Market Comparison'
								value={state?.marketComparisonTable}
								refresh={ refresh }
								sx={ {height: '360px'} }
								// imageWidth={460}
								imageHeight={100}
								cropHeight={50}
								cropWidth={650}
								type='image'
								filters={ {
									'subject_and_competitor_leasing.community': state?.communityName
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
						</Tile>
					</Grid>
					<Grid item xs={4}>
						<Tile title={'Property Leasing Activity & Insight'} sx={{height: 'fit-content', fontSize:'smallest'}}>
							{/*<MultilineTextInputField*/}
							{/*	id='residentEvents'*/}
							{/*	label={'Resident Events'}*/}
							{/*	defaultValue={state?.residentEvents}*/}
							{/*	{ ...CommentaryProps }*/}
							{/*/>*/}
							<MultilineTextInputField
								id='marketOutreach'
								label={'Market Outreach'}
								defaultValue={state?.marketOutreach}
								{ ...CommentaryProps }
							/>
							<MultilineTextInputField
								id='currentConcessions'
								label={'Current Concessions'}
								defaultValue={state?.currentConcessions}
								{ ...CommentaryProps }
							/>
							<MultilineTextInputField
								key='leasingRecommendation'
								id='leasingRecommendation'
								label='Recommendation'
								defaultValue={state?.leasingRecommendation}
								{ ...CommentaryProps }
							/>
						</Tile>
						<Tile title='Key Dates & Market Insight' sx={{height: 'fit-content', fontSize:'smallest'}}>
							<MultilineTextInputField
								id='marketInsight'
								label={''}
								defaultValue={state?.marketInsight}
								{ ...CommentaryProps }
							/>
						</Tile>
						<Tile title='Facilities, Maintenance & Operations' sx={{height: 'fit-content', fontSize:'smallest'}}>
							<MultilineTextInputField
								id='operationsInsight'
								label={''}
								defaultValue={state?.operationsInsight}
								{ ...CommentaryProps }
								maxLength={400}
								maxRows={5}
								rows={5}
								inputProps={{ ...CommentaryProps.inputProps, maxLength: 400 }}
							/>
						</Tile>
					</Grid>
					<Grid item xs={3}>
						<Tile title="Current Staffing" sx={ { height: '700px', fontSize: 'smallest' } }>
							<PropertyStaffingContent
								id="staffing"
								property={ property }
								sx={ { margin: '0px', fontSize: 'smaller' } }
								value={ state?.staffing }
								canUpdate={canUpdate}
								onChange={ ( event: any ) => updateState( { [event.target.id]: event.target.value } ) }
								readonly={readonly}
							/>
							<br />
							<MultilineTextInputField
								id="staffingComment"
								label="Staffing Commentary"
								defaultValue={ state?.staffingComment ?? '' }
								{ ...CommentaryProps }
							/>
							<br />
							<MultilineTextInputField
								id="status"
								label="Follow Up Items & Status"
								defaultValue={ state?.status }
								{ ...CommentaryProps }
							/>
						</Tile>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PropertyWeeklyPartnerReport;

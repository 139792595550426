import Chip, { ChipProps } from '@mui/material/Chip';
import capitalize from 'lodash/capitalize';
import React, { FC } from 'react';
import { ReportStateType } from './types';

interface ReportStateChipProps extends ChipProps {
	state?: ReportStateType,
	terminalState?: string
}

export const ReportStateChip: FC<ReportStateChipProps> = ( { state, terminalState = 'submitted', ...props }) => {

	return state ? (
		<Chip label={capitalize(state)} sx={{color: state !== terminalState ? 'red':'green'}} {...props }/>
	) : null;
};

import { useEffect } from 'react';
import { useIntl } from 'react-intl';

export default function PageTitle({ id, defaultTitle })
{
	const intl = useIntl();
	useEffect(() =>
	{
		document.title =
			(!id && !defaultTitle) ? `${ intl.formatMessage({ id: 'app_name' }) }` :
				`${ intl.formatMessage({
					id: id,
					defaultMessage: defaultTitle
				}) } - ${ intl.formatMessage({ id: 'app_name' }) }`;
	});
}

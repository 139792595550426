import React from 'react';
import {useIntl} from 'react-intl';
// @ts-ignore
import Page from 'material-ui-shell/lib/containers/Page';
import PageTitle from '../../components/hooks/PageTitle';
import AdminToolbar from '../../components/Admin/AdminToolbar';
import TaskHistoryGrid from '../../components/Admin/TaskHistoryGrid';


const TaskHistory = () => {
	const intl = useIntl();
	PageTitle({id: 'admin', defaultTitle: 'Admin'});
	return (
		<Page
			pageTitle={intl.formatMessage({id: 'task_history', defaultMessage: 'Task History'})}
			appBarContent={<AdminToolbar/>}
		>
			<TaskHistoryGrid/>
		</Page>
	);
};
export default TaskHistory;

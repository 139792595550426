import {
	createContext,
	useContext
} from 'react';
import { Market } from './types.js';

const defaultValue = Symbol();

export type MarketContextType = {
	market: Market;
	setMarket: ( market: Market ) => any;
}
export type MarketContextTypeOrDefault = MarketContextType | typeof defaultValue

export const MarketContext = createContext<MarketContextTypeOrDefault>( defaultValue );


export const useMarketContext = (): MarketContextType => {
	let context = useContext( MarketContext );
	if ( context === defaultValue ) {
		throw new Error( 'missing context ' );
	}
	return context;
};

import { LookerEmbedSDK } from '@looker/embed-sdk';
import { useAuth } from 'base-shell/lib/providers/Auth';
import { useMemo } from 'react';
export const useLooker = () => {
	const {auth} = useAuth();
	return useMemo(()=>{
		console.info('Initializing LookerEmbedSDK');
		let lookerHost = window.env.lookerHost;
		let token = auth.token;
		if (token && !token.isAuthenticated) {
			// The looker api uses a direct XMLHttpRequest under the covers and not the fetch api
			// or axios so there is no way to intercept or pass in our request handler
			LookerEmbedSDK.initCookieless(lookerHost, {
				url: '/api/reporting/acquire-embed-session',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${ token }`
				},
			},
			{
				url: '/api/reporting/generate-embed-tokens',
				method:'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${ token }`
				}
			},
			);
		}
		return LookerEmbedSDK;
	}, [auth]);
};

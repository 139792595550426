import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField
} from '@mui/material';
import React, {
	useEffect,
	useState
} from 'react';
import {
	DayOfWeek,
	DayType,
	Month
} from '../Admin/SyncJobTypes';


type ScheduleModelType = {
	cronString: string,
	parts: string[],
	scheduleType: string,
	timeValue: string,
	dayOfWeek: DayOfWeek,
	dayValue: DayType,
};

//The regex for actual cron is so not worth it...

const validateCronString = ( c: string ): string => {
	if ( c.split( ' ' ).length != 5 ) {
		return '* * * * *';
	}
	return c;
};

const ScheduleInput = ( { cronString, callback, editable }: {
	cronString: string,
	editable: boolean,
	callback: ( cronString: string ) => void
} ) => {

	let [ model, setModel ] = useState<ScheduleModelType>( {
		cronString: validateCronString( cronString ),
		scheduleType: '',
		parts: [],
		timeValue: '',
		dayValue: 1,
		dayOfWeek: 'MON',
	} );

	useEffect( () => {
		let parts = cronString.split( ' ' );
		let scheduleType = '';
		let timeValue = '';
		let dayValue: DayType = ( parts[2] === '*' ? '*' : Number( parts[2] ) );
		let monthValue: Month = parts[3] as Month;
		let dayOfWeek: DayOfWeek = parts[4] as DayOfWeek;
		if ( parts[0] !== '*' && parts[1] === '*' && parts[2] === '*' && parts[3] === '*' && dayOfWeek === '*' ) {
			scheduleType = `hourly`;
			timeValue = parts[0];
		}
		else if ( parts[0] !== '*' && parts[1] !== '*' && parts[2] === '*' && parts[3] === '*' && dayOfWeek === '*' ) {
			scheduleType = `daily`;
			timeValue = `${ parts[1].padStart( 2, '0' ) }:${ parts[0].padStart( 2, '0' ) }`;
		}
		else if ( parts[0] !== '*' && parts[1] !== '*' && parts[2] === '*' && parts[3] === '*' && dayOfWeek !== '*' ) {
			scheduleType = `weekly`;
			timeValue = `${ parts[1].padStart( 2, '0' ) }:${ parts[0].padStart( 2, '0' ) }`;
		}
		else {
			scheduleType = `custom`;
		}

		let newModel: ScheduleModelType = {
			cronString,
			timeValue,
			parts,
			scheduleType,
			dayValue,
			dayOfWeek
		};

		setModel( newModel );
	}, [ callback ] );

	const handleTypeChange = ( event: any ) => {
		const newType = event.target.value as string;
		let cronString = model.cronString;
		console.dir( newType );
		let parts = model.parts;

		let {
			dayOfWeek,
			dayValue
		} = model;
		console.dir( parts );
		switch ( newType ) {
			case 'hourly':
				console.dir( 'hourly' );
				cronString = `${ parts[0] || 0 } * * * *`;
				break;
			case 'daily':
				console.dir( 'daily' );
				cronString = `${ parts[0] || 0 } ${ parts[1] === '*' ? 0 : parts[1] } * * *`;
				break;
			case 'weekly':
				console.dir( 'weekly' );
				dayOfWeek = 'MON';
				cronString = `${ parts[0] || 0 } ${ parts[1] === '*' ? 0 : parts[1] } * * ${ dayOfWeek }`;
				break;
			case 'custom':
				cronString = `* * * * *`;
				console.dir( 'custom' );
				break;
		}
		cronString = validateCronString( cronString );
		callback( cronString );
	};

	const handleDayOfWeekChange = ( event: any ) => {
		let parts = model.parts;

		if ( model.scheduleType === 'weekly' ) {
			parts[4] = event.target.value;
		}
		let cronString = parts.join( ' ' );
		callback( cronString );
	};

	const handleTimeChange = ( event: any ) => {
		if ( model.scheduleType === 'hourly' ) {
			let cronString = `${ event.target.value } * * * *`;
			callback( cronString );
		}
		else {
			const [ newHour, newMinute ] = ( event.target.value ).split( ':' ).map( Number );
			let cronString = `${ newMinute } ${ newHour } ${ model.parts[2] } ${ model.parts[3] } ${ model.parts[4] }`;
			callback( cronString );
		}
	};

	const handleCronStringChange = ( event: any ) => {
		let cronString = `${ event.target.value }`;
		callback( cronString );
	};


	return (
		<Stack spacing={ 5 } margin={ 5 }>
			<FormControl fullWidth>
				<InputLabel>Schedule Type</InputLabel>
				<Select disabled={ !editable }
				        value={ model.scheduleType }
				        onChange={ handleTypeChange }>
					<MenuItem value="hourly">Hourly</MenuItem>
					<MenuItem value="daily">Daily</MenuItem>
					<MenuItem value="weekly">Weekly</MenuItem>
					<MenuItem value="custom">Custom</MenuItem>
				</Select>
			</FormControl>
			{ model.scheduleType === 'custom'
			  ?
			  <FormControl fullWidth>
				  <TextField
					  value={ model.cronString }
					  label={ 'Cron String' }
					  type="string"
					  onChange={ handleCronStringChange }
					  InputLabelProps={ {
						  shrink: true,
					  } }
				  />
			  </FormControl>
			  : (
				  <>
					  { model.scheduleType === 'weekly'
					    ? <FormControl fullWidth>
						    <InputLabel>On</InputLabel>
						    <Select disabled={ !editable }
						            value={ model.dayOfWeek }
						            onChange={ handleDayOfWeekChange }>
							    <MenuItem value="MON">Monday</MenuItem>
							    <MenuItem value="TUE">Tuesday</MenuItem>
							    <MenuItem value="WED">Wednesday</MenuItem>
							    <MenuItem value="THU">Thursday</MenuItem>
							    <MenuItem value="FRI">Friday</MenuItem>
							    <MenuItem value="SAT">Saturday</MenuItem>
							    <MenuItem value="SUN">Sunday</MenuItem>
						    </Select>
					    </FormControl>
					    : null
					  }
					  { model.scheduleType === 'hourly'
					    ? <TextField
						    disabled={ !editable }
						    fullWidth
						    label="Minute"
						    type="number"
						    value={ `${ Number( model.parts[0] ) || 0 }` }
						    onChange={ handleTimeChange }
						    InputLabelProps={ {
							    shrink: true,
						    }
						    }/>
					    : <TextField
						    disabled={ !editable }
						    fullWidth
						    label="Time"
						    type="time"
						    value={ model.timeValue }
						    onChange={ handleTimeChange }
						    InputLabelProps={ {
							    shrink: true,
						    } }
						    inputProps={ {
							    step: 300, // 5 min
						    }
						    }/>
					  }
				  </>
			  )
			}
		</Stack>
	);
};

export default ScheduleInput;

import {
	TextField,
	Typography
} from '@mui/material';
import * as React from 'react';
import { EditDataDialog } from '../EditDataDialog';
import { useIntl } from 'react-intl';
import { Market } from './types';
import APIAutocomplete from '../APIAutocomplete';


interface MarketEditProps {
	open: boolean,
	onClose: any,
	onSave: any,
	market: Market
}

export const MarketEdit: React.FC<MarketEditProps> = ( { open, onClose, onSave, market } ) => {
	const intl = useIntl();

	const handleMarketSelected = ( event: any, newValue: any, reason: any ) => {
		console.dir( newValue );
		if ( newValue && newValue.key ) {
			market.externalId = newValue.key;
		}
	};

	return (
		<EditDataDialog
			open={ open }
			data={ market }
			onClose={ onClose }
			onSave={ onSave }
			typeLabel={ intl.formatMessage( { id: 'market', defaultMessage: 'Market' } ) }
			disableAuditNote={ true }
		>
			<TextField
				id="name"
				label="Name"
				//description={'Market Full Name'}
				type="string"
				required
			/>

			<TextField
				id="marketSurveyId"
				label="Market Survey Id"
				type="number"
			/>

			<TextField
				id="externalId"
				label="College House Id"
				type="number"
				disabled={ true }
				value={ market.externalId }
			/>
			<Typography>
				Choose college house market:
			</Typography>
			<APIAutocomplete
				id="collegeHouseMarket"
				url={ '/college-house/markets' }
				getOptionLabel={ ( option ) => `${ option.city }, ${ option.stateAbbr }` }
				onChange={ handleMarketSelected }
				isOptionEqualToValue={ ( option, value ) => {
					return ( option?.marketKey === value?.marketKey );
				} }
			/>
		</EditDataDialog> );
};

export default MarketEdit;

import React, { useState, useRef, useEffect, useCallback } from 'react';

import ReactCrop, {
	centerCrop,
	makeAspectCrop,
	Crop,
	PixelCrop,
} from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';

interface ImageUploadProps {
  title: string
  src: string,
  isOpen: boolean,
  handleSave: any
  handleCancel: any
  aspectRatio: number,
}
const TO_RADIANS = Math.PI / 180;
function centerAspectCrop(
	mediaWidth: number,
	mediaHeight: number,
	aspect: number,
) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 100,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	);
}

export const ImageUploadDialog = ({title, src, isOpen, handleSave, handleCancel, aspectRatio = 5/7}:ImageUploadProps)=> {
	const [imgSrc, setImgSrc] = useState(src);
	const [imgName, setImageName] = useState(src?.substring(src.lastIndexOf('/')+1));
	const imgRef = useRef<HTMLImageElement>(null);
	const [crop, setCrop] = useState<Crop>();
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
	const [scale, setScale] = useState(1);
	const [rotate, setRotate] = useState(0);
	const [aspect, setAspect] = useState<number | undefined>(aspectRatio);
	const [isLoading, setIsLoading] = useState(false);
	const [modified, setModified] = useState(false);
	// const [openPicker, authResponse] = useDrivePicker();
	// const handleOpenPicker = () => {
	// 	openPicker({
	// 		setParentFolder: '0AHWPW4q5mzzkUk9PVA',
	// 		setIncludeFolders: true,
	// 		setSelectFolderEnabled: true,
	// 		clientId: window.env.googleClientId,
	// 		developerKey: '',
	// 		viewId: 'DOCS_IMAGES',
	// 		// token: token, // pass oauth token in case you already have one
	// 		showUploadView: false,
	// 		showUploadFolders: false,
	// 		supportDrives: true,
	// 		multiselect: false,
	// 		// customViews: customViewsArray, // custom view
	// 		callbackFunction: (data) => {
	// 			switch (data.action) {
	// 			case 'picked':
	// 				//let [doc] = data.docs;
	// 				if (data?.docs?.at(0))
	// 					setCrop(undefined); // Makes crop preview update between images.
	// 				// const reader = new FileReader();
	// 				// reader.addEventListener('load', () =>
	// 				// 	setImgSrc(reader.result?.toString() || ''),
	// 				// );
	// 				// reader.readAsDataURL(doc.url);
	// 				setImageName(data?.docs?.at(0)?.name ?? '');
	// 				break;
	// 			default:
	// 				console.log(data);
	// 			}
	// 			console.log(data);
	// 		},
	// 	});
	// };
	useEffect(()=>{
		setIsLoading(true);
		setImgSrc(src);
		setImageName(src?.substring(src.lastIndexOf('/')+1));
		setIsLoading(false);
	}, [src]);
	function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
		setIsLoading(true);
		if (e.target.files && e.target.files.length > 0) {
			setCrop(undefined); // Makes crop preview update between images.
			const reader = new FileReader();
			reader.addEventListener('load', () =>
				setImgSrc(reader.result?.toString() || ''),
			);
			reader.readAsDataURL(e.target.files[0]);
			setImageName(e.target.files[0].name);
			setModified(true);
		}
	}

	function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
		if (aspect) {
			const { width, height } = e.currentTarget;
			setCrop(centerAspectCrop(width, height, aspect));
		}
		setIsLoading(false);
	}
	const saveImage = useCallback(async ()=> {
		if (imgRef?.current && completedCrop) {
			const canvas = canvasPreview(
				imgRef.current,
				completedCrop,
				scale,
				rotate,
			);
			try {
				return new Promise((resolve) => {
					canvas.toBlob((file) => {
						// @ts-ignore
						resolve(handleSave(file, imgName));
					}, 'image/jpeg');
				});
			} catch (error) {
				console.log(error);
				return null;
			}
		}
	}, [completedCrop, handleSave, imgName, rotate, scale]);

	const onClickSave = ()=> {
		setIsLoading(true);
		saveImage();
	};
	const handleClose = (event: object, reason?: string) => {
		if (['backdropClick'].includes(reason ?? 'undefined')) {
			return;//ignored event
		}
		// @ts-ignore
		event?.preventDefault();
		setImgSrc(src);
		setModified(false);
		handleCancel(event, reason);
	};

	function canvasPreview(
		image: HTMLImageElement,
		crop: PixelCrop,
		scale = 1,
		rotate = 0,
	) {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		if (!ctx) {
			throw new Error('No 2d context');
		}

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const pixelRatio = window.devicePixelRatio;

		canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
		canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

		ctx.scale(pixelRatio, pixelRatio);
		ctx.imageSmoothingQuality = 'high';

		const cropX = crop.x * scaleX;
		const cropY = crop.y * scaleY;

		const rotateRads = rotate * TO_RADIANS;
		const centerX = image.naturalWidth / 2;
		const centerY = image.naturalHeight / 2;

		ctx.save();

		// 5) Move the crop origin to the canvas origin (0,0)
		ctx.translate(-cropX, -cropY);
		// 4) Move the origin to the center of the original position
		ctx.translate(centerX, centerY);
		// 3) Rotate around the origin
		ctx.rotate(rotateRads);
		// 2) Scale the image
		ctx.scale(scale, scale);
		// 1) Move the center of the image to the origin (0,0)
		ctx.translate(-centerX, -centerY);
		ctx.drawImage(
			image,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight,
		);
		return canvas;
	}

	return (
		<Dialog open={ isOpen } onClose={ handleClose } fullWidth={true} >
			<DialogTitle>{title ?? 'Upload Image' }</DialogTitle>
			<DialogContent>
				<div className="Crop-Controls">
					<input type="file" accept="image/*" onChange={onSelectFile} />
				</div>
				{ isLoading && <Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box> }
				{!!imgSrc && (
					<ReactCrop
						crop={crop}
						onChange={(_, percentCrop) => {
							setCrop(percentCrop);
							setModified(true);
						}}
						onComplete={(c) => setCompletedCrop(c)}
						aspect={aspect}
					>
						<img
							ref={imgRef}
							alt="Crop me"
							src={imgSrc}
							style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
							onLoad={onImageLoad}
						/>
					</ReactCrop>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={ handleClose }>Cancel</Button>
				<LoadingButton
					onClick={ onClickSave }
					loading={ isLoading }
					disabled={ !modified || !(imgRef?.current || completedCrop) }
					loadingPosition="start"
					startIcon={ <SaveIcon /> }
				>Save</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

import React, { useEffect, useState } from 'react';

// @ts-ignore
import { useAuth } from 'base-shell/lib/providers/Auth';
import { AuthenticationResponseData, useAuthAdapter } from './hooks/AuthAdapter';
import Box from '@mui/material/Box';

type SignInProps  = {
	clientId:string,
	autoSelect:boolean,
	cancelOnTapOutside:boolean,
  sx?:object
}

export default function GoogleSignin({
	sx,
	clientId,
	autoSelect = false,
	cancelOnTapOutside = false
}: SignInProps) {
	const { auth } = useAuth();
	const authenticate = useAuthAdapter();
	const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);
	useEffect(() => {
		async function onSuccess(googleData:any) {
			try {
				const res = await fetch('/api/auth/google', {
					method: 'POST',
					body: JSON.stringify(googleData),
					headers: {
						'Content-Type': 'application/json'
					}
				});
				const data = await res.json() as AuthenticationResponseData;
				const { response } = data;
				authenticate(response);
			} catch (err: any) {
				console.error(err);
				const {
					message,
					data: {
						error: {
							description
						}
					}
				} = err;
				window.alert(description || message);
			}
		}
		const initializeGsi = () => {
			if (!window.google || gsiScriptLoaded) return;

			setGsiScriptLoaded(true);
			window.google.accounts.id.initialize({
				client_id: clientId,
				callback: onSuccess,
				auto_select: autoSelect,
				cancel_on_tap_outside: cancelOnTapOutside,
				context: 'use'

			});
			if(!auth.isAuthenticated) {
				window.google.accounts.id.prompt((notification: any) => {
					if (notification.isNotDisplayed() || notification.isSkippedMoment())
					{
						window.google.accounts.id.renderButton(
							document.getElementById('GSIButton'),
							{ theme: 'outline', size: 'large' }  // customization attributes
						);
					}
				});
			}
		};

		const script = document.createElement('script');
		script.src = 'https://accounts.google.com/gsi/client';
		script.onload = initializeGsi;
		script.async = true;
		script.id = 'google-client-script';
		document.querySelector('body')?.appendChild(script);

		// return () => {
		// 	console.log('removing gsi stuff');
		// 	window.google?.accounts.id.cancel();
		// 	document.getElementById('google-client-script')?.remove();
		// };
	}, [auth.isAuthenticated, authenticate, autoSelect, cancelOnTapOutside, clientId, gsiScriptLoaded]);

	return (auth.isAuthenticated ? null : <Box id="GSIButton" sx={sx}></Box>);
}

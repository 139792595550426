import {
	AutocompleteProps,
	Chip,
} from '@mui/material';
import React, {
	SyntheticEvent,
	useState
} from 'react';
import APIAutocomplete from './APIAutocomplete';

interface PropertyAutocompleteProps extends AutocompleteProps<any, any, any, any>{
	label:string,
	initialValue:[]
}

const PropertiesAutocomplete = ( props: PropertyAutocompleteProps)=>{
	let { id, label, defaultValue = [], onChange} = props;
	const [value, setValue] = useState(defaultValue);

	const handleChange = async (event: SyntheticEvent, newValue: any, reason:any) => {
		if (reason) {
			onChange && onChange( event, newValue, reason );
			setValue(newValue);
		}
		return false;
	};
	return(
		<APIAutocomplete
			{...props}
			id={id}
			label={label}
			url={'/properties'}
			// fields={'id,name,externalPropertyId'}
			multiple
			value={value}
			onChange={handleChange}
			getOptionLabel={(o) => `${o.name} - ${o.fein}`}
			filterOptions={(o:any, s:any) => {
				return o;
			}}
			renderTags={(value:[], getTagProps:any) =>
				value?.map((option:any, index:any) => (
					<Chip
						key={`properties-${index}`}
						label={option?.name}
						{...getTagProps({ index })}
					/>
				))
			}
		/>

	);
};

export default PropertiesAutocomplete;
